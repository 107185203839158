
import { Input, InputGroup, Button, Select } from 'components/FormComponents';
import cn from 'classnames';
import styles from './CompanySettingsForm.module.scss';
import { useForm } from 'react-hook-form';

export const CompanySettingsForm = ({ stateCompany, updateCompany, brands }) => {

  const { register, errors, reset, handleSubmit } = useForm({defaultValues: {
    name: stateCompany.name
  }});

  const onSubmit = (data) => {
    updateCompany({
      variables: {
        id: stateCompany.id,
        name: data.name,
        defaultBrand: data?.defaultBrand
      }
    })
    reset(data)
  }

  return (
    <form className={cn('card', styles.root)} onSubmit={handleSubmit(onSubmit)}>
      <div className={cn('card_content', styles.formContainer)}>
        <div className={cn(styles.companyContainer)}>
          <div className={styles.companyAvatar}>
            <span>{stateCompany.name.split('')[0]}</span>
          </div>
          <InputGroup className={styles.input} title='Company Name'>
            <Input
              name='name'
              type='text'
              placeholder='Enter your company name'
              validators={{required: true}}
              register={register}
              error={errors?.name?.type}
              inputProps={{ tabIndex: 2 }}
            />
          </InputGroup>
          <InputGroup title='Default Email Brand'>
            <Select menuStyles={styles.selectMenu} name='defaultBrand' register={register}>
              {brands?.map(brand => (
                <Select.Item
                  key={brand.id}
                  description={brand.email ? brand.email : "No email is set"}
                  value={brand.id}
                  active={brand.default}
                >
                  {brand.brandName}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        </div>
      </div>
      <div className={cn('card_footer', 'd-flex', 'justify-content-between', styles.footer)}>
        <Button type='secondary' onClick={reset}>Cancel</Button>
        <Button submit>Save</Button>
      </div>
    </form>
  )
}
