import React, { useState } from "react";
import { ExternalLinkWarning } from "components/ExternalLinkWarning/ExternalLinkWarning";
import styles from "./UrlPreview.module.scss";
import cn from "classnames";
import { Button } from "components/FormComponents";

export const UrlPreview = ({ url }) => {
  return (
    <>
      <iframe
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded URL"
        style={{ backgroundColor: "#e5e5e5" }}
      />
      <Button className={styles.urlBtn}>
        <ExternalLinkWarning text="Open Link" url={url} />
      </Button>
    </>
  );
};
