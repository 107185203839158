import cn from 'classnames';
import { BlockInfoItemTypes } from 'constants/block-info-items-enum';
import { VideoWidget } from 'components/VideoWidget/VideoWidget';
import { GoogleMapWidget } from 'components/GoogleMapWidget/GoogleMapWidget';
import style from './InfoBlock.module.scss';
import { CustomButton } from 'views/StakeholderJourneyScreens/components/CustomButton/CustomButton';
import { Badge } from 'react-bootstrap';
import { Button } from 'components/FormComponents';
import { ExternalLinkWarning } from 'components/ExternalLinkWarning/ExternalLinkWarning';
import { MobilePdfViewer } from '../QuestionnaireForm/MobilePdfViewer/MobilePdfViewer';
import { UrlPreview } from '../QuestionnaireForm/components/UrlPreview/UrlPreview';
import Vimeo from '@u-wave/react-vimeo';
import { LoomSettings } from 'views/BlockBuilderScreen/tabs/Blocks/BlockBuilder/components/InformationBuilder/components/InfoItemConfig/settings_components/LoomSettings/LoomSettings';
import { VidyardSettings } from 'views/BlockBuilderScreen/tabs/Blocks/BlockBuilder/components/InformationBuilder/components/InfoItemConfig/settings_components/VidyardSettings/VidyardSettings';

export const InfoBlock = ({
  required,
  block,
  onContinue = () => {}
}) => {
  const { innerWidth: width } = window;

  const renderItem = (item) => {
    switch(item.typeOf) {
      case BlockInfoItemTypes.Title: 
        return <h1 className={cn(style.infoItem, 'title-3')}>{item.params.text}</h1>
      case BlockInfoItemTypes.Paragraph:
        return <p className={cn(style.infoTextItem, 't-body')}>{item.params.text}</p>
      case BlockInfoItemTypes.Video:
        return <VideoWidget className={cn(style.video, style.infoItem)} src={item.params.videoSrc} autoplay={false} noClickable={false} loop={false} mute={false} />;
      case BlockInfoItemTypes.Image:
        return <img className={cn('margin-top', 'card', style.image, style.infoItem)} src={item.image.url} />
      case BlockInfoItemTypes.GoogleMap:
        return (<GoogleMapWidget className={style.infoItem} height={400} search={item.params.text} />)
      case BlockInfoItemTypes.Pdf:
        return width > 1080 ?(<embed className={cn(style.infoItem, style.embed)} src={item.pdf.url} type="application/pdf" />)
        : 
          <div className={cn(style.infoItem,style.embedMobile)}>       
             <MobilePdfViewer url={item?.pdf?.url} />
          </div>
      case BlockInfoItemTypes.TangoEmbed:
        return (width > 600 ?
          <div className={cn(style.infoItem, style.embed, 'card-with-border card margin-bottom')}>
            <embed type="video/webm" src={item.params.videoSrc} />
          </div> : 
          <Button className={cn(style.infoItem, style.tangoLink, 'margin-bottom')}>
            <ExternalLinkWarning 
              text={'Click here to go to a Tango Flow page'}
              url={item.params.videoSrc}
            />
          </Button>
        )
      case BlockInfoItemTypes.Url:
        return <UrlPreview url={item.params.text}/>
      case BlockInfoItemTypes.Vimeo:
        return( 
         <div className={cn(style.video, style.infoItem)}>
           <Vimeo style={{width: '100%', height: '100%'}}  video={item.params.text} responsive/> 
          </div>
        )
      case BlockInfoItemTypes.Loom:
        return(
          <LoomSettings loomVideo={item.params.text} />
        )
      case BlockInfoItemTypes.Vidyard:
        return(
          <div className={cn(style.video, style.infoItem)}>
            <VidyardSettings vidyardVideo={item.params.text} />
          </div>
        )
    } 
  }

  return (
    <div className={cn('card', 'card-with-border', style.root)}>
      <div className={cn(style.heading)}>
        <Badge className={cn(style.badge, { ['badge--default']: !required, ['badge--info']: required })}>
          {required ? 'Required' : 'Nice to do'}
        </Badge>
        <h1 className='title-2'>{block.heading}</h1>
      </div>
      <div className={cn(style.blockItems)}>
        {block.blockInfoItems.map(item => renderItem(item))}
        <CustomButton className={cn(style.next, 'mt-4')} onClick={onContinue}>Complete</CustomButton>
      </div>
    </div>
  )
}
