import styles from './GoogleMapWidget.module.scss';
import cn from 'classnames';

export const GoogleMapWidget = ({height, search, className}) => {
  return (
    <div className={cn(styles.container, 'margin-top', {[className]: className})} style={{height}}>
      <iframe 
        title="map"
        id="gmap_canvas"
        src={`https://maps.google.com/maps?q=${search}&t=&ie=UTF8&iwloc=&output=embed`}
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        className={styles.frame}
        >
      </iframe>
    </div>
  )
}