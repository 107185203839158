import { useState, useEffect } from "react";
import { Button} from "components/FormComponents";
import styles from './AddTeamMembersModal.module.scss';
import cn from 'classnames';
import { ModalForm } from "components/ModalForm/ModalForm";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { SearchInput } from 'components/SearchInput/SearchInput';
import { Checkbox } from "components/FormComponents/Checkbox/Checkbox";
import fuzzysort from "fuzzysort";

export const AddTeamMembersModal = ({ users,team, handleAddTeamMembers, onClose, visible }) => {

  const [selectedUsers, setSelectedUsers] = useState([])
  const [filtered, setFiltered] = useState(users)
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setFiltered(users);
  },[users])

  useEffect(() => {
    if (searchValue !== null && searchValue?.trim() !== "") {
      const filter = fuzzysort.go(searchValue, users, { key: "name", threshold: -10000 });
      const result = [];
      filter.forEach((e) => {
        result.push(e.obj);
      });
      setFiltered(result);
    } else {
      setFiltered(users);
    }
  }, [searchValue]);

  const close = () => {
    onClose()
    setSelectedUsers([])
  }

  const onAddTeamMembers = () => {
    handleAddTeamMembers({teamId: team?.id, userIds: selectedUsers})
    close()
  }

  const handleCheckboxClick = (user_id) => selectedUsers?.includes(user_id) ? setSelectedUsers((selectedUsers) => selectedUsers.filter((id) => id !== user_id)) : setSelectedUsers([...selectedUsers, user_id])

  return (
    <ModalForm visible={visible} onClose={() => {onClose()}}>
      <div className='card_content w-100'>
        <div className={cn(styles.header, 'mb-5')}>
          <h1 className={cn(styles.title, 'title-3')}>
            <FeatherIcon icon='user-plus' size={22} className='me-2' /> Add Team Members
          </h1>
          <FeatherIcon icon='x' className={styles.closeIcon} onClick={close} />
        </div>

        <SearchInput className={'w-100 mb-3'} placeholder='Search...' onChange={setSearchValue}/>

        <div className={styles.listContainer}>
          {filtered?.map(user => (
            <div className={styles.list} key={`user-${user.id}`} onClick={() =>handleCheckboxClick(user.id)}>
              <Checkbox className={cn(styles.checkBox)} checked={selectedUsers?.includes(user?.id)}/>
              <span className={styles.name}>{user.name}</span> &nbsp;/ <span className={styles.email}>{user.email} </span>
            </div>
          ))}
        </div>

      </div>
      <div className={cn('card_footer', styles.noBorder, 'd-flex', 'justify-content-between')}>
        <Button type='secondary' >Cancel</Button>
        <Button type='primary' onClick={onAddTeamMembers}>Add Team Members</Button>
      </div>
    </ModalForm>
  )
}
