import { useForm } from 'react-hook-form';
import styles from './BrandForm.module.scss';
import { EditableTitle, Button } from 'components/FormComponents';
import { Tabs, Tab } from 'components/Tabs/Tabs';
import { GeneralTab } from '../GeneralTab/GeneralTab';
import { EmailsTab } from '../EmailsTab/EmailsTab';
import { useEffect } from 'react';
import { IconButton } from 'components/IconButton/IconButton';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { useState } from 'react';
import cn from 'classnames';

export const BrandForm = ({
  brand,
  onClose = () => {},
  onSave = () => {},
  onDelete = () => {}
}) => {

  const { register, reset, control, watch, handleSubmit } = useForm();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => reset(brand), [brand])

  return (
    <>
      <ConfirmationModal
        visible={openModal}
        title='Delete Brand Confirmation'
        subtitle='This Brand will be permanently deleted. This data cannot be recovered'
        confirmationKey={watch('brandName')}
        confirmationIcon='trash-2'
        onClose={() => { setOpenModal(false) }}
        onConfirmation={onDelete}
        buttonTitle='Delete Brand'
      />
      <form className={cn(styles.root, 'card card-with-border')} onSubmit={handleSubmit(onSave)}>
        <div className='card_content'>
          <header className={styles.header}>
            <EditableTitle register={register} name='brandName' value={watch('brandName')} />
            {!brand.default && (
              <IconButton icon='trash-2' tip='Delete' placement='left' onClick={() => { setOpenModal(true) }}/>
            )}
          </header>
        </div>
        <Tabs defaultActiveKey='general' navItemStyles={styles.navItems}>
          <Tab eventKey="general" title="General">
            <GeneralTab register={register} control={control} watch={watch} />
          </Tab>
          <Tab eventKey='emails' title='Emails'>
            <EmailsTab register={register} control={control} watch={watch} />
          </Tab>
        </Tabs>
        <div className='card_footer d-flex justify-content-between'>
          <Button type='secondary' onClick={onClose}>Cancel</Button>
          <Button submit>Save</Button>
        </div>
      </form>
    </>
  )
}
