import { Modal } from 'components/Modal/Modal';
import styles from './ErrorAlert.module.scss';
import cn from 'classnames';

export const ErrorAlert = ({ 
  visible,
  errorMessage,
  onClose = () => {}
}) => (
  <Modal visible={visible} title={errorMessage || "Unexpected Error"} customStyles={{ backgroundColor: '#b56161', titleColor: '#FFF' }} onClose={onClose}>
    <p className={cn('t-body', styles.body)}>Sorry for the inconvenience, if the error persist, contact KnowMy support</p>
  </Modal>
)
