import { LinkVideoInput } from "components/FormComponents";

export const VideoSettings = ({ register, name, watch, errors }) => (
  <LinkVideoInput
    register={register}
    className="margin-top--large"
    name={`${name}.params.videoSrc`}
    validators={{ required: true }}
    value={watch(`${name}.params.videoSrc`)}
    error={errors?.params?.videoSrc?.type}
  />
);
