import { EditableSettings } from '../../../../../../../../../../../components/EditableSettings/EditableSettings';
import { Fragment } from 'react';
import { ToggleSwitch, Input } from '../../../../../../../../../../../components/FormComponents';
import { Collapse } from 'react-bootstrap';

export const DefaultSettings = ({ register, name, field }) => (
  <EditableSettings title='Settings' className='margin-top--large' startOpen={true}>
    <ToggleSwitch register={register} name={`${name}.required`} label='Required' />
    <hr />
    <Fragment>
      <ToggleSwitch register={register} name={`${name}.useDescription`} label='Add description' />
      <Collapse in={field.options?.useDescription}>
        <div><Input register={register} name={`${name}.description`} className='margin-top--small' placeholder='Enter description...'/></div>
      </Collapse>
    </Fragment>
  </EditableSettings>
)