import styles from './InitialLetterBadge.module.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const InitialLetterBadge = ({ word='', color='#4932D0', className }) => (
  <span className={cn(styles.root, { [className]: className })} style={{ color, borderColor: color }}>{word?.[0]}</span>
)

InitialLetterBadge.propTypes = {
  word: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};
