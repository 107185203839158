import { CollapsibleGroup } from "components/CollapsibleBlock/CollapsibleBlock";
import { BlockInfoItemTypes } from "constants/block-info-items-enum";
import { useFieldArray } from "react-hook-form";
import { Button } from "components/FormComponents";
import { InfoItemConfig } from "./components/InfoItemConfig/InfoItemConfig";
import FeatherIcon from "feather-icons-react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

export const InformationBuilder = ({ control, watch, register, errors, setValue, clearErrors, setError }) => {
  const { fields: items, append, remove, swap } = useFieldArray({ control, name: "blockInfoItemsAttributes", keyName: "key" });
  const addItem = () => {
    append({
      order: items.length + 1,
      typeOf: BlockInfoItemTypes.Paragraph,
      params: {},
    });
  };

  const handleItemRemove = (indexToRemove) => {
    let _items = [];
    let order = 1;
    items.forEach((item, index) => {
      let _item;
      if (index === indexToRemove || item._destroy) {
        _item = { ...item, _destroy: true };
      } else {
        _item = { ...item, order: order };
        order++;
      }
      delete _item.key;
      if (!_item._destroy || _item.id) _items.push(_item);
    });
    setValue("blockInfoItemsAttributes", _items);
  };

  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return;

    swap(source.index, destination.index);
    setValue(`blockInfoItemsAttributes[${source.index}].order`, source.index + 1);
    setValue(`blockInfoItemsAttributes[${destination.index}].order`, destination.index + 1);
  };

  return (
    <>
      <CollapsibleGroup newStartOpen={true}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="options">
            {({ innerRef, droppableProps, placeholder }) => (
              <div className="w-100" {...droppableProps} ref={innerRef}>
                {items.map(
                  (item, index) =>
                    !item._destroy && (
                      <InfoItemConfig
                        key={item.key}
                        index={index}
                        register={register}
                        control={control}
                        watch={watch}
                        item={item}
                        errors={errors?.blockInfoItemsAttributes?.[index]}
                        remove={handleItemRemove}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    )
                )}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </CollapsibleGroup>
      <Button className="w-100" type="secondary-reverse" onClick={addItem}>
        <FeatherIcon size={18} className="margin-right" icon="plus" />
        Add Item
      </Button>
    </>
  );
};
