import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { useAppContext } from "templates/AppLayout/AppLayout";
import cn from "classnames";
import styles from './BundleBlockResult.module.scss'

const PARTNER_LINK_QUERY = loader("graphql/queries/checkmate_candidate_partner_link.graphql")

export const BundleBlockResult = ({blockResultId}) => {

    const { setLoading} = useAppContext();
    const [link , setLink ] = useState(null)  
    
    const { loading: fetching } = useQuery(PARTNER_LINK_QUERY, {
        variables: { id: blockResultId },
        onCompleted: data => {
            setLink(data?.checkmateCandidatePartnerLink)
        }
      });

    const openLink = () =>{
        window.open(link, '_blank');
    }
    
    useEffect(()=>{
        setLoading(fetching)
    },[fetching])

    return (
        <>
            <p className={cn(styles.text)} onClick={openLink}>Click here to open in detail</p>
            {/* {!!link && <iframe src={link}/>}  */}
        </>
    )
}