import { Input } from "./../Input/Input";
import PropTypes from "prop-types";
import { VideoWidget } from "./../../VideoWidget/VideoWidget";

export const LinkVideoInput = ({ className, register, name, value, validators, error }) => {
  return (
    <div className={className}>
      <p className="t-color-secondary t-tiny">* We only accept youtube URL's at the moment. Sorry for the inconvenience</p>
      <Input
        register={register}
        name={name}
        placeholder="https://www.youtube.com/embed/dskhie93hjsk"
        validators={validators}
        error={error}
      />
      <VideoWidget src={value} className="margin-top" autoplay={false} />
    </div>
  );
};

LinkVideoInput.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
};

LinkVideoInput.defaultProps = {
  register: (...args) => ({ ref: () => {} }),
};
