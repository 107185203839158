import styles from './Company.module.scss';
import cn from 'classnames';
import { useState } from 'react';
import { stateUser, stateCompany } from '../../../../graphql/state';
import { CompanySettingsForm } from './components/CompanySettingsForm/CompanySettingsForm';
import { useReactiveVar, useMutation, useQuery } from '@apollo/client';
import { Loading } from '../../../../components/Loading/Loading';
import { loader } from "graphql.macro";


const UPDATE_COMPANY_SETTINGS_MUTATION = loader('graphql/mutations/update_company_settings.graphql');
const BRANDS_QUERY =                     loader("./../../../../graphql/queries/brands.graphql");

export const Company = ({ alert, error }) => {

  const [brands, setBrands] = useState([]);
  const user = useReactiveVar(stateUser);
  const company = useReactiveVar(stateCompany);

  const [ updateCompany, { loading: updatingCompany } ] = useMutation(UPDATE_COMPANY_SETTINGS_MUTATION, {
    onCompleted: async (data) => {
      const {company} = data.updateCompanySettings;
      stateCompany(company);
      alert('Details were successfully updated.')
    },
    onError: errorMessage => {
      error(errorMessage.message)
    }
  });

  const { loading: fetchingBrands } = useQuery(BRANDS_QUERY, {
    onCompleted: (data) => {
      setBrands(data.brands)
    }
  });

  return (
    <div className={cn('card card-with-border', styles.root)}>
      <Loading visible={updatingCompany || fetchingBrands} />
      <div className={cn('card_content', styles.header)}>
          <h1 className={styles.title}>Company Settings</h1>
          <p className={styles.subtitle}>Edit the details of the company settings below.</p>
      </div>
      <CompanySettingsForm
        stateUser={user}
        brands={brands}
        stateCompany={company}
        updateCompany={updateCompany}
      />
    </div>
  )
}
