import { createContext, useMemo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './VerticalTabs.module.scss';
import cn from 'classnames';

const VerticalTabsContext = createContext(null);

export const VerticalTabs = ({
  defaultActiveKey,
  className,
  children,
  contentStyles,
  navItemStyles
}) => {

  const [tabs, setTabs] = useState({});
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (defaultActiveKey) setActiveKey(defaultActiveKey)
  }, [defaultActiveKey]);

  const addTab = (key, title) => {
    setTabs(curr => ({...curr, [key]: title }))
  }

  const contextValue = useMemo(() => ({ activeKey, addTab }), [activeKey])

    return (
        <VerticalTabsContext.Provider value={contextValue}>
            <div className={cn(styles.tabsContainer, className)}>
                <div className={styles.tabList}>
                    {Object.keys(tabs).map(tabKey => (
                        <div key={tabKey} className={cn(styles.tabItem, navItemStyles, { [styles.tabItemActive]: activeKey === tabKey })} onClick={() => setActiveKey(tabKey)}>
                        {tabs[tabKey]}
                        </div>
                    ))}
                </div>
                <div className={styles.tabContent}>
                    {children}
                </div>
            </div>
        </VerticalTabsContext.Provider>
    )
}

VerticalTabs.propTypes = {
  defaultActiveKey: PropTypes.string,
  className: PropTypes.string
};


const useVerticalTabsContext = () => {
  const context = useContext(VerticalTabsContext)
  if (!context)
    throw new Error(`Tab compound components cannot be rendered outside the Tabs component`)

  return context
}


export const Tab = ({ eventKey, title, children }) => {
  const { activeKey, addTab } = useVerticalTabsContext()

  useEffect(() => { addTab(eventKey, title) }, [title, eventKey])

  if(activeKey !== eventKey) return null;

  return (
    children
  )
}

Tab.propTypes = {
  eventKey: PropTypes.string,
  title: PropTypes.string
};
