import { Button, Input, InputGroup, Select } from "components/FormComponents";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import styles from './InviteUserForm.module.scss';
import cn from 'classnames';
import { useReactiveVar } from "@apollo/client";
import { stateCompany } from "graphql/state";
import { ModalForm } from "components/ModalForm/ModalForm";
import { generatePassword } from "utils/helpers";

export const InviteUserForm = ({ allRoles, handleInviteUser, companies, onClose, visible }) => {

  const [roles , setRoles] = useState(allRoles)

  useEffect(()=>{
    setRoles(allRoles)
  },[allRoles])

  const company = useReactiveVar(stateCompany);

  const { register, unregister, formState: { errors }, reset, handleSubmit, watch, control } = useForm({
    defaultValues: {
    companyId: company?.id,
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    password: generatePassword(6),
    roleId: null

  }});
  
  const close = () => {
    onClose()
    reset()
  }

  const onSubmit = (userAttributes) => {
    handleInviteUser(userAttributes)
    close()
  }

  const roleId = watch('roleId')
  const companyId = watch('companyId')

  useEffect(()=>{
    if(companyId!=='new'){
      unregister('companyName')
    }
  },[companyId])

  return (
    <ModalForm visible={visible} onClose={() => { onClose() }} headerClasses={styles.modalHeader}>
      <div className={cn(styles.header)}>
        <h1 className={cn(styles.title, 'title-3')}>Invite Company User</h1>
        <p>Enter the details of the new company user below and click the invite button.</p>
      </div>
      <form className={cn(styles.root)} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.content}>
          <div className={styles.inputContainer}>
            <InputGroup className={styles.input} title='First Name'>
              <Input
                name='firstName'
                type='text'
                placeholder='Enter first name'
                validators={{ required: true }}
                register={register}
                error={errors?.firstName?.type}
                inputProps={{ tabIndex: 1 }}
              />
            </InputGroup>
            <InputGroup className={styles.input} title='Last Name'>
              <Input
                name='lastName'
                type='text'
                placeholder='Enter last name'
                validators={{ required: true }}
                register={register}
                error={errors?.lastName?.type}
                inputProps={{ tabIndex: 2 }}
              />
            </InputGroup>
          </div>
          <div className={styles.inputContainer}>
            <InputGroup className={styles.input} title='Email'>
              <Input
                name='email'
                type='text'
                placeholder='Enter email address'
                register={register}
                validators={{ required: true }}
                error={errors?.email?.type}
                inputProps={{ tabIndex: 3 }}
              />
            </InputGroup>
            <InputGroup className={styles.input} title='Phone Number'>
              <Input
                name='contactNumber'
                type='text'
                placeholder='Enter phone number'
                register={register}
                validators={{ required: true }}
                error={errors?.contactNumber?.type}
                inputProps={{ tabIndex: 5 }}
              />
            </InputGroup>
          </div>
          <div className={styles.inputContainer}>
          <InputGroup className={styles.input} title='Role'>
            <Controller
              rules={{required: true}}
              control={control}
              name={'roleId'}
              render={({ field: { onChange, value } }) => (
                <Select name='roleId' error={errors?.roleId} menuStyles={styles.selectMenu} register={register} placeholder="Select employee role..." className='me-5'>
                   <Select.Item key={"null"} value={null} active={roleId === null}>
                     -
                  </Select.Item>
                  {roles?.map(role => (
                    <Select.Item key={`${role.id}${role.displayName}`} value={role.id} >{role.displayName}</Select.Item>
                  ))}
                </Select>
              )}
            />
          </InputGroup>    
            {companies && <InputGroup className={styles.input} title='Company'>
              <Select name='companyId' menuStyles={styles.selectMenu} register={register} placeholder="Select employee company..." className='me-5'>
              <Select.Item value="new" active={companyId === ""}>
                  Create New Company
                </Select.Item>                
                {companies?.map(item => (
                  <Select.Item key={item.id} value={item.id} active={item.id === company?.id}>{item.name}</Select.Item>
                ))}
              </Select>
            </InputGroup>}
          </div>
          {companyId === "new" &&
            <div className={styles.inputContainer}>
              <InputGroup className={styles.input} title='New Company'>
                <Input
                  name='companyName'
                  type='text'
                  placeholder='Enter New Company Name'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.companyName?.type}
                  inputProps={{ tabIndex: 3 }}
                />
              </InputGroup>  
            </div>  
            }
        </div>
        <div className={styles.footer}>
          <Button type='secondary' onClick={close}>Cancel</Button>
          <Button submit >Invite</Button>
        </div>
      </form>
    </ModalForm>
  )
}
