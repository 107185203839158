import styles from "./UserRow.module.scss";
import cn from "classnames";
import { Button } from "components/FormComponents";
import Moment from "react-moment";

export const UserRow = ({ user, handleImpersonation }) => {
  return (
    <div className={cn(styles.root)}>
      {/* Name */}
      <h1 className={cn(styles.statusParent, "title-6")}>{user?.name}</h1>
      {/* company */}
      <span className={styles.name}>{user?.company?.name}</span>
      {/* email */}
      <span className={styles.email}>{user?.email}</span>
      {/* Verified */}
      <span>{user?.verified ? " ✅" : " ❌"}</span>
      {/* Actions */}
      <div className={cn(styles.actions)}>
        <Button type="primary" className={styles.impersonateButton} icon="user" onClick={() => handleImpersonation(user?.id)}> Impersonate </Button>
      </div>
    </div>
  );
};
