import styles from "../../StakeholderJourneyForm.module.scss";
import { useFieldArray, Controller} from "react-hook-form";
import { CollapsibleBlock } from "components/CollapsibleBlock/CollapsibleBlock";
import { Button } from 'components/FormComponents'
import { Input, InputGroup } from "components/FormComponents";
import { FilePicker } from 'components/FormComponents';
import cn from "classnames";
import FeatherIcon from 'feather-icons-react';
import { IconButton } from "components/IconButton/IconButton";
import { EditableSettings } from "components/EditableSettings/EditableSettings";
import { useEffect } from "react";


export const UploadOptionalPdf = ({
    register,
    control,
    watch,
    clearErrors,
    unregister,
    setError,
    setValue,
    errors
}) => {

    useEffect(() => {
    if (errors?.stakeholderJourneyCustomizationsAttributes?.filter((e) => e !== undefined).length === 0) {
      clearErrors("stakeholderJourneyCustomizationsAttributes");
    }
  }, [Object.keys(errors)]);

    const { fields, append, remove } = useFieldArray({ control, name: 'stakeholderJourneyCustomizationsAttributes', keyName: 'key' });

    useEffect(() => {
        setValue('stakeholderJourneyCustomizationsAttributes', fields);
    },[])

    const addUploadPdfAttributes = () => {
        append({
            order: fields?.length + 1,
            documentHeading: '',
            documentUploadAttributes: null,
            useSignature: false,
            signatureHeading: '',
            signatureUploadId: null,
        });
    }

    const handleFieldRemove = (indexToRemove) => {
        let _fields = [];
        let order = 1;
        fields.forEach((field, index) => {
        let _field;
        if(index === indexToRemove || field._destroy) {
            _field = {...field, _destroy: true }
        } else {
            _field = {...field, order: order }
            order++;
        }
        delete _field.key;
        if(!_field._destroy || _field.id) _fields.push(_field)
        });
        remove(indexToRemove)
        setValue('stakeholderJourneyCustomizationsAttributes', _fields);
    }

    return (
        <div className={styles.uploadPdfContainer}>
            <h1 className={styles.blockTitle}>
                <span>
                <span style={{ color: '#4932d0' }}>Upload PDF</span>
                (Optional)
                </span>
                <p className={cn(styles.subTitle, "t-subtitle margin-bottom")}>{'Upload the PDF document that you want to show to the stakeholders'}</p>
            </h1>
            {fields.map((field, index) => (
                <CollapsibleBlock
                key={field.key}
                className={cn(styles.collapsibleBlock, 'margin-bottom')}
                keyCode={`${index}`}
                startOpen
                >
                    <CollapsibleBlock.Header>
                        <span className={styles.order}>{field?.order ? field?.order : index+1}</span>
                        <Input
                        register={register}
                        name={`stakeholderJourneyCustomizationsAttributes.${index}.documentHeading`}
                        validators={{ required: true }}
                        error={errors?.stakeholderJourneyCustomizationsAttributes?.[index]?.documentHeading?.type}
                        noErrorMessage
                        className={styles.input}
                        placeholder='Enter document title...'
                        />
                        <IconButton tip='Delete Field' className={styles.removeButton} onClick={() => {
                        handleFieldRemove(index)
                        }} icon='trash-2' />
                    </CollapsibleBlock.Header>
                    <CollapsibleBlock.Content className="margin-top">
                        <Controller
                            control={control}
                            name={`stakeholderJourneyCustomizationsAttributes.${index}.documentUploadAttributes`}
                            render={({ field: { onChange, value } }) => (
                                <FilePicker
                                handleCustomChange={(fileId, fileName) => {
                                    onChange({
                                    documentUploadId: fileId,
                                    documentName: fileName
                                    });
                                }}
                                value={value?.documentUploadId}
                                error={errors?.stakeholderJourneyCustomizationsAttributes?.[index]?.documentUploadAttributes?.documentUploadId?.type}
                                setError={setError}
                                name={`stakeholderJourneyCustomizationsAttributes.${index}.documentUploadAttributes`}
                                clearErrors={clearErrors}
                                acceptedFileExtension={"pdf"}
                                />
                            )}
                            rules={{ required: true }}
                        />

                        <EditableSettings
                            title='Add Signature'
                            open={watch(`stakeholderJourneyCustomizationsAttributes.${index}.useSignature`)}
                            className={('margin-top margin-bottom', styles.signatureCard)}
                            toggleable
                            register={register}
                            name={`stakeholderJourneyCustomizationsAttributes.${index}.useSignature`}
                        >
                            <InputGroup title='Signature Title'>
                                <Input
                                name={`stakeholderJourneyCustomizationsAttributes.${index}.signatureHeading`}
                                placeholder='Enter signature title...'
                                register={register}
                                error={errors?.stakeholderJourneyCustomizationsAttributes?.[index]?.signatureHeading?.type}
                                validators={{required: watch(`stakeholderJourneyCustomizationsAttributes.${index}.useSignature`)}}
                                />
                            </InputGroup>
                        </EditableSettings>
                    </CollapsibleBlock.Content>
                </CollapsibleBlock>
            ))}
            <Button
                className='w-100 margin-bottom'
                type='secondary'
                onClick={addUploadPdfAttributes}
            >
                <FeatherIcon size={18} className='margin-right' icon='plus' />Add
            </Button>
        </div>
    )
}
