import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import styles from './Personal.module.scss';
import { Button } from 'components/FormComponents';
import { ExternalProfileForm } from './ExternalProfileForm/ExternalProfileForm';
import { useState } from 'react';

export const Personal = ({ externalStakeholder, updateUser }) => {

  const firstLetter = externalStakeholder?.firstName?.split('')[0];

  const [modal, setModal] = useState(false);

  return (
    <div className={cn(styles.root, 'card card-with-border')}>
      {externalStakeholder?.firstName && (
        <ExternalProfileForm 
          externalStakeholder={externalStakeholder}
          updateUser={updateUser}
          onClose={() => {setModal(false)}}
          visible={modal}
        />
      )}
      <div className={cn(styles.header, 'd-flex align-items-center justify-content-between')}>
        <h1 className={cn(styles.title, 'title-3 d-flex align-items-center')}>
          <FeatherIcon className={cn('me-3')} icon='user' />
          Personal
        </h1>
        <Button type='secondary' className={cn(styles.editBtn)} onClick={() => {setModal(true)}} >Edit</Button>
      </div>
      <div className={cn(styles.body)}>
        <div className={cn(styles.photoDiv, 'd-flex')}>
          <div className={cn('t-subtitle d-flex flex-column')}>
            Photo
            <div className={cn(styles.img, 'title-3')}>{firstLetter}</div>
          </div> 
        </div>
        <div className={cn('d-flex justify-content-between')}>
          <div className={cn(styles.namesDiv, 'd-flex flex-column align-items-between justify-content-between mt-5')}>
            <div className={cn(styles.firstName, styles.data)}>
              <p className={cn('t-subtitle')}>First Name</p>
              <p className={cn('title-4')}>{externalStakeholder?.firstName}</p>
            </div>
            <div className={cn(styles.email, styles.data, 'mt-5 d-flex flex-column me-5')}>
              <p className={cn('t-subtitle')}>Email</p>
              <p className={cn('title-4')}>{externalStakeholder?.email}</p>
            </div>
          </div>
          <div className={cn(styles.detailsDiv, 'd-flex flex-column align-items-between justify-content-between mt-5')}>
            <div className={cn(styles.lastName, styles.data)}>
              <p className={cn('t-subtitle')}>Last Name</p>
              <p className={cn('title-4')}>{externalStakeholder?.lastName}</p>
            </div>
            <div className={cn(styles.phone, styles.data, 'mt-5')}>
              <p className={cn('t-subtitle')}>Phone</p>
              <p className={cn('title-4')}>{externalStakeholder?.phoneNumber}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}