import { useState, useEffect } from "react";
import styles from "./StakeholderJourneyPreview.module.scss";
import { MultipleStakeholdersPreview } from "./MultipleStakeholdersPreview/MultipleStakeholdersPreview";

export const StakeholderJourneyPreview = ({ journey, stakeholderJourneyData, externalStakeholderData }) => {
  const merged = stakeholderJourneyData?.externalStakeholderIds?.length && stakeholderJourneyData?.externalStakeholders?.length ?
  [...stakeholderJourneyData?.externalStakeholderIds, ...stakeholderJourneyData?.externalStakeholders ] : null
  const [externalStakeholderTag, setExternalStakeholderTag] = useState({});
  const [internalStakeholderTags, setInternalStakeholderTags] = useState([]);
  if (externalStakeholderData) {
    stakeholderJourneyData.externalStakeholderAttributes.firstName = externalStakeholderData?.firstName;
    stakeholderJourneyData.externalStakeholderAttributes.lastName = externalStakeholderData?.lastName;
    stakeholderJourneyData.externalStakeholderAttributes.email = externalStakeholderData?.email;
    stakeholderJourneyData.externalStakeholderAttributes.phoneNumber = externalStakeholderData?.phoneNumber;
  }
  stakeholderJourneyData.internalStakeholdersAttributes = stakeholderJourneyData?.internalStakeholdersAttributes?.flat();

  useEffect(() => {
    setExternalStakeholderTag(journey.stakeholderTags.find((st) => st.typeOf === "external"));
    setInternalStakeholderTags(journey.stakeholderTags.filter((st) => st.typeOf === "internal"));
  }, [journey]);

  return (
    <div className="card card-with-border">
      <div className="card_content">
        <div>
          <h1 className="title-4 w-100">Summary</h1>
          <p className="t-subtitle padding-top--small">If the details below look correct, go ahead and launch your {externalStakeholderData ? ' journey' : ' new onboard journey'} </p>
           {/* External Stakeholders */}
          <MultipleStakeholdersPreview externalStakeholderTag={externalStakeholderTag} styles={styles}
           externalStakeholdersData={ merged || stakeholderJourneyData?.externalStakeholders || stakeholderJourneyData?.externalStakeholderIds  || [stakeholderJourneyData?.externalStakeholderAttributes] || externalStakeholderData}/>

          {/* Internal Stakeholders */}
          {stakeholderJourneyData.internalStakeholdersAttributes.map((internalStakeholderData) => {
            const stakeholderTag = internalStakeholderTags?.find(
              (stakeholderTag) => stakeholderTag?.id === (internalStakeholderData?.stakeholderTagId ||
               internalStakeholderData?.stakeholderTag?.id)
            );
            return (
              <div className="margin-top" key={internalStakeholderData?.stakeholderTag?.id}>
                <hr className="margin-bottom" />
                <h2 className="title-5 font-weight-400">
                  <span style={{ color: stakeholderTag?.color }}>{stakeholderTag?.name}</span> Details
                </h2>
                <div className={styles.stakeholderDetailsWrapper}>
                  <div className={styles.stakeholderDetailsItem}>
                    <label>Name</label>
                    <p>
                      {internalStakeholderData.firstName} {internalStakeholderData.lastName}
                    </p>
                  </div>
                  <div className={styles.stakeholderDetailsItem}>
                    <label>Email</label>
                    <p>{internalStakeholderData.email}</p>
                  </div>
                  <div className={styles.stakeholderDetailsItem}>
                    <label>Phone</label>
                    <p>{internalStakeholderData.phoneNumber}</p>
                  </div>
                </div>
              </div>
            );
          })}
          {stakeholderJourneyData?.stakeholderJourneyCustomizationsAttributes?.length > 0 &&
            <div className="margin-top">
              <hr className="margin-bottom" />
              <h2 className="title-5 font-weight-400">
                <span style={{ color: '#4932d0' }}>Upload PDF</span> Details
              </h2>
              {stakeholderJourneyData?.stakeholderJourneyCustomizationsAttributes?.map((data, index) => (
                <div className={styles.stakeholderDetailsWrapper} key={index}>
                  <div className={styles.stakeholderDetailsItem}>
                    <label>Document Name</label>
                    <p>
                      {data?.documentUploadAttributes?.documentName}
                    </p>
                  </div>
                  <div className={styles.stakeholderDetailsItem}>
                  </div>
                  <div className={styles.stakeholderDetailsItem}>
                    <label>Document Title</label>
                    <p>
                      {data.documentHeading}
                    </p>
                  </div>
                  {data?.useSignature && <div className={styles.stakeholderDetailsItem}>
                    <label>Signature Title</label>
                    <p>
                      {data.signatureHeading}
                    </p>
                  </div>}
                </div>
              ))}
            </div>}
        </div>
      </div>
    </div>
  );
};
