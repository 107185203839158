import { ExternalStakeholderLayout , useExternalStakeholderLayoutContext } from "templates/ExternalStakeholderLayout/ExternalStakeholderLayout";
import { stateExternalStakeholder } from "graphql/state";
import { useReactiveVar } from "@apollo/client";
import { OverviewTab } from "./Tabs/OverviewTab/OverviewTab";
import { HistoryTab } from "./Tabs/HistoryTab/HistoryTab";
import { Tab, Tabs } from "components/Tabs/Tabs";
import { useEffect, useState } from "react";

export const ExternalStakeholderDashboardScreen = () =>{

    const [currentStakeholder, setCurrentStakeholder] = useState([])
   
    const stakeholder = useReactiveVar(stateExternalStakeholder);

    useEffect(()=>{
        setCurrentStakeholder(stakeholder)
    },[stakeholder])

    return(
        <>
            <ExternalStakeholderLayout.Header>
                <ExternalStakeholderLayout.Title>Dashboard</ExternalStakeholderLayout.Title>
                <ExternalStakeholderLayout.Subtitle>Below is your summary of Journeys and Blocks.</ExternalStakeholderLayout.Subtitle>
            </ExternalStakeholderLayout.Header>
            <ExternalStakeholderLayout.Body>
                <Tabs defaultActiveKey="overview">
                    <Tab eventKey="overview" title="Overview">
                        <OverviewTab currentStakeholder={currentStakeholder}/>
                    </Tab>
                    <Tab eventKey="history" title="History">
                        <HistoryTab currentStakeholder={currentStakeholder}/>
                    </Tab>
                </Tabs>
            </ExternalStakeholderLayout.Body>
        </>
    )
}