import { HistoryRow } from './components/HistoryRow/HistoryRow';

export const History = ({ externalStakeholder }) => {

  const histories = externalStakeholder?.histories;

  const sortedHistories = [...histories];

  sortedHistories.sort((a, b) => {
      const idA = parseInt(a.id)
      const idB = parseInt(b.id)
      return idB - idA; 
    })
  
  return (
    <div className='d-flex flex-column align-items-center justify-content-center'>
      {sortedHistories?.map((history) => (
        <HistoryRow history={history} externalStakeholder={externalStakeholder} />
      ))}
    </div>
  )
}