import styles from './FieldConfig.module.scss';
import { Input, Select } from 'components/FormComponents';
import { FieldTypes, FieldTypesOptions } from 'constants/field-types-enum';
import { CollapsibleBlock } from 'components/CollapsibleBlock/CollapsibleBlock';
import { IconButton } from 'components/IconButton/IconButton';
import { DefaultSettings } from './settings_components/DefaultSettings/DefaultSettings';
import { MultipleChoiceSettings } from './settings_components/MultipleChoiceSettings/MultipleChoiceSettings';
import { OpinionScaleSettings } from './settings_components/OpinionScaleSettings/OpinionScaleSettings';
import { StarRatingSettings } from './settings_components/StarRatingSettings/StarRatingSettings';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { PdfSettings } from './settings_components/PdfSettings/PdfSettings';
import { VideoSettings } from './settings_components/VideoSettings/VideoSettings';
import { useWatch } from 'react-hook-form';
import { Textarea } from 'components/FormComponents';


export const FieldConfig = ({
  fieldObject,
  field,
  index,
  register,
  unregister,
  control,
  remove,
  errors,
  watch,
  clearErrors,
  setError,
  blockCode,
  invalid
}) => {

  const registerName = `fieldsAttributes.${index}`;
  const isStatement = useWatch({control, name: `${registerName}`})?.typeOf === 'statement'
  const renderSettings = () => {
    switch(field?.typeOf) {
      case FieldTypes.MultipleChoice:
        return <MultipleChoiceSettings register={register} name={`${registerName}.options`} field={field} control={control} errors={errors} clearErrors={clearErrors}/>
      case FieldTypes.OpinionScale:
        return <OpinionScaleSettings register={register} name={`${registerName}.options`} field={field} />
      case FieldTypes.StarRating:
        return <StarRatingSettings register={register} name={`${registerName}.options`} field={field} />
      case FieldTypes.Statement:
        return null;
      case FieldTypes.Pdf:
        return <PdfSettings name={`${registerName}.uploadId`} control={control} watch={watch} errors={errors} setError={setError} clearErrors={clearErrors}/>
      case FieldTypes.Video:
        return <VideoSettings name={`${registerName}.options`} register={register} watch={watch} errors={errors} />
      default:
        return <DefaultSettings register={register} name={`${registerName}.options`} field={field} />
    }
  }

  return (
    <Draggable draggableId={fieldObject.key} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div {...draggableProps} {...dragHandleProps} ref={innerRef}>
          <CollapsibleBlock render={invalid} openable={errors} startOpen={isStatement} className={cn('margin-bottom', styles.root)} headerClassName={cn(styles.header)} keyCode={`${index}`}>
            <CollapsibleBlock.Header>
              <span className={styles.order}>{field.order}</span>
              <Select register={register} name={`${registerName}.typeOf`} className={styles.typeSelector}>
                {FieldTypesOptions.map(fieldType => (
                  <Select.Item
                    key={fieldType.key}
                    value={fieldType.key}
                    active={fieldType.key === field.typeOf}
                    description={fieldType.subTitle}
                  >
                    {fieldType.title}
                  </Select.Item>
                ))}
              </Select>
              { !isStatement &&
                  <Textarea
                  register={register}
                  inputProps={{ rows: '1'}}
                  name={`${registerName}.text`}
                  validators={{ required: true }}
                  error={errors?.text?.type}
                  noErrorMessage
                  className={styles.input}
                  placeholder='Enter question...'
                  dynamicHeight
                />
              }
              <IconButton tip='Delete Field' className={styles.removeButton} onClick={() => {
                unregister(registerName)
                remove(index)
              }} icon='trash-2' />
            </CollapsibleBlock.Header>
            <CollapsibleBlock.Content>
            {isStatement &&
              <Textarea
                register={register}
                name={`${registerName}.text`}
                validators={{ required: true }}
                error={errors?.text?.type}
                noErrorMessage
                containerClassName={styles.textareaExpanded}
                className={cn(styles.textArea)}
                placeholder='Enter statement...'
                dynamicHeight
              />
              } 
              {!isStatement && !!blockCode &&
              <p className={styles.token} onClick={() => {
                  navigator.clipboard.writeText(`{{${blockCode}_${index}}}`);
                }}>Use this field's answer in the next questions using <span>{`{{${blockCode}_${index}}}`}</span>. Click To copy.</p>
              }
              {renderSettings()}
            </CollapsibleBlock.Content>
          </CollapsibleBlock>
        </div>
      )}
    </Draggable>
  )
}
