import React, {useState, useEffect} from "react";
import Vimeo from "@u-wave/react-vimeo";
import { Input } from "components/FormComponents";
import styles from "./VimeoSettings.module.scss";
import cn from "classnames";
import { vimeo_parser } from "utils/format-video-url";
import axios from "axios";

export const VimeoSettings = ({ register, watch, name, error }) => {

  const [videoError , setVideoError] = useState(false)
  const [isValidUrl , setIsValidUrl] = useState(null)
  const value = watch(`${name}`)

  useEffect(()=>{
    setVideoError(false)
    let isValid = null
    if(value){
      isValid = vimeo_parser(value)
    }
    if(isValid){
      checkVideoExists()
    }
    setIsValidUrl(isValid)
   
  },[value])

  const checkVideoExists = async () =>{
   await axios
    .get(`https://vimeo.com/api/oembed.json?url=${value}`)
    .then(response => {
        if(response.status===200){
          setVideoError(false)
        } 
    })
    .catch(function(error) {
      if(error.response.status===404){
        setVideoError('Either video is private or does not exist')
      } 
    });
  }
  return (
    <div className={cn(styles.root)}>
      <Input
        register={register}
        name={`${name}`}
        placeholder="https://vimeo.com/225613698"
        validators={{ required: true }}
        error={error}
      />
      {!videoError && value && isValidUrl &&
        <div className={cn(styles.vimeo)}>
          <Vimeo video={value} />
        </div>
      }
      {videoError && <p className={cn(styles.error)}>{videoError}</p>}
      {!isValidUrl && value  && <p className={cn(styles.error)}>Vimeo URL not valid !</p>}

    </div>
  );
};
