import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEffect, useState } from 'react';
import { List } from "../../../../components/List/List";
import { BrandForm } from "./components/BrandForm/BrandForm";

const BRANDS_QUERY =          loader("./../../../../graphql/queries/brands.graphql");
const BRAND_QUERY =           loader("./../../../../graphql/queries/brand.graphql");
const CREATE_BRAND_MUTATION = loader("./../../../../graphql/mutations/create_brand.graphql");
const UPDATE_BRAND_MUTATION = loader("./../../../../graphql/mutations/update_brand.graphql");
const DELETE_BRAND_MUTATION = loader('graphql/mutations/delete_brand.graphql');

export const BrandingTab = ({
  setLoading = () => {},
  alert,
  error
}) => {

  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState()

  const { loading: fetchingBrands } = useQuery(BRANDS_QUERY, {
    onCompleted: (data) => {
      setBrands(data.brands)
    }
  });

  const [fetchBrand, { loading: fetchingBrand }] = useLazyQuery(BRAND_QUERY, {
    onCompleted: data => setBrand(data.brand)
  });

  const [ createBrand, { loading: creatingBrand } ] = useMutation(CREATE_BRAND_MUTATION, {
    onCompleted: async(data) => {
      const newBrand = data.createBrand;
      setBrands(curr => [...curr, newBrand]);
      setBrand(newBrand)
      alert('Brand successfully created.')
    },
    onError: errorMessage => {
      error(errorMessage)
    }
  });

  const [ updateBrand, { loading: updatingBrand } ] = useMutation(UPDATE_BRAND_MUTATION, {
    onCompleted: async(data) => {
      const updatedBrand = data.updateBrand;
      const _brands = brands.map(brand => {
        if(brand.id === updatedBrand.id) return {...brand, ...updatedBrand }
        else return brand
      })
      setBrands(_brands);
      alert('Brand successfully updated.');
    },
    onError: errorMessage => {
      const err = errorMessage.networkError.result
      if (err?.message){
        error(err?.message)
      }else if (err?.errors[0].message){
        error(err.errors[0].message)
      }
    }
  });

  const [ deleteBrand, { loading: deletingBrand } ] = useMutation(DELETE_BRAND_MUTATION, {
    onCompleted: async(data) => {
      const deletedBrand = data.deleteBrand;
      setBrands(curr => curr.filter(block => block.id !== deletedBrand.id));
      setBrand(null);
      alert('Brand successfully deleted.');
    },
    onError: errorMessage => error(errorMessage)
  });

  const handleBlockDeletion = () => {
    deleteBrand({ variables: { id: brand.id } })
  }

  useEffect(() => {
    setLoading(fetchingBrands || fetchingBrand || creatingBrand || updatingBrand || deletingBrand)
  }, [fetchingBrands, fetchingBrand, creatingBrand, updatingBrand, deletingBrand])

  const handleNewBrand = () => {
    createBrand({ variables: { brandName: 'New Brand' }})
  }

  const handleUpdate = (brandAttributes) => {
    brandAttributes.name = brandAttributes.brandName
    delete brandAttributes.__typename;
    updateBrand({ variables: { brandAttributes } })
  }

  return (
    <div className='d-flex justify-around'>
      <List
        title='Brands'
        subtitle='Choose from a list of existing brands, or go ahead and create a new one. You can build unlimited brands. Be sure to click “save” when you are done.'
        className='margin-right--large'
        onNew={handleNewBrand}
        tip={'Add a new brand and customize colors, logo and banner.'}
      >
        <List.Category>
          {brands.map(brand => (
            <List.Item
              key={brand.id}
              title={brand.brandName}
              value={brand.id}
              onClick={() => fetchBrand({ variables: { id: brand.id }})}
            />
          ))}
        </List.Category>
      </List>
      {brand &&
        <BrandForm
          brand={brand}
          onClose={() => setBrand(null)}
          onSave={handleUpdate}
          onDelete={handleBlockDeletion}
        />
      }
    </div>
  )
}
