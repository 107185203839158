import { Input, InputGroup, ColorInput, ImagePicker } from "../../../../../../components/FormComponents";
import { Controller } from 'react-hook-form';

export const GeneralTab = ({
  register = () => {},
  watch = () => {},
  control,
}) => {
  return (
    <div>
      <Input name='name' register={register} placeholder='Enter name' inputProps={{hidden: true}} />
      <InputGroup title='Text Color'>
        <ColorInput 
          name='textColor'
          register={register}
          initialColor={watch('textColor')}
        />
      </InputGroup>
      <InputGroup title='Background Color'>
        <ColorInput 
          name='backgroundColor'
          register={register}
          initialColor={watch('backgroundColor')}
        />
      </InputGroup>
      <InputGroup title='Button Text Color'>
        <ColorInput 
          name='buttonTextColor'
          register={register}
          initialColor={watch('buttonTextColor')}
        />
      </InputGroup>
      <InputGroup title='Button Background Color'>
        <ColorInput 
          name='buttonBackgroundColor'
          register={register}
          initialColor={watch('buttonBackgroundColor')}
        />
      </InputGroup>
      <InputGroup title='Logo - ideal size is 700x600 pixels'>
        <Controller
          control={control}
          name='logoId'
          render={({ field: { onChange, value } }) => (
            <ImagePicker value={value} onChange={onChange} />
          )}
        />
      </InputGroup>
      <InputGroup className="margin-bottom-0" title='Banner - ideal size is 560x190 pixels'>
        <Controller
          control={control}
          name='bannerId'
          render={({ field: { onChange, value } }) => (
            <ImagePicker value={value} onChange={onChange} />
          )}
        />
      </InputGroup>
    </div>
  )
}