import React, { useEffect, useState } from 'react';
import * as loom from '@loomhq/loom-embed';
import { Input } from "components/FormComponents";
import styles from './LoomSettings.module.scss'

export const LoomSettings = ({register, name, error, watch, loomVideo}) =>{
    
    const [url, setUrl] = useState(null)
    const [videoError, setVideoError] = useState(null)

    const value = loomVideo || watch(`${name}`) 
    
    useEffect(()=>{
        setUrl(null)
        if(!value){
            setVideoError(null)
        }else{
            getIframe(value)
        }
    },[value])

    const getIframe = async (videoUrl)=>{
        if (!videoUrl) return
        try{
            var questionMarkIndex = videoUrl.indexOf('?');
            var cleanedUrl = questionMarkIndex !== -1 ? videoUrl.substring(0, questionMarkIndex) : videoUrl;   
            const res = await loom.oembed(cleanedUrl)
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(res.html, 'text/html');
            const iframeElement = parsedHtml.querySelector('iframe');
            const iframeUrl = iframeElement?.getAttribute('src') || '';
            if (iframeUrl) setUrl(iframeUrl)
            setVideoError(null)
        }catch(e){
            setUrl(null)
            setVideoError('Link not valid or video not supported')
        }
  
    }

    
    return(
        <div className={styles.root}>
            {!loomVideo && 
                <div className={styles.input}>
                    <Input
                    register={register}
                    name={`${name}`}
                    placeholder="https://www.loom.com/share/126094738853485eb8dd3e807bc8a4d1"
                    validators={{ required: true }}
                    error={error}
                    />
                </div>
            }
            {!!url && 
                <div className={styles.container}>
                    <iframe className={styles.iframe} title="Loom Content" src={url} width="100%" height="100%" allowFullScreen />
                </div>
            }

            {videoError && 
                <div className={styles.error}>
                    {videoError}
                </div>
            }
        </div>
    )
}