import styles from './ExternalStakeholderRow.module.scss';
import cn from 'classnames';
import { InitialLetterBadge } from 'components/InitialLetterBadge/InitialLetterBadge';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { Checkbox } from 'components/FormComponents/Checkbox/Checkbox';
import { Button } from 'components/FormComponents';
import { useLazyQuery } from "@apollo/client";
import download from 'downloadjs';
import { loader } from "graphql.macro";
import { consolidatedCsvs } from "utils/helpers";
import { useAppContext } from 'templates/AppLayout/AppLayout';
import { saveAs } from "file-saver";

const MULTIPLE_BLOCK_RESULT_CSV_QUERY = loader("graphql/queries/multiple_block_results.graphql")

export const ExternalStakeholderRow = ({ block, index, stakeholder, register, brand, onSelect, selectedBlocks,id, fetchResult, setLoading }) => {

  const result = block?.relatedBlockResults.find((blockResult) => (blockResult.stakeholderJourney.externalStakeholder?.id === stakeholder?.id))
  const {triggerUnexpectedError}= useAppContext()

  const [fetchCsv, { loading: fetchingEmployeeCsv }] = useLazyQuery(MULTIPLE_BLOCK_RESULT_CSV_QUERY, {
    onCompleted: (data) => {
      const blob = consolidatedCsvs([data.multipleBlockResults[0].result])
      const name = `${data.multipleBlockResults[0].blockName}-${stakeholder.name}`.replace(/ /g, "_")
      saveAs(blob,`${name}.csv`);
    },
    onError: (error) => {
      triggerUnexpectedError();
      setLoading(false);
    },
  });

  const downloadDoc = async () => {
    setLoading(true)
    const {data} = await fetchResult({variables: {blockResultIds: parseInt(id)}})
    const result = data.multipleBlockResults[0]
    const blockName = block?.name?.replace(/\s+/g, '-')
    const name = stakeholder?.name?.replace(/\s+/g, '-')
    download(result.result, `${blockName}-${name}`, "application/pdf")
  }

  return (
    <div className={cn(styles.root)}>
      <div className={styles.checkboxDiv} >
        <Checkbox checked={selectedBlocks.includes(id)} name={`check-${index}`} register={register} onClick={() => {onSelect(result.id)}} />
      </div>
      
      {/* Name */}
      <h1 className={cn(styles.name, 'title-6')}>
        <InitialLetterBadge word={stakeholder?.stakeholderTag?.name} color={stakeholder?.stakeholderTag?.color} />
        <Link to={`/profile/${stakeholder?.id}`} className='link'>{stakeholder?.name}</Link>
      </h1>
      
      {/* Team/Company */}
      <h1 className={cn(styles.brand, 'title-6')}>
        {brand?.name}
      </h1>
      
      {/* Start Date */}
      <Moment className={cn(styles.date)} date={stakeholder?.startDate} format='DD/MM/YYYY' />
      
      {(['questionnaire','quiz'].includes(block.typeOf)) ? (
          <div className={styles.csv}>
            <Button onClick={()=>fetchCsv({variables: {blockResultIds: id}})} type='secondary' icon='download'>Download</Button>
          </div>
        ) : (
          <Button type='secondary' onClick={downloadDoc} className={styles.toggleButton} icon='download'>Download</Button>
        )
      }
    </div>
  )
}