export const BlockInfoItemTypes = {
  Title:      'title',
  Paragraph:  'paragraph', 
  Video:      'video',
  Vimeo:      'vimeo_video',
  Loom:       'loom_video',
  Vidyard:    'vidyard_video',
  Image:      'image',
  GoogleMap:  'google_map',
  TangoEmbed: 'tango_embed',
  Pdf:        'pdf_viewer',
  Url:        'url',
}

export const BlockInfoItemDetails = {
  [BlockInfoItemTypes.Title]: {
    title:  'Add a Title',
  },
  [BlockInfoItemTypes.Paragraph]: {
    title: 'Add a Paragraph', 
  },
  [BlockInfoItemTypes.Video]: {
    title: 'Embed a Youtube Video',
    subTitle: 'This item allows you to show the candidate a Youtube Video'
  },
  [BlockInfoItemTypes.Vimeo]: {
    title: 'Embed a Vimeo Video',
    subTitle: 'This item allows you to show the candidate a Vimeo Video'
  },
  [BlockInfoItemTypes.Loom]: {
    title: 'Embed a Loom Video',
    subTitle: 'This item allows you to show the candidate a Loom Video'
  },
  [BlockInfoItemTypes.Vidyard]: {
    title: 'Embed a Vidyard Video',
    subTitle: 'This item allows you to show the candidate a Vidyard Video'
  },
  [BlockInfoItemTypes.Image]: {
    title: 'Embed an Image',
    subTitle: 'This item allows you to show the candidate an Image'
  },
  [BlockInfoItemTypes.GoogleMap]: {
    title: 'Embed a map widget',
    subTitle: 'This item allows you to show the candidate a map showing a certain location'
  },
  [BlockInfoItemTypes.TangoEmbed]: {
    title: 'Embed a Tango flow widget',
    subTitle: 'This item allows you to show the candidate a Tango Flow guide'
  },
  [BlockInfoItemTypes.Pdf]: {
    title: 'PDF viewer',
    subTitle: 'PDF’s only - ability to load a pdf to be read'
  },
  [BlockInfoItemTypes.Url]: {
    title: 'Url embed widget',
    subTitle: 'This item allows you to embed the URLs you want to show to the candidate'
  },
}

export const BlockInfoItemOptions = [
  { 
    title: BlockInfoItemDetails[BlockInfoItemTypes.Title].title,
    key:   BlockInfoItemTypes.Title, 
  },
  { 
    title: BlockInfoItemDetails[BlockInfoItemTypes.Paragraph].title, 
    key:   BlockInfoItemTypes.Paragraph, 
  },
  { 
    title: BlockInfoItemDetails[BlockInfoItemTypes.Video].title,
    subTitle: BlockInfoItemDetails[BlockInfoItemTypes.Video].subTitle,
    key:   BlockInfoItemTypes.Video, 
  },
  {
    title: BlockInfoItemDetails[BlockInfoItemTypes.Vimeo].title,
    subTitle: BlockInfoItemDetails[BlockInfoItemTypes.Vimeo].subTitle,
    key:   BlockInfoItemTypes.Vimeo,
  },
  {
    title: BlockInfoItemDetails[BlockInfoItemTypes.Loom].title,
    subTitle: BlockInfoItemDetails[BlockInfoItemTypes.Loom].subTitle,
    key:   BlockInfoItemTypes.Loom,
  },
  {
    title: BlockInfoItemDetails[BlockInfoItemTypes.Vidyard].title,
    subTitle: BlockInfoItemDetails[BlockInfoItemTypes.Vidyard].subTitle,
    key:   BlockInfoItemTypes.Vidyard,
  },
  {
    title: BlockInfoItemDetails[BlockInfoItemTypes.Image].title,
    subTitle: BlockInfoItemDetails[BlockInfoItemTypes.Image].subTitle,
    key:   BlockInfoItemTypes.Image,
  },
  {
    title: BlockInfoItemDetails[BlockInfoItemTypes.GoogleMap].title,
    subTitle: BlockInfoItemDetails[BlockInfoItemTypes.GoogleMap].subTitle,
    key:   BlockInfoItemTypes.GoogleMap,
  },
  {
    title: BlockInfoItemDetails[BlockInfoItemTypes.TangoEmbed].title,
    subTitle: BlockInfoItemDetails[BlockInfoItemTypes.TangoEmbed].subTitle,
    key:   BlockInfoItemTypes.TangoEmbed,
  },
  {
    title: BlockInfoItemDetails[BlockInfoItemTypes.Pdf].title,
    subTitle: BlockInfoItemDetails[BlockInfoItemTypes.Pdf].subTitle,
    key:   BlockInfoItemTypes.Pdf,
  },
  {
    title: BlockInfoItemDetails[BlockInfoItemTypes.Url].title,
    subTitle: BlockInfoItemDetails[BlockInfoItemTypes.Url].subTitle,
    key:   BlockInfoItemTypes.Url,
  }
]