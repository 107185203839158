
import { Input, InputGroup, Button } from 'components/FormComponents';
import cn from 'classnames';
import styles from './EditDetailsForm.module.scss';
import { useForm } from 'react-hook-form';

export const EditDetailsForm = ({ stateUser, stateCompany, updateCompany }) => {

  const { register, errors, reset, handleSubmit } = useForm({defaultValues: {
    company: {
      name: stateCompany.name
    },
    user: {
      ...stateUser
    }
  }});

  const onSubmit = (data) => {
    updateCompany({
      variables: {
        companyAttributes: data.company,
        userAttributes: {
          id:             stateUser.id,
          firstName:      data.user.firstName,
          lastName:       data.user.lastName,
          contactNumber:  data.user.contactNumber
        }
      }
    })
    reset(data)
  }

  return (
    <form className={cn('card', styles.root)} onSubmit={handleSubmit(onSubmit)}>
      <div className='card_content'>
        <InputGroup className={styles.input} title='Company Name'>
          <Input 
            name='company.name'
            type='text'
            placeholder='Enter your new company name'
            validators={{required: true}}
            register={register}
            error={errors?.company?.name?.type}
            inputProps={{ tabIndex: 2 }}
          />
        </InputGroup>
        <InputGroup className={styles.input} title='User First Name'>
          <Input 
            name='user.firstName'
            type='text'
            placeholder='Enter your first name'
            validators={{required: true}}
            register={register}
            error={errors?.user?.firstName?.type}
            inputProps={{ tabIndex: 2 }}
          />
        </InputGroup>
        <InputGroup className={styles.input} title='User Last Name'>
          <Input 
            name='user.lastName'
            type='text'
            placeholder='Enter your last name'
            validators={{required: true}}
            register={register}
            error={errors?.user?.lastName?.type}
            inputProps={{ tabIndex: 2 }}
          />
        </InputGroup>
        <InputGroup className={styles.input} title='User Email'>
          <Input 
            name='user.email'
            type='text'
            placeholder='Enter your new user email'
            validators={{required: true}}
            register={register}
            error={errors?.user?.email?.type}
            inputProps={{ tabIndex: 2, disabled: true }}
          />
        </InputGroup>
        <InputGroup className={styles.input} title='User Contact Number'>
          <Input 
            name='user.contactNumber'
            type='text'
            placeholder='Enter your new contact number'
            validators={{required: true}}
            register={register}
            error={errors?.user?.number?.type}
            inputProps={{ tabIndex: 2 }}
          />
        </InputGroup>
      </div>
      <div className={cn('card_footer', 'd-flex', 'justify-content-between')}>
        <Button type='secondary' onClick={reset}>Cancel</Button>
        <Button submit>Save</Button>
      </div>
    </form>
  )
}