import styles from './Checkbox.module.scss';
import cn from 'classnames';
import { useRef, useState } from 'react';
import { useCombinedRefs } from 'hooks/combined-refs';
import { useEffect } from 'react';


export const Checkbox = ({
  register,
  name,
  className,
  onClick  = () => {},
  validators = {},
  value,
  checked
}) => {

  const [style, setStyle] = useState(false);

  const { ref, ...registerProps } = register(name, validators);
  const inputRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, inputRef);

  const handleClick = () => {
    onClick()
    setStyle(!style);
  }

  useEffect(() => {
    setStyle(checked);
  }, [checked])

  return (
    <input
      ref={combinedRef}
      type="checkbox"
      name={name}
      register={register}
      onClick={handleClick}
      value={value}
      className={cn(style ? styles.checkboxActive : styles.checkbox, {[className]: className})}
      {...registerProps}
    />
  )
}


Checkbox.defaultProps = {
  register: (...args) => ({ ref: () => {} }),
};