import { Modal } from './../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { Input, InputGroup, Button, Textarea, ColorInput } from './../../../../components/FormComponents';
import { useEffect } from 'react';

export const UpdateStakeholderTagModal = ({ 
  visible, 
  defaultValues,
  onClose, 
  onSubmit
}) => {

  const { watch, register, reset, handleSubmit, formState: { errors } } = useForm({ defaultValues });

  useEffect(() => reset(defaultValues), [defaultValues])

  return (
    <Modal title='Edit Stakeholder' visible={visible} onClose={onClose}>
      <form className='margin-top--large' onSubmit={handleSubmit(onSubmit)}>
        <InputGroup title='Stakeholder Label'>
          <Input 
            name='name'
            placeholder='Enter a stakeholder label...'
            register={register}
            validators={{ required: true }}
            inputProps={{ tabIndex: 1 }}
            error={errors?.name?.type}
          />
        </InputGroup>
        <InputGroup title='Stakeholder Description'>
          <Textarea 
            name='description'
            placeholder='Enter a description for the stakeholder...'
            register={register}
            inputProps={{ tabIndex: 2, rows: '3' }}
          />
        </InputGroup>
        <InputGroup title='Stakeholder Color'>
          <ColorInput 
            name='color'
            register={register}
            initialColor={watch('color')}
            inputProps={{ tabIndex: 3 }}
          />
        </InputGroup>
        {defaultValues?.typeOf === 'internal' &&
          <InputGroup title='Stakeholder Email & Welcome Message Copy'>
            <Textarea
              name='welcomeMessage'
              placeholder="Enter the messaging you'd like this stakeholder to receive when being alerted of journeys they will be asked to participate in ..."
              register={register}
              inputProps={{ tabIndex: 4, rows: '3' }}
            />
          </InputGroup>
        }
        <div className='d-flex justify-content-between w-100 margin-top--large'>
          <Button type='secondary' onClick={()=>{onClose()}}>Cancel</Button>
          <Button submit>Save</Button>
        </div>
      </form>
    </Modal>
  )
}