import React from "react";

export const MultipleStakeholdersPreview = ({ externalStakeholderTag, styles, externalStakeholdersData }) => {
  return (
    <>
      {externalStakeholdersData?.map((externalStakeholder) => {
        return (
          <div className="margin-top--large mb-2" key={externalStakeholder?.id}>
            <h2 className="title-5 font-weight-400 ">
              <span style={{ color: externalStakeholderTag?.color }}>{externalStakeholderTag?.name}</span> Details
            </h2>
            <div className={styles.stakeholderDetailsWrapper}>
              <div className={styles.stakeholderDetailsItem}>
                <label>Name</label>
                <p>
                  {externalStakeholder?.firstName} {externalStakeholder?.lastName}
                </p>
              </div>
              <div className={styles.stakeholderDetailsItem}>
                <label>Email</label>
                <p>{externalStakeholder?.email}</p>
              </div>
              <div className={styles.stakeholderDetailsItem}>
                <label>Phone</label>
                <p>{externalStakeholder?.phoneNumber}</p>
              </div>
              {externalStakeholder?.startDate && (
                <div className={styles.stakeholderDetailsItem}>
                  <label>Start-date</label>
                  <p>{externalStakeholder?.startDate}</p>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
