import { Input, InputGroup, Textarea } from 'components/FormComponents';
import { EditableSettings } from 'components/EditableSettings/EditableSettings';

export const EmailsTab = ({
  register = () => {}
}) => {
  return (
    <div>
      <InputGroup title='Email From'>
        <Input name='email' register={register} placeholder='Enter email' />
      </InputGroup>
      <EditableSettings title='Use custom email copy' name='useCustomEmailCopy' register={register} startOpen toggleable>
        <InputGroup title='Welcome Text'>
          <Input name='emailWelcomeText' register={register} placeholder='Greetings Dear {{stakeholder_name}} ......' />
        </InputGroup>
        <InputGroup title='Subject'>
          <Input name='emailSubject' register={register} placeholder='You have been invited to a Journey' />
        </InputGroup>
        <InputGroup title='Body'>
          <Textarea name='emailBody' register={register} placeholder='Welcome to Example, here are some onboarding tips for you' inputProps={{ rows: 10 }} />
        </InputGroup>
      </EditableSettings>
    </div>
  )
}