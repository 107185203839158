import { Button, FormMessage } from 'components/FormComponents';
import cn from 'classnames';
import { Loading } from 'components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import styles from './AuthorizationScreen.module.scss';
import { useState } from 'react';
import { useQueryParams } from 'hooks/query-params';
import { Buffer } from 'buffer';
import FeatherIcon from 'feather-icons-react';
import { AccessScopes } from 'constants/access-scopes';

const OAUTH_CLIENT_QUERY = loader("graphql/queries/o_auth_client.graphql");
const AUTHORIZATION_CODE_MUTATION = loader("graphql/mutations/authorization_code.graphql");

export const AuthorizationScreen = () => {

  const navigate = useNavigate();

  const [error, setError] = useState();

  const [oAuthClient, setOAuthClient] = useState();

  const [ clientId, responseType, redirectUri, scope, state ] = useQueryParams(['client_id', 'response_type', 'redirect_uri', 'scope', 'state']);

  const { loading: fetchingClient } = useQuery(OAUTH_CLIENT_QUERY, {
    variables: { clientId },
    errorPolicy: 'none',
    onCompleted: data => {
      setOAuthClient(data.oAuthClient);
    },
    onError: error => {
      if(error.message === 'Unauthorized') {
        const state = Buffer.from(window.location.search).toString('base64');
        navigate(`/sign_in?redirect_uri=authorization&state=${state}`)
      } else {
        setError('Client Error')
      }
    }
  });

  const [ authorizationCode, { loading: gettingAuthorizeCode } ] = useMutation(AUTHORIZATION_CODE_MUTATION, {
    variables: { authorizationAttributes: { clientId, responseType, redirectUri, scope, state }},
    onCompleted: async(data) => {
      const { code } = data.authorizationCode;
      redirect({ code, state })
    },
    onError: error => {
      redirect({
        error_code: 'bad_request',
        error_description: error.message,
        state
      });
    }
  });

  const reject = () => {
    redirect({
      error: 'forbidden',
      error_description: 'User declined authorization',
      state
    });
  }

  const redirect = (params = {}) => {
    if(!redirectUri) {
      setError('Invalid redirect URI');
      return;
    }

    let url = `${redirectUri}?`;
    let keys = Object.keys(params);
    for(let i=0; i < keys.length; i++) {
      const key = keys[i];
      if(!params[key]) continue;
      url += `${key}=${encodeURI(params[key])}&`
    }

    window.location.replace(url);
  }

  return (
    <div className={styles.root}>
      <Loading visible={fetchingClient || gettingAuthorizeCode} />
      <div className='card card-with-shadow margin-top'>
        <div className={cn('card_content', styles.content)}>
          <img className={styles.logo} src='/assets/images/knowmy/knowmy_label.svg' alt='KnowMy Logo' />
          {error ?
            <FormMessage message={error} className='margin-bottom' onClose={() => setError(null)} /> :
            <>
              <FeatherIcon icon='link' size={24}/>
              <img className={styles.logo} src={oAuthClient?.logoUri} alt='Partner Logo' />
              <p className='t-body margin-top'><b>{oAuthClient?.name}</b> is requesting the following permissions</p>
              <ul className={styles.scopes}>
                {scope?.split(' ').map(scope => (
                  <li key={scope}>{AccessScopes[scope]}</li>
                ))}
              </ul>
              <div className='d-flex w-100 justify-content-between'>
                <Button className='w-100 margin-right--small' onClick={authorizationCode}>Accept</Button>
                <Button type='secondary' className='w-100 margin-left--small' onClick={reject}>Cancel</Button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )
}
