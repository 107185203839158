import { Input, InputGroup, Button, FormMessage } from "components/FormComponents";
import cn from "classnames";
import styles from "./EditPasswordForm.module.scss";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import PasswordStrengthBar from "react-password-strength-bar";

export const EditPasswordForm = ({setInvalidPassword, updatePassword, invalidPassword }) => {
  const {
    register,
    reset,
    setError,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [scorePass, setScorePass] = useState(false);
  const [formError, setFormError] = useState();

  const watchPassword = useWatch({ control, name: "user.password" });

  useEffect(() => {
    if (!watchPassword) return;
    if (!scorePass) {
      setFormError("The password is not strong enough");
      setError("weakPassword");
    } else {
      clearErrors("weakPassword");
      setFormError(null);
    }
  }, [watchPassword, scorePass]);

  useEffect(() => {
    if (invalidPassword) {
      setError("user.currentPassword", { type: "custom", message: `${invalidPassword}` });
    }
  }, [invalidPassword]);

  const onSubmit = (data) => {
    const newPassword = data?.user?.password;
    const reenteredPassword = data?.user?.passwordConfirmation;

    if (newPassword !== reenteredPassword) {
      setError("user.password", { type: "custom", message: "The passwords need to match" });
      setError("user.passwordConfirmation", { type: "custom", message: "The passwords need to match" });
    } else if (newPassword === reenteredPassword) {
      clearErrors();
      updatePassword({
        variables: {
          currentPassword: `${data?.user?.currentPassword}`,
          password: `${data?.user?.password}`,
          passwordConfirmation: `${data?.user?.passwordConfirmation}`,
        },
      });
      reset(data);
      setInvalidPassword(false)
    }
  };
  return (
    <>
      {!scorePass && (
        <div className={styles.error}>
          <FormMessage message={formError} className="margin-bottom" onClose={() => setFormError(null)} />
        </div>
      )}
      <form className={cn("card", styles.root)} onSubmit={handleSubmit(onSubmit)}>
        <div className="card_content">
          <InputGroup className={styles.input} title="Current Password">
            <Input
              name="user.currentPassword"
              type="password"
              placeholder="Enter your password"
              validators={{ required: true }}
              register={register}
              error={errors?.user?.currentPassword?.message || errors?.user?.currentPassword?.type}
              inputProps={{ tabIndex: 2 }}
            />
          </InputGroup>
          <InputGroup className={styles.input} title="New Password">
            <Input
              name="user.password"
              type="password"
              placeholder="Enter your new password"
              validators={{ required: true }}
              register={register}
              error={errors?.user?.password?.message || errors?.user?.password?.type}
              inputProps={{ tabIndex: 2 }}
            />
            <PasswordStrengthBar
              password={watchPassword}
              onChangeScore={(score) => {
                score > 2 ? setScorePass(true) : setScorePass(false);
              }}
            />
          </InputGroup>

          <InputGroup className={styles.input} title="Re-enter New Password">
            <Input
              name="user.passwordConfirmation"
              type="password"
              placeholder="Re-enter your new password"
              validators={{ required: true }}
              register={register}
              error={errors?.user?.passwordConfirmation?.message || errors?.user?.passwordConfirmation?.type}
              inputProps={{ tabIndex: 2 }}
            />
          </InputGroup>
        </div>
        <div className={cn("card_footer", "d-flex", "justify-content-between")}>
          <Button
            type="secondary"
            onClick={() => {
              reset();
              setFormError(null);
            }}
          >
            Cancel
          </Button>
          <Button submit>Update Password</Button>
        </div>
      </form>
    </>
  );
};
