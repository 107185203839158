import styles from "./PdfCardPreview.module.scss";
import { Badge } from "react-bootstrap";
import cn from "classnames";
import { useState, useEffect } from "react";
import { BlockStatus } from "constants/block-status-enum";

export const PdfCardPreview = ({
  required,
  stakeholder,
  optionalPdfs,
  block,
  result,
  forceCompletion = false,
  onNavigate = () => {},
  className = "",
}) => {

  const handleClick = () => {
    onNavigate();
  };

  const allComplete = optionalPdfs?.every(obj => obj.status === 'completed');

  const handleBadge = () => {
    return allComplete ? styles.active : styles.inactive;
  };
  const activeBadge = handleBadge();
  const completed = optionalPdfs?.filter(obj => obj.status === 'completed');

  return (
    <div
      className={cn("card", "card-with-border", styles.root, {
        [className]: className,
      })}
      onClick={handleClick}
    >
      <div className={cn("card_content", styles.content)}>
        <Badge
          className={cn("margin-left--small", styles.badge, allComplete ? "badge--status-completed" : "badge--default")}
        >
          {allComplete ? "Completed" : "Nice to do"}
        </Badge>
        <h1 className={styles.title}>Supporting Documentation</h1>
        {
          <span className={cn(styles.blockItem, styles.blockBadge, activeBadge)}>
            {`${completed?.length}/${optionalPdfs.length}`}
          </span>
        }
      </div>
    </div>
  );
};
