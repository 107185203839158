Object.assign(Array.prototype, {
  removeValue(value) {
    const index = this.indexOf(value);
    if(index < 0) return this;
    this.splice(index, 1); 
    return this;
  },

  difference(array) {
    return this.filter(x => !array.includes(x));
  }
});
