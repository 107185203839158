import { useMutation, useQuery } from "@apollo/client";
import { AppLayout, useAppContext } from './../../templates/AppLayout/AppLayout';
import FeatherIcon from 'feather-icons-react';
import { loader } from "graphql.macro";
import { Button } from './../../components/FormComponents';
import { useState } from 'react';
import { NewStakeholderTagModal } from './components/NewStakeholderTagModal/NewStakeholderTagModal';
import { UpdateStakeholderTagModal } from './components/UpdateStakeholderTagModal/UpdateStakeholderTagModal';
import { useEffect } from 'react';
import { StakeholderTagRow } from './components/StakeholderTagRow/StakeholderTagRow';

const STAKEHOLDER_TAGS_WITH_INTERNAL_STAKEHOLDERS_QUERY = loader("./../../graphql/queries/stakeholder_tags_with_internal_stakeholders.graphql");
const CREATE_STAKEHOLDER_TAG_MUTATION = loader("./../../graphql/mutations/create_stakeholder_tag.graphql")
const UPDATE_STAKEHOLDER_TAG_MUTATION = loader("./../../graphql/mutations/update_stakeholder_tag.graphql")
const DELETE_STAKEHOLDER_TAG_MUTATION = loader("./../../graphql/mutations/delete_stakeholder_tag.graphql")

export const StakeholdersScreen = () => {

  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  const [showNewModal, setShowNewModal] = useState(false);
  const [stakeholderTagToUpdate, setStakeholderTagToUpdate] = useState();

  const [externalStakeholderTag, setExternalStakeholderTag] = useState({});
  const [internalStakeholderTags, setInteralStakeholderTags] = useState([]);

  const { loading: fetching } = useQuery(STAKEHOLDER_TAGS_WITH_INTERNAL_STAKEHOLDERS_QUERY, {
    onCompleted: (data) => {
      setExternalStakeholderTag(data.stakeholderTagsWithInternalStakeholders.find(stakeholderTag => stakeholderTag.typeOf === 'external'))
      setInteralStakeholderTags(data.stakeholderTagsWithInternalStakeholders.filter(stakeholderTag => stakeholderTag.typeOf === 'internal'))
    }
  });

  const [ createStakeholderTag, { loading: creating } ] = useMutation(CREATE_STAKEHOLDER_TAG_MUTATION, {
    onCompleted: async(data) => {
      const newStakeholderTag = data.createStakeholderTag;
      setInteralStakeholderTags(curr => [...curr, newStakeholderTag])
      alert('Stakeholder Tag successfully created.')
    }, onError: error => {
      triggerUnexpectedError(error)
    }
  });

  const [ updateStakeholderTag, { loading: updating }] = useMutation(UPDATE_STAKEHOLDER_TAG_MUTATION, {
    onCompleted: async(data) => {
      const updatedStakeholderTag = data.updateStakeholderTag;
      if(updatedStakeholderTag.typeOf === 'external') {
        setExternalStakeholderTag(updatedStakeholderTag)
      } else {
        const stakeholderTags = internalStakeholderTags.map(stakeholderTag => {
          if(stakeholderTag.id === updatedStakeholderTag.id) return updatedStakeholderTag
          else return stakeholderTag;
        })
        setInteralStakeholderTags(stakeholderTags);
      }
      alert('Stakeholder Tag successfully updated.')
    }, onError: error => {
      triggerUnexpectedError(error)
    }
  }); 

  const [ deleteStakeholderTag, { loading: deleting }] = useMutation(DELETE_STAKEHOLDER_TAG_MUTATION, {
    onCompleted: async(data) => {
      const deletedStakeholderTag = data.deleteStakeholderTag;
      setInteralStakeholderTags(curr => curr.filter(stakeholdeTag => stakeholdeTag.id !== deletedStakeholderTag.id));
      alert('Stakeholder Tag successfully deleted.')
    }, onError: error => {
      triggerUnexpectedError(error)
    }
  })

  useEffect(() => { setLoading(fetching || creating || updating || deleting) }, [fetching, creating, updating, deleting])

  const handleNew = (data) => {
    createStakeholderTag({ variables: { stakeholderTagAttributes: data }})
    setShowNewModal(false);
  }

  const handleUpdate = (data) => {
    delete data.__typename
    delete data.typeOf
    delete data.internalStakeholders
    updateStakeholderTag({ variables: { stakeholderTagAttributes: data }})
    setStakeholderTagToUpdate(null);
  }

  const handleDelete = (id) => {
    deleteStakeholderTag({ variables: { id }})
  }

  return (
    <>
      <AppLayout.Header withDivider>
        <AppLayout.Title>Stakeholders</AppLayout.Title>
        <AppLayout.Subtitle>
          Below you can set the label for your primary stakeholder. This label should be whatever makes sense for your business (Eg. Employee, Contractor, Client, etc). A secondary stakeholder is someone that is also involved in the process (Eg. Manager, Director, CEO, etc)
        </AppLayout.Subtitle>
        <div className='d-flex justify-content-end'>
          <Button tip={'Create a new Stakeholder label'} placement={'left'} onClick={() => setShowNewModal(true)}><FeatherIcon icon='plus' size={15} className='margin-right--small'/>Add</Button>
        </div>
      </AppLayout.Header>
      <AppLayout.Body withMargin>
        <NewStakeholderTagModal 
          visible={showNewModal} 
          onClose={() => setShowNewModal(false)}
          onSubmit={handleNew}
        />
        <UpdateStakeholderTagModal 
          visible={!!stakeholderTagToUpdate} 
          defaultValues={stakeholderTagToUpdate}
          onClose={() => setStakeholderTagToUpdate(null)}
          onSubmit={handleUpdate}
        />
        <div className='card card-with-border w-100'>
          <div className='card_content align-items-start'>
            <header className='d-flex align-items-center'>
              <h1 className='title-5'>Primary Stakeholder</h1><span className='t-subtitle padding-left--small'>(This person cannot be deleted, click the edit button to change text)</span>
            </header>
            <div className='margin-top w-100'>
              <StakeholderTagRow stakeholderTag={externalStakeholderTag} onEdit={setStakeholderTagToUpdate} />
            </div>
          </div>
        </div>

        <div className='card card-with-border w-100 margin-top--large'>
          <div className='card_content align-items-start'>
            <header className='d-flex align-items-center'>
              <h1 className='title-5'>Secondary Stakeholder</h1><span className='t-subtitle padding-left--small'>(These are other people that will need to interact with the journey's - Managers, CEO's, IT, Security, etc)</span>
            </header>
            <div className='margin-top w-100'>
              {internalStakeholderTags.map(stakeholderTag => (
                <StakeholderTagRow alert={alert} triggerUnexpectedError={triggerUnexpectedError} key={stakeholderTag.id} stakeholderTag={stakeholderTag} onEdit={setStakeholderTagToUpdate} onDelete={handleDelete} />
              ))}
            </div>
          </div>
        </div>
      </AppLayout.Body>
    </>
  )
}