import styles from "./BlockCardPreview.module.scss";
import { Badge } from "react-bootstrap";
import cn from "classnames";
import { useState, useEffect } from "react";
import { BlockStatus } from "constants/block-status-enum";

export const BlockCardPreview = ({
  required,
  stakeholder,
  block,
  result,
  forceCompletion = false,
  onNavigate = () => {},
  className = "",
}) => {
  const [completed, setCompleted] = useState(false);

  const [badgeClass, setBagdeClass] = useState("");
  const [badgeText, setBadgeText] = useState("");

  useEffect(() => {
    const blockCompleted = isCompleted();
    setCompleted(blockCompleted);

    if (blockCompleted) {
      setBagdeClass("badge--status-completed");
      setBadgeText("Completed");
    } else if (result?.status === "locked") {
      setBagdeClass("badge--info");
      setBadgeText("Blocked");
    } else if (required) {
      setBagdeClass("badge--info");
      setBadgeText("Required");
    } else {
      setBagdeClass("badge--default");
      setBadgeText("Nice to do");
    }
  }, [required, result]);

  const handleClick = () => {
    if (result?.status === "locked") {
      return;
    }
    onNavigate();
  };

  const isCompleted = () => {
    if (forceCompletion) return true;
    if (!result) return;
    return result.status === BlockStatus.Completed;
  };

  const handleBadge = (blockResult) => {
    return blockResult.status === "completed" ? styles.active : styles.inactive;
  };

  const badge = result?.answeredFields;
  const totalFields = block?.fields.filter((t) => t?.typeOf !== "statement");

  const answeredfields = result?.answeredFields ? result?.answeredFields.split("/")[0] : result?.answersAttributes?.length;

  const statements = block?.fields?.filter((t) => t?.typeOf === "statement");
  const displayAnsweredfields = parseInt(answeredfields) - parseInt(statements.length);

  const activeBadge = handleBadge(result);

  return (
    <div
      className={cn("card", "card-with-border", styles.root, {
        [styles.rootDisabled]: result?.status === "locked",
        [className]: className,
      })}
      onClick={handleClick}
    >
      <div className={cn("card_content", styles.content)}>
        <Badge className={cn("margin-left--small", styles.badge, badgeClass)}>{badgeText}</Badge>
        <h1 className={styles.title}>{block?.heading}</h1>
        {
          <span className={cn(styles.blockItem, styles.blockBadge, activeBadge)}>
            {totalFields?.length === 0 ? badge : `${displayAnsweredfields < 0 ? 0 : displayAnsweredfields}/${totalFields.length}`}
          </span>
        }
      </div>
    </div>
  );
};
