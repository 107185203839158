import PropTypes from 'prop-types';
import styles from './EnsureStakeholderLayout.module.scss';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './components/Sidebar/Sidebar';
import { Loading } from '../../components/Loading/Loading';
import cn from 'classnames';
import { useState, createContext, useContext } from 'react';
import { AlertMessage, MessageType } from './components/AlertMessage/AlertMessage';
import { ErrorAlert } from 'components/ErrorAlert/ErrorAlert';
import { RightBar } from 'views/Ensure/StakeholderScreens/StakeholderDashboardScreen/RightBar/RightBar';
import { Modal } from 'components/Modal/Modal';

const EnsureStakeholderLayoutContext = createContext(null);

export const useEnsureStakeholderLayoutContext = () => {
  const context = useContext(EnsureStakeholderLayoutContext)
  if (!context) throw new Error(`App compound components cannot be rendered outside the EnsureStakeholderLayout component`);
  return context
}

export const EnsureStakeholderLayout = () => {

  const [loading, setLoading] = useState(false);
  const [sidebarHidden, setSidebarHidden] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const [alertMessage, setAlertMessage] = useState();
  const [alertMessageType, setAlertMessageType] = useState(MessageType.Success);

  const [showErrorAlert, setShowErrorAlert] = useState([null,false]);

  const alert = (message, type = MessageType.Success) => {
    setAlertMessage(message);
    setAlertMessageType(type);
  }

  const triggerUnexpectedError = (error) => {
    setShowErrorAlert([error || null, true]);
  }

  const { innerWidth: width } = window;

  const contextValue = { setLoading, alert, triggerUnexpectedError };

  return (
    <EnsureStakeholderLayoutContext.Provider value={contextValue}>
      <div className={styles.root}>
        <AlertMessage visible={!!alertMessage} type={alertMessageType} message={alertMessage} onClose={() => alert(null, null)} />
        <ErrorAlert errorMessage={showErrorAlert[0]} visible={showErrorAlert[1]} onClose={() => setShowErrorAlert(false)} />
        <Loading visible={loading} />
        <Sidebar width={width} isCollapsed={isSidebarCollapsed} setIsCollapsed={setIsSidebarCollapsed} onStateChange={setSidebarHidden} />
        <div className={cn(styles.content, {[styles.contentExpanded]: sidebarHidden})}><Outlet /></div>
      </div>
    </EnsureStakeholderLayoutContext.Provider>
  )
}

EnsureStakeholderLayout.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
};

EnsureStakeholderLayout.defaultProps = {
  loading: false,
};


const Header = ({ children, className, withDivider=false }) => (
  <div className={cn(styles.header, { [styles.headerWithDivider]: withDivider, [className]: className })}>
    {children}
  </div>
)
EnsureStakeholderLayout.Header = Header;


const Title = ({ children }) => (
  <h1 className={cn(styles.title, 'title-3')}>{children}</h1>
)
EnsureStakeholderLayout.Title = Title;


const Subtitle = ({ children }) => (
  <p className={styles.subtitle}>{children}</p>
)
EnsureStakeholderLayout.Subtitle = Subtitle;


const Body = ({ children, className, withMargin=false }) => (
  <div className={cn(styles.body, { [styles.bodyWithMargin]: withMargin, [className]: className })}>{children}</div>
)
EnsureStakeholderLayout.Body = Body;
