import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal"
import { useState } from "react"

export const ExternalLinkWarning = ({ url, text }) => {
  
  const [openModal, setOpenModal] = useState(false);

  const handleConfirm = () => {
    window.open(url, '_blank').focus();
    setOpenModal(false);
  }

  return (
    <>
      <ConfirmationModal
        title="You are leaving the app"
        subtitle="If you leave in the middle of a block you will need to come back and complete it"
        visible={openModal}
        onClose={() => {setOpenModal(false)}}
        onConfirmation={handleConfirm}
        buttonTitle={<a target='_blank' href={url}>Leave</a>}
      />
      <a style={{width:'100%'}} target='_blank' onClick={() => {setOpenModal(true)}}>
        {text}
      </a>
    </>
  )
}