import { Button, Input } from "components/FormComponents";
import { AppLayout, useAppContext } from "templates/AppLayout/AppLayout";
import cn from "classnames";
import styles from "./DocumentScreen.module.scss";
import FeatherIcon from "feather-icons-react";
import { Checkbox } from "components/FormComponents/Checkbox/Checkbox";
import { useForm, useWatch } from "react-hook-form";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { useEffect } from "react";
import { ExternalStakeholderRow } from "./ExternalStakeholderRow/ExternalStakeholderRow";
import fuzzysort from "fuzzysort";
import { saveAs } from "file-saver";
import download from 'downloadjs';
import { downloadZip, base64Decoder } from "utils/helpers";
import { consolidatedCsvs } from "utils/helpers";
import { Controller } from "react-hook-form";
import { ReactSelect } from "components/FormComponents/ReactSelect/ReactSelect";
import JSZip from 'jszip'

const BLOCKS_QUERY = loader("graphql/queries/quest_doc_blocks.graphql");
const MULTIPLE_BLOCK_RESULT_CSV_QUERY = loader("graphql/queries/multiple_block_results.graphql")

export const DocumentScreen = () => {
  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    defaultValues: {block: 'empty'}
  });

  const [fetch, { loading: fetching }] = useLazyQuery(BLOCKS_QUERY, {
    onCompleted: (data) => {
      setBlocks(data.questDocBlocks);
      setLoading(false);
    },
    onError: (error) => {
      triggerUnexpectedError();
      setLoading(false);
    },
  });

  const [fetchMultipleBlockResultCsv, { loading: fetchingMultipleCsv }] = useLazyQuery(MULTIPLE_BLOCK_RESULT_CSV_QUERY, {
    onCompleted: (data)=>{setLoading(false)}
    ,onError: (error) => {
      triggerUnexpectedError();
      setLoading(false);
    },
  });
  const [fetchEmployeeCsv, { loading: fetchingEmployeeCsv }] = useLazyQuery(MULTIPLE_BLOCK_RESULT_CSV_QUERY, {
    onCompleted: (data) => {
      var tempCsvData = [];
      data.multipleBlockResults.forEach((csvData, index) => {
        tempCsvData[index] = `${csvData.result} \n`;
      });

      const blob = consolidatedCsvs(data.multipleBlockResults.map((csv)=>csv.result))
      saveAs(
        blob,
        `${activeBlock?.name.replace(/ /g, "_")}.csv`
      );
      setLoading(false);
    },
    onError: (error) => {
      triggerUnexpectedError();
      setLoading(false);
    },
  });

  const [blocks, setBlocks] = useState();
  const [activeBlock, setActiveBlock] = useState();
  const [filtered, setFiltered] = useState();
  const [filterBy, setFilterBy] = useState([]);
  const [relatedblockResults, setRelatedBlockResults] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState("");

  const blockWatch = useWatch({ control, name: "block" });
  const searchName = useWatch({ control, name: "searchName" });
  const checkAll = useWatch({ control, name: "check-all" });

  const blockOptions = blocks?.map((block)=>{
    return {label: block.name , value: block.id}
  })
  blockOptions?.unshift({label:'All', value: 'empty'})
  
  useEffect(() => {
    fetch();
    setLoading(true);
  }, []);

  useEffect(() => {
    setActiveBlock(blocks?.find((block) => block.id === blockWatch));
  }, [blockWatch]);

  useEffect(() => {
    const times = relatedblockResults?.length;
    for (let i = 0; i < times; i++) {
      setValue(`check-${i}`, false);
    }
    setSelectedBlocks([]);
    if (!activeBlock){
      const completedBlocks = blocks?.map((block)=>{
        return block.relatedBlockResults?.filter((relatedBlock)=>relatedBlock.status === 'completed')
      })
      setRelatedBlockResults(completedBlocks?.flat())
      setFiltered(completedBlocks?.flat())
    }else{
      setFiltered(activeBlock?.relatedBlockResults?.filter((e) => e.status === "completed"));
      setRelatedBlockResults(activeBlock?.relatedBlockResults?.filter((e) => e.status === "completed"));
    }
  }, [activeBlock,blocks]);


  useEffect(() => {
    if (searchName && searchName?.trim() !== "" && relatedblockResults) {
      const filter = fuzzysort.go(searchName, relatedblockResults, {
        key: "stakeholderJourney.externalStakeholder.name",
        threshold: -10000,
      });
      const result = [];
      filter.forEach((e) => {
        result.push(e.obj);
      });
      setFiltered(result);
    } else {
      setFiltered(relatedblockResults);
    }
  }, [searchName]);

  useEffect(() => {
    if (selectedBlocks.length === 0) {
      setValue("check-all", false);
    } else if (selectedBlocks.length === relatedblockResults.length) {
      setValue("check-all", true);
    }
  }, [selectedBlocks]);

  const filter = {
    name: {
      asc: (a, b) => b.stakeholderJourney.externalStakeholder.name.localeCompare(a.stakeholderJourney.externalStakeholder.name),
      desc: (a, b) => a.stakeholderJourney.externalStakeholder.name.localeCompare(b.stakeholderJourney.externalStakeholder.name),
    },
    brand: {
      asc: (a, b) => b.stakeholderJourney.brand.name.localeCompare(a.stakeholderJourney.brand.name),
      desc: (a, b) => a.stakeholderJourney.brand.name.localeCompare(b.stakeholderJourney.brand.name),
    },
    date: {
      asc: (a, b) => b.stakeholderJourney.externalStakeholder.startDate.localeCompare(a.stakeholderJourney.externalStakeholder.startDate),
      desc: (a, b) => a.stakeholderJourney.externalStakeholder.startDate.localeCompare(b.stakeholderJourney.externalStakeholder.startDate),
    },
  };

  const handleFilterChange = (column, order) => {
    if (filterBy[0] === column && filterBy[1] === order) {
      if (filterBy[1] === "asc") {
        setFilterBy([column, "desc"]);
        setFiltered(filtered?.sort(filter[column]["desc"]));
      } else {
        setFilterBy([column, "asc"]);
        setFiltered(filtered?.sort(filter[column]["asc"]));
      }
    } else {
      setFilterBy([column, order]);
      setFiltered(filtered?.sort(filter[column][order]));
    }
  };

  const selectAll = () => {
    checkAll ? setSelectedBlocks([]) : setSelectedBlocks(relatedblockResults.map((blockResult) => blockResult.id))
  };

  const onSelect = (id) => {
    selectedBlocks?.includes(id) ? 
    setSelectedBlocks((selectedBlocks)=> selectedBlocks.filter((id_)=> id !== id_)) :
    setSelectedBlocks([...selectedBlocks, id])

  };

  const createBlockObjects = () =>{
    const questionnaires = []
    selectedBlocks?.forEach((id)=>{
      const relatedBlock = relatedblockResults.find((block)=>block.id === id)
      const block = blocks.find((block)=> block.id === relatedBlock.blockId)
      const stakeholderName = relatedBlock?.stakeholderJourney?.externalStakeholder?.name
      questionnaires.push({id: parseInt(id), name: stakeholderName, block: block.name, type: block.typeOf })  
    })
    return questionnaires
  }

  const allInOneCsvDownload = async () => {
    try {
      setLoading(true)
      if (['questionnaire','quiz'].includes(activeBlock.typeOf)) {
        fetchEmployeeCsv({ variables: { blockResultIds: selectedBlocks } });
        setLoading(true);
      } else {
        const questionnaires = createBlockObjects()
        const blobArray = []
        const pdfNames  = []
        const {data} = await fetchMultipleBlockResultCsv({variables: {blockResultIds: questionnaires.map((csv)=>csv.id)}})
        data.multipleBlockResults.forEach((result)=>{
          const relatedBlock = relatedblockResults.find((b)=>parseInt(b.id)===parseInt(result.id))
          pdfNames.push(relatedBlock.stakeholderJourney.externalStakeholder.name)
          const view = base64Decoder(result.result.replace("data:application/pdf;base64, ",""))
          blobArray.push(new Blob([view], {type: "application/pdf"}))
        })
        downloadZip(blobArray, pdfNames, activeBlock.name)
      }
      setLoading(false)
    } catch {
      triggerUnexpectedError();
      setLoading(false);
    }
  };

  const onBulkDownload = async () => {
    const blobArray = []
    try {
        setLoading(true)
        const questionnaires = createBlockObjects()
        const {data} = await fetchMultipleBlockResultCsv({variables: {blockResultIds: questionnaires.map((csv)=>csv.id)}})
        data.multipleBlockResults.forEach((csv)=>{
          const index = questionnaires.find((v)=> parseInt(csv.id) === parseInt(v.id))
          const {name,block, type} = index
          if (['questionnaire','quiz'].includes(type)){
            const blob = consolidatedCsvs([csv.result])
            blobArray.push({ data: blob, filename: `${name}-${block}.csv`, type: 'text/csv' })
          }else{
            const view = base64Decoder(csv.result.replace("data:application/pdf;base64, ",""))
            const blob = (new Blob([view], {type: "application/pdf"}))
            blobArray.push({ data: blob, filename: `${name}-${block}.pdf`, type: 'application/pdf' })
          }
        })  
        const zip = new JSZip();
        blobArray.forEach(({ data, filename, type }) => {
          zip.file(filename, data, { binary: true });
        });
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, `individual_downloads_${data.multipleBlockResults.length}.zip`)
    } catch{
      triggerUnexpectedError();
      setLoading(false);
    }
  };

  return (
    <>
      <AppLayout.Header>
        <AppLayout.Title>Reports</AppLayout.Title>
        <AppLayout.Subtitle>Below you can view and download employee data related to Blocks.</AppLayout.Subtitle>
        <form className="d-flex justify-content-between mt-5">
          <Input name="searchName" type="text" icon="search" placeholder="Search for an employee..." register={register} />
          <div className="flex-03 d-flex align-items-center justify-content-between">
            <div className={cn(styles.selectDiv2, "t-subtitle d-flex align-items-center justify-content-center")}>
              <p className={styles.blockName}>Block Name</p>
              {blockOptions?.length && <Controller
                  name={`block`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <ReactSelect
                      isSearchable
                      options={blockOptions}
                      value={value}
                      placeholder={"Search or Select any"}
                      onChange={onChange}
                      className={cn(styles.select, styles.select2, "ms-3 me-3")}
                      isCustomizedOptionLabel
                      customizedOptionLabel={(option) => (<div>
                        <div className={styles.optionLabel}>{option.label}</div></div>)
                      }
                    />
                  }
                  />}
            </div>
          </div>
        </form>
      </AppLayout.Header>
      <AppLayout.Body>
        <div className={styles.tableHeaders}>
          <Checkbox checked={checkAll} name="check-all" register={register} onClick={()=>selectAll()} />
          <span
            onClick={() => {
              handleFilterChange("name", "asc");
            }}
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Name
            <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
          </span>
          <span
            onClick={() => {
              handleFilterChange("brand", "asc");
            }}
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Brand Name
            <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
          </span>
          <span
            onClick={() => {
              handleFilterChange("date", "asc");
            }}
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Start Date
            <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
          </span>
        </div>
          <div className={cn(styles.rowsDiv)}>
            {filtered?.map((blockResult, index) => {
              return (
                <ExternalStakeholderRow
                  id={blockResult.id}
                  key={blockResult.id}
                  block={activeBlock || blocks?.find((block)=> block.id === blockResult.blockId)}
                  index={index}
                  stakeholder={blockResult?.stakeholderJourney?.externalStakeholder}
                  brand={blockResult?.stakeholderJourney?.brand}
                  register={register}
                  selectedBlocks={selectedBlocks}
                  onSelect={onSelect}
                  setLoading={setLoading}
                  fetchResult={fetchMultipleBlockResultCsv}
                />
              );
            })}
            {selectedBlocks.length > 0 && (
              <div className={styles.downloadButtons}>
                <Button className={styles.downloadButton} onClick={onBulkDownload} type="secondary">
                  <p className={cn(styles.downloadText, "t-small")}>Download Individually {selectedBlocks.length} </p>
                  <FeatherIcon icon="download" size={15} className="margin-left" />
                </Button>
                {activeBlock && <Button className={styles.downloadButton} onClick={allInOneCsvDownload} type="secondary">
                  <p className={cn(styles.downloadText, "t-small")}>Download {selectedBlocks.length} </p>
                  <FeatherIcon icon="download" size={20} className="margin-left" />
                </Button>
                }
              </div>
            )}
          </div>
      </AppLayout.Body>
    </>
  );
};
