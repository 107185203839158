export const HistoryType = ({ history, externalStakeholder }) => {
  const stakeholder_journey = history?.stakeholderJourney || null;
  const internal_stakeholder = history?.internalStakeholder || null;
  const block = history?.block || null;
  const color = internal_stakeholder ? internal_stakeholder?.stakeholderTag?.color : null

  switch(history?.historyType) {
    case 'stakeholder_journey_launched': 
      return (
        <>
          Journey launched for 
          <span style={{color: color}} className='margin-left--small margin-right--small'>
            {externalStakeholder?.firstName}
          </span>
          containing {stakeholder_journey?.blocks.length} onboarding blocks
        </>
      )
    case 'stakeholder_journey_resend':
      return (
        <>
          Journey invite resent for 
          <span style={{color: color}} className='margin-left--small'>
            {internal_stakeholder?.firstName || externalStakeholder?.firstName}
          </span>
        </>
      )
    case 'stakeholder_journey_completed': 
      return (
        <>
          <span style={{color: color}} className='margin-right--small'>
            {internal_stakeholder?.firstName || externalStakeholder?.firstName}
          </span>
          successfully completed {stakeholder_journey?.journey?.name} Journey
        </>
      )
    case 'journey_block_opened': 
      return (
        <>
          <span style={{color: color}} className='margin-right--small'>
            {internal_stakeholder?.firstName || externalStakeholder?.firstName}
          </span>
          opened {block?.name} for the first time
        </>
      )
    case 'journey_block_completed':
      return (
        <>
          <span style={{color: color}} className='margin-right--small'>
            {internal_stakeholder?.firstName || externalStakeholder?.firstName}
          </span>
          successfully completed {block?.name}
        </>
      )
    case 'journey_block_accessed':
      return (
        <>
          <span style={{color: color}} className='margin-right--small'>
            {internal_stakeholder?.firstName || externalStakeholder?.firstName}
          </span>
          opened {block?.name}
        </>
      )
    case 'contract_declined':
      return <p>Text</p>
    case 'recorded_welcome_message':
      return <p>Text</p>
    case 'welcome_message_viewed':
      return <p>Text</p>
    case 'documentation_upload':
      return <p>Text</p>
    default: 
      return <p>Text</p>
  }
}