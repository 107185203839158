import styles from './SignEasyDocumentSigned.module.scss'

export const SignEasyDocumentSigned=()=>{
    return(

    <div className={styles.heading}>
        <h1 className={styles.title} >
            Document has been signed ✅
        </h1>
        <h1 className={styles.subtitle}>
            Close this page and click <span>Save and Close</span> button
        </h1>
    </div>
    )
}