import { loader } from "graphql.macro";
import cn from 'classnames';
import { useMutation } from "@apollo/client";
import styles from './KnowMyIntegrationScreen.module.scss';
import { KnowMyIntegrationLayout, useKnowMyIntegrationLayoutContext } from 'templates/KnowMyIntegrationLayout/KnowMyIntegrationLayout';
import { Button, FormMessage } from "components/FormComponents";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { AccessScopes } from 'constants/access-scopes';
import { useEffect, useState } from "react";
import { LaunchJourneyScreen } from "views";

const AUTHORIZATION_CODE_MUTATION = loader("graphql/mutations/authorization_code.graphql");

export const KnowMyIntegrationScreen = () => {
  const [step, setStep] = useState(1);

  const {
    setAuthCode,
    client,
    setLoading,
    setError,
    error,
    params,
    reject,
  } = useKnowMyIntegrationLayoutContext();



  const [ authorizationCode, { loading: gettingAuthorizeCode } ] = useMutation(AUTHORIZATION_CODE_MUTATION, {
    variables: { authorizationAttributes: params },
    onCompleted: async (data) => {
      const { code } = data.authorizationCode;
      setAuthCode(code)
      setStep(2)
    },
    onError: error => {
      setError(error.message)
    }
  });

  useEffect(() => {
    setLoading(gettingAuthorizeCode)
  }, [gettingAuthorizeCode])


  return (
    <KnowMyIntegrationLayout.Content className={styles.root}>
      <div className={cn('card card-with-shadow margin-top', styles.cardContainer)}>
        <div className={cn('card_content', styles.content)}>
          <img className={styles.logo} src='/assets/images/knowmy/knowmy_label.svg' alt='KnowMy Logo' />
          {
            error ? (
              <FormMessage message={error} className='margin-bottom' onClose={() => setError(null)} />
            ) :
            (
              step === 1 ?
              (
                <>
                  <FeatherIcon icon='link' size={24} />
                  <img className={styles.logo} src={client?.logoUri} alt='Partner Logo' />
                  <p className='t-body margin-top'><b>{client?.name}</b> is requesting the following permissions</p>
                  <ul className={styles.scopes}>
                    {params?.scope?.split(' ')?.map(scope => (
                      <li key={scope}>{AccessScopes[scope]}</li>
                    ))}
                  </ul>
                  <div className='d-flex w-100 justify-content-between'>
                    <Button className='w-100 margin-right--small' onClick={authorizationCode}>Accept</Button>
                    <Button type='secondary' className='w-100 margin-left--small' onClick={reject}>Cancel</Button>
                  </div>
                </>
              ) :
              (
                step === 2 && (
                  <LaunchJourneyScreen
                    setLoading={setLoading}
                    error={error}
                    setError={setError}
                  />
                )
              )
            )
          }
        </div>
      </div>
    </KnowMyIntegrationLayout.Content>
  )
}
