import styles from "./DocumentBlockResult.module.scss";
import cn from "classnames";

export const DocumentBlockResult = ({ block, blockResult }) => {
  return (
    <>
      <h4>Signers:</h4>
      {blockResult?.documentSigners?.map((signer) => (
        <div className={cn(styles.signer, "t-body", "margin-bottom")}>
          <span style={{ color: signer.stakeholder.stakeholderTag.color }} className="margin-right--small">
            {signer.stakeholder.stakeholderTag.name}
          </span>
          <span>{signer.stakeholder.name}</span>
          <span className="t-capitalize">{signer.status.replace("_", " ")}</span>
        </div>
      ))}
    </>
  );
};
