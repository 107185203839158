import { useRef } from 'react';
import { useClickOutside } from '../../hooks/click-outside';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import styles from './Modal.module.scss';

export const Modal = ({ 
  visible,
  className, 
  title,
  subtitle,
  children,
  onClose,
  customStyles = {
    backgroundColor: '#FFF',
    titleColor: '#333333',
  }
}) => {
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, onClose);

  return (
    <div className={cn(styles.root, { [styles.rootVisible]: visible })}>
      <div className={cn(styles.card, 'card', 'card-with-shadow')} ref={wrapperRef} style={{ backgroundColor: customStyles.backgroundColor }}>
        <div className={styles.header}>
          <div className='mw-100'>
            <h1 className='title-3' style={{ color: customStyles.titleColor }}>{title}</h1>
            {subtitle && <p className='t-subtitle mt-2'>{subtitle}</p>}
          </div>
          <FeatherIcon icon="x" onClick={onClose} className={styles.closeBtn}/>
        </div>
        <div className={cn('w-100', { [className]: className })}>
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func
};