export const StakeholderStatusType = [
    {
        key: 'completed',
        value: 'Completed'
    },
    {
        key: 'in_progress',
        value: 'In-Progress'
    },
    {
        key: 'pending',
        value: 'Pending'
    },
    {
        key: 'archived',
        value: 'Archived'
    }
]