import React from 'react';

export const ApiDocumentation = () => {
  return (
    <div style={{height: '100vh', overflow: 'hidden'}}>
      <iframe
        src="/docs/api/index.html"
        title="API Documentation"
        style={{height: '100%', width: '100%'}}
      />
    </div>
  );
}
