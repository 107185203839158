import styles from './OpinionScale.module.scss';
import { useState, useEffect } from 'react';
import cn from 'classnames';

export const OpinionScale = ({
  options: {
    useCustomSteps,
    stepsStart, 
    stepsEnd, 
    labelStart, 
    labelEnd,
  },
  value,
  disabled,
  onChange
}) => {
  const [scale, setScale] = useState([]);

  useEffect(() => {
    const start = useCustomSteps && stepsStart ? parseInt(stepsStart) : 1;
    const end = useCustomSteps && stepsEnd ? parseInt(stepsEnd) : 10;
    const array = Array(end - start + 1).fill().map((_, index) => start + index);
    setScale(array);
  }, [useCustomSteps, stepsStart, stepsEnd])

  const handleClick = (number) => {
    if (disabled) return
    onChange(number)
  }

  return (
    <div className={cn(styles.root)}>
      <div className={styles.scaleWrapper}>
        {scale.map(number => (
          <span 
            key={number} 
            className={cn('t-body', styles.option, { [styles.disabled]: disabled, [styles.optionSelected]: value === number })}
            onClick={() => handleClick(number)}
          >
            {number}
          </span>
        ))}
      </div>
      <label className={styles.labelStart}>{labelStart}</label>
      <label className={styles.labelEnd}>{labelEnd}</label>
    </div>
  )
}