import { InitialLetterBadge } from '../../../../components/InitialLetterBadge/InitialLetterBadge';
import { IconButton } from '../../../../components/IconButton/IconButton';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { useState } from 'react';
import { InternalStakeholdersModal } from './InternalStakeholdersModal/InternalStakeholdersModal';

export const StakeholderTagRow = ({
  stakeholderTag,
  onEdit,
  onDelete,
  triggerUnexpectedError,
  alert
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [internalsModal , setInternalsModal] = useState(false)
  const [selected , setSelected] = useState([])
  
  const onCancel = () => {
    setInternalsModal(false)
    setSelected([])
  }  

  return (
    <div className='d-flex align-items-center justify-content-between w-100'>
      <ConfirmationModal 
        visible={openModal}
        title='Delete Stakeholder Tag Confirmation'
        subtitle='This Stakeholder Tag will be permanently deleted. This data cannot be recovered'
        confirmationKey={stakeholderTag.name}
        confirmationIcon='trash-2'
        onClose={() => { setOpenModal(false) }}
        onConfirmation={() => onDelete(stakeholderTag.id)}
        buttonTitle='Delete Stakeholder Tag'
      />
      <InternalStakeholdersModal alert={alert} triggerUnexpectedError={triggerUnexpectedError} onCancel={onCancel} selected={selected} setSelected={setSelected} tag={stakeholderTag} visible={internalsModal} setModal={setInternalsModal}/>
      <div className='t-body d-flex align-items-center'>
        <InitialLetterBadge word={stakeholderTag.name} color={stakeholderTag.color} /> <p className='margin-left--small'>{stakeholderTag.name} - {stakeholderTag.description}</p>
      </div>
      <div>
        {onDelete && <IconButton tip={`Existing ${stakeholderTag?.name}`} onClick={() => setInternalsModal(true)} className='margin-right--small' icon='users'/>}
        <IconButton tip='Edit' onClick={() => onEdit(stakeholderTag)} icon='edit-2'/>
        {onDelete && <IconButton tip='Delete' onClick={() => setOpenModal(true)} className='margin-left--small' icon='trash'/>}
      </div>
    </div>
  )
}