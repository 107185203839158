import { Input } from "components/FormComponents";

export const TitleSettings = ({ register, name, errors }) => (
  <Input
    register={register}
    name={`${name}.params.text`}
    className="margin-top--large"
    placeholder="Enter Title..."
    validators={{ required: true }}
    error={errors}
  />
);
