import { FieldTypeDetails } from 'constants/field-types-enum';
import styles from './QuestionnairePreview.module.scss';

export const QuestionnairePreview = ({ block }) => (
  <div>
    <p className='t-small font-weight-600'>Block summary ({block.fieldsAttributes.length} fields)</p>
    {block.fieldsAttributes.map(field => (
      <div key={field.id} className={styles.fieldRow}>
        <p className='t-small'>{field.order}. {FieldTypeDetails[field.typeOf].title}:</p> 
        <p className='t-small'>"{field.text}"</p>
      </div>
    ))}
  </div>
)