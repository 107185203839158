import { GoogleLogin } from 'react-google-login';
import styles from './GoogleLogin.module.scss';
import cn from 'classnames';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

export const GoogleLoginButton = ({ signInGoogle = () => {} }) => {
  const success = (res) => {
    signInGoogle({ variables: { email: res.profileObj.email } })
  }

  return (
    <div className={styles.root}>
      <GoogleLogin 
        clientId={clientId}
        buttonText='Log in with Google'
        onSuccess={success}
        cookiePolicy={'single_host_origin'}
        isSignedIn={true}
        className={cn(styles.button,styles.buttonPosition)}
      />
      <p className={cn(styles.border, 'title-6')}>or</p>
    </div>
  )
}