import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import styles from './IconButton.module.scss';
import cn from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const IconButton = ({ icon, className, onClick, tip, placement = 'top' }) => (
  <OverlayTrigger trigger={tip === undefined ? 'none' : ['hover', 'focus']} placement={placement} overlay={<Tooltip>{tip}</Tooltip>}>
    <div className={cn(styles.root, { [className]: className })} onClick={onClick}>
      <FeatherIcon size={17} icon={icon} className={styles.icon} />
    </div>
  </OverlayTrigger>
)

IconButton.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  onClick: () => {}
};
