import styles from './RolePermissionsTab.module.scss';
import {Fragment} from 'react'
import cn from 'classnames';

export const RolePermissionsTab = ({
  permissions
}) => {
  return (
    <div className ={cn(styles.root)} >
      <div className={cn(styles.permissionContainer)}>
        {permissions?.map((perm, index) => (
        <Fragment key={index}>
          <h1 className='title-6'>
            {perm.charAt(0).toUpperCase() + perm.replace(/[^a-zA-Z ]/g, " ").slice(1)}</h1>
          <p className={styles.description}>Be able to {perm.replace(/[^a-zA-Z ]/g, " ")}</p>
        </Fragment>
        ))}
      </div>
    </div>
  )
}
