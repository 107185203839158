import { AppLayout, useAppContext } from './../../templates/AppLayout/AppLayout';
import { Tabs, Tab } from '../../components/Tabs/Tabs';
import { Blocks } from './tabs/Blocks/Blocks';
import { Journeys } from './tabs/Journeys/Journeys';

export const BlockBuilderScreen = () => {

  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  return (
    <>
      <AppLayout.Header>
        <AppLayout.Title>Block Builder</AppLayout.Title>
        <AppLayout.Subtitle>Below you can edit existing block, or build new ones and piece these together to create your employee journey.</AppLayout.Subtitle>
      </AppLayout.Header>
      <AppLayout.Body>
        <Tabs defaultActiveKey='blocks'>
          <Tab eventKey="blocks" title="Blocks">
            <Blocks setLoading={setLoading} alert={alert} error={triggerUnexpectedError} />
          </Tab>
          <Tab eventKey="journeys" title='Journeys'>
            <Journeys setLoading={setLoading} alert={alert} error={triggerUnexpectedError} />
          </Tab>
        </Tabs>
      </AppLayout.Body>
    </>
  )
}