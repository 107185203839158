import styles from './StarRating.module.scss';
import { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';

export const StarRating = ({
  options: {
    useCustomStars,
    stars,
    labelStart, 
    labelEnd
  },
  value,
  onChange,
  disabled
}) => {
  
  const [starsArray, setStarsArray] = useState([]);

  const handleClick = (index) => {
    if (disabled) return
    onChange(index)
  }

  useEffect(() => {
    const starsInt = useCustomStars ? parseInt(stars) : 5;
    const array = Array(starsInt).fill().map((_, index) => index + 1);
    setStarsArray(array);
  }, [useCustomStars, stars])

  return (
    <div className={styles.root}>
      <div className={styles.starsWrapper}>
        {starsArray.map(index => (
          <FeatherIcon 
            className={cn(styles.star, {[styles.disabled]: disabled})}
            key={index}
            icon='star'
            size={35}
            color={index <= value ? '#4932d0' : '#e5e5e5'}
            fill={index <= value ? '#4932d0' : '#f9f9f9'}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>
      <label className={styles.labelStart}>{labelStart}</label>
      <label className={styles.labelEnd}>{labelEnd}</label>
    </div>
  )
}
