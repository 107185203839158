import { useEffect } from 'react';

export const useKeyDown = (key, callback=()=>{}) => {
  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.key == key) callback();
    }

    document.addEventListener("keydown", handleKeyDown);
    
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    
  }, [key]);
}