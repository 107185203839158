import styles from './Sidebar.module.scss';
import { gapi } from 'gapi-script';
import { Link, NavLink } from 'react-router-dom';
import { Fragment, useEffect, useState, useRef } from 'react';
import FeatherIcon from 'feather-icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { stateUser, stateCompany } from './../../../../graphql/state';
import { BiTachometer, BiBarChart } from 'react-icons/bi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { BsPersonVcard, BsBoxes } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { RiAdminLine, RiArrowDownSFill } from 'react-icons/ri';
import cn from 'classnames';
import { useReactiveVar, useMutation, useApolloClient } from '@apollo/client';
import { loader } from "graphql.macro";
import { useAppContext } from 'templates/AppLayout/AppLayout';
import { Loading } from 'components/Loading/Loading';
import jwt from 'jwt-decode'
import { checkPermission } from 'utils/helpers';
import { useNavigate } from "react-router-dom";
import { useClickOutside } from 'hooks/click-outside';
import { useKeyDown } from 'hooks/key-down';



const REMOVE_IMPERSONATION_MUTATION = loader("./../../../../graphql/mutations/remove_impersonation.graphql");


export const Sidebar = ({

  onStateChange = () => {}
}) => {

  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCompanyMenu, setShowCompanyMenu] = useState(false);
  const navigate = useNavigate();
  const client = useApolloClient();
  const { alert, triggerUnexpectedError } = useAppContext();
  const impersonatorId = Cookies.get('token') ? jwt(Cookies?.get('token'))?.impersonator_id : null

  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, ()=>setShowCompanyMenu(false));
  useKeyDown('Escape', () => setShowCompanyMenu(false));

  const [endImpersonation] = useMutation(REMOVE_IMPERSONATION_MUTATION, {
    onCompleted: async (data) => {
      const { token, user, company } = data.removeImpersonation;
      await client.clearStore();
      Cookies.set("token", token, { path: "" });
      stateCompany(company);
      stateUser(user);
      Cookies.remove('impersonatorId')
      setLoading(false);
      alert("User impersonation ended");
      navigate('/')

    },
    onError: (error) => {
      triggerUnexpectedError(error.message);
      setLoading(false);
    },
  });

  const logout = async () => {
    if (gapi.auth2) {
      try {
        const auth2 = gapi.auth2.getAuthInstance();
        await auth2.signOut();
      } catch {
        console.log('No auth2 signOut method')
      }
    }
    Cookies.remove('token', { path: '' });
    stateUser(null);
  }

  const handleEndImpersonation = async () =>{
    setLoading(true);
    await endImpersonation({ variables: { impersonater: `${impersonatorId}` } });
  }

  const handleCompanyMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowCompanyMenu(!showCompanyMenu)
  }

  const user = useReactiveVar(stateUser);
  const company = useReactiveVar(stateCompany);
  const firstLetter = user?.firstName?.split('')[0];

  const NavLinks = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: <BiTachometer fontSize={25} />,
      permission: 'read:journey'
    },
    {
      title: 'Block Builder',
      path: '/block_builder',
      icon: <BsBoxes fontSize={25} />,
      permission: 'read:block'
    },
    {
      title: 'Documents',
      path: '/documents',
      icon: <IoDocumentTextOutline fontSize={25} />,
      permission: 'read:report'
    },
    {
      title: 'Teams',
      path: '/teams',
      icon: <FiUsers fontSize={25} />,
      disabled: true,
      permission: 'read:team'
    },
    {
      title: 'Analytics',
      path: '/analytics',
      icon: <BiBarChart fontSize={25} />,
      disabled: true,
      permission: 'read:analytics'
    },
    {
      title: 'Stakeholders',
      path: '/stakeholders',
      icon: <BsPersonVcard fontSize={25} />,
      permission: 'read:stakeholder_tag'
    },
    {
      title: 'Admin',
      path: '/admin',
      icon: <RiAdminLine fontSize={25} />,
      permission: 'impersonate:user'
    },
  ]


  useEffect(() => onStateChange(hidden), [hidden]);

  // Disabled links
  const checkDisabled = (e, link) => { if(link.disabled) e.preventDefault() }

  if (loading) return <Loading visible={loading} />;


  const displaySideBar = (link)=>(
      <Fragment key={`fragment-${link.path}`}>
        {(checkPermission(user, link.permission)) && <OverlayTrigger
          key={`overlay-${link.path}`}
          trigger={hidden ? ['hover', 'focus'] : null}
          placement='right'
          overlay={<Tooltip>{link.title}</Tooltip>}
        >
          <NavLink
            onClick={e => checkDisabled(e, link)}
            key={`link-${link.path}`}
            to={link.path}
            className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive, [styles.linkDisabled]: link.disabled })}
          >
            {link.icon}
            <div className={styles.title}>{link.title}</div>
          </NavLink>
        </OverlayTrigger>}
      </Fragment>
    )

  return (
    <aside className={cn(styles.root, { [styles.rootHidden]: hidden })}>
      <div className={cn(styles.header)} >
        <Link className={cn(styles.headerLink, 'margin-top--large margin-bottom', { [styles.headerLinkHidden]: hidden })} to='/' >
          <img src='/assets/images/knowmy/knowmy_logo_4.svg' alt='KnowMy Logo' className={styles.logo} />
        </Link>
      </div>
      <div className={styles.content}>
        <div className={styles.menu}>
          <OverlayTrigger trigger={hidden ? ['hover', 'focus'] : null} placement='right' overlay={<Tooltip>{company?.name}</Tooltip>}>
            <NavLink
              to='/company_settings'
              state={{ tabName: "users" }}
              className={({ isActive }) => cn(styles.link,{ [styles.linkActive]: isActive , [styles.linkCompany]: true})}
            >
              <div className={cn(styles.img, 'title-3')}>{company?.name.split('')[0]}</div>
              <span className={styles.title}>{company?.name}</span>
              {!hidden && <div className={styles.downArrow} ref={wrapperRef} onClick={handleCompanyMenu}><RiArrowDownSFill fontSize={25} />
                {showCompanyMenu && <div className={styles.companyMenu}>
                  <NavLink
                    className={styles.companyMenuLink}
                    to='/company_settings'
                    state={{ tabName: "company" }}
                  >
                    Settings</NavLink>
                  <NavLink
                    className={styles.companyMenuLink}
                    to='/company_settings'
                    state={{ tabName: "users" }}
                  >
                    Users</NavLink>
                  <NavLink
                    className={styles.companyMenuLink}
                    to='/company_settings'
                    state={{ tabName: "roles" }}
                  >
                    Roles</NavLink>
                  <NavLink
                    className={styles.companyMenuLink}
                    to='/company_settings'
                    state={{ tabName: "teams" }}
                  >
                    Teams</NavLink>
                  <NavLink
                    className={styles.companyMenuLink}
                    to='/company_settings'
                    state={{ tabName: "branding" }}
                  >
                    Branding</NavLink>
                  <NavLink
                    className={styles.companyMenuLink}
                    to='/company_settings'
                    state={{ tabName: "integrations" }}
                  >
                    Integrations</NavLink>
                </div>}
              </div>}
            </NavLink>
          </OverlayTrigger>
          {NavLinks.map(link => (
            displaySideBar(link)
          ))}
        </div>
        <div className={styles.footer} >
          <OverlayTrigger trigger={hidden ? ['hover', 'focus'] : null} placement='right' overlay={<Tooltip>{user?.firstName}</Tooltip>}>
            <NavLink
              to='/account'
              className={({ isActive }) => cn(styles.link,{ [styles.linkActive]: isActive })}
            >
              <div className={cn(styles.img, 'title-3')}>{firstLetter}</div>
              <span className={styles.title}>{user?.firstName}</span>
            </NavLink>
          </OverlayTrigger>
          <OverlayTrigger trigger={hidden ? ['hover', 'focus'] : null}  placement='right' overlay={<Tooltip>Log out</Tooltip>}>
            <NavLink className={cn( styles.link)} onClick={impersonatorId ? handleEndImpersonation : logout}>
              <div><FeatherIcon icon='log-out' /></div>
              <span className={styles.title}>{impersonatorId ? 'Revert User' : 'Log Out'}</span>
            </NavLink>
          </OverlayTrigger>
        </div>
      </div>
      <FeatherIcon icon='chevron-left' className={styles.toggleButton} onClick={() => setHidden(!hidden)} />
    </aside>
  )
}
