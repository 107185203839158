import { useEffect } from 'react';
import styles from '../AddStakeholderToJourneyScreen/AddStakeholderToJourneyScreen.module.scss';
import { PublicJourneyLayout, usePublicJourneyLayoutContext } from "../../../templates/PublicJourneyLayout/PublicJourneyLayout";
import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useNavigate, useLocation } from 'react-router-dom';
import cn from 'classnames';
import defaultLogo from '../../../assets/images/KM_logo.svg'
import { CustomButton } from 'views/StakeholderJourneyScreens/components/CustomButton/CustomButton'

const JOURNEY_INVITATION_LINK_QUERY = loader("graphql/queries/journey_invitation_link.graphql");

export const PublicJourneyInvitationScreen = () => {

    const { publicJourney, setLoading, triggerUnexpectedError } = usePublicJourneyLayoutContext();

    const navigate = useNavigate();
    const { state } = useLocation();
    const stakeholderJourney = state?.stakeholderJourney

    const [fetchJourneyInvitationLink, { loading: fetching }] = useLazyQuery(JOURNEY_INVITATION_LINK_QUERY, {
    variables: { stakeholderJourneyId: stakeholderJourney?.id },
        onCompleted: (data) => {
        const journeyInvitationLink = data?.journeyInvitationLink
        if (journeyInvitationLink) {
            return navigate(journeyInvitationLink)
        }
    },
    onError: (error) => {
      triggerUnexpectedError('Sorry, something went wrong.')
    }
  });

    const handleJourneyInvitationLink = () => {
        if (publicJourney?.id === stakeholderJourney?.journey?.id) {
            fetchJourneyInvitationLink()
        }
        else {
            triggerUnexpectedError('Something went wrong...')
        }

    }
  useEffect(() => {
    setLoading(fetching)
  }, [fetching])



  const logo = publicJourney?.brand?.logo?.url || defaultLogo;

    return (
        <PublicJourneyLayout.Content className={styles.root}>
            <div className={cn(styles.root, "card", "card-with-border")}>
                <div className={cn("card_content", styles.content)}>
                    <div className={styles.header}>
                        <img
                            className={cn(styles.logo)}
                            src={logo}
                            alt='Brand logo'
                        />
                        <h1 className={styles.title}>Journey Invitation has been sent to your email</h1>
                        <div className={cn('d-flex justify-content-center w-50 margin-top--large')}>
                            <CustomButton
                                className={cn(styles.submitBtn)}
                                brand={publicJourney?.brand}
                                onClick={handleJourneyInvitationLink}
                            >
                                Go to Journey
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        </PublicJourneyLayout.Content>
    )
}
