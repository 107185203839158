import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import { ActionTypesOptions } from 'constants/action-types-enum';
import { Controller, useFieldArray } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { Button, MultipleSelect, Select } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import styles from './ActionSelect.module.scss';
import cn from 'classnames';
import { useWatch } from 'react-hook-form';

export const ActionSelect = ({
  control,
  index,
  setValue,
  registerName,
  register,
  stakeholderTags,
  block,
  errors,
  clearErrors
}) => {

  const { fields: actions, append } = useFieldArray({ control, name: `journeyBlocksAttributes.${index}.actionsAttributes`, keyName: 'key' });
  const blockIndex = index
  const [useActions, setUseActions] = useState(null);

  const tagList = stakeholderTags.filter((stakeholderTag)=> stakeholderTag.name !== 'Employee')
  const actionWatch = useWatch({ control, name: registerName });
  const type = actionWatch?.actionsAttributes
  // Clear errors array when there is no error
  var errorCount = 0
  useActions && errors?.journeyBlocksAttributes?.forEach((block)=>{
    block?.actionsAttributes?.forEach((error)=>{
      errorCount = error ? errorCount + 1 : errorCount
    })
  })

  if (errors?.journeyBlocksAttributes?.length && errorCount===0 && useActions){
    clearErrors(`journeyBlocksAttributes.${index}.actionsAttributes`)
  }


  // Set actions toggle
  useEffect(() => {
    setUseActions(actions?.filter(action => !action._destroy).length > 0)
  }, [actions])

  // Delete actions if toggled off
  useEffect(() => {
    if(useActions === null) return;

    if(!useActions) {
      let _actions = [];
      actions.forEach(action => {
        if(action.id) _actions.push({ ...action, _destroy: true });
      });
      setValue(`journeyBlocksAttributes.${index}.actionsAttributes`, _actions);
    }

    if(useActions && !actions.length) {
      append()
    }
  }, [useActions])

  const addAction = () => {
    append();
  }

  const removeAction = (indexToRemove) => {
    let _actions = [];
    actions.forEach((action, index) => {
      let _action;
      if(index === indexToRemove || action._destroy) {
        _action = {...action, _destroy: true }
      } else {
        _action = action
      }

      delete _action.key;
      if(!_action._destroy || _action.id) _actions.push(_action)
    });
    setValue(`journeyBlocksAttributes.${index}.actionsAttributes`, _actions);
  }

  return (
    <EditableSettings
      title='Set custom actions'
      open={useActions}
      className='w-100 margin-top'
      toggleable
      onChange={e => setUseActions(e.target.checked)}
    >
      {(
        actions.map((action, index) => {
          const updateRegisterName = `${registerName}.actionsAttributes.${index}`
          const stakeholderTagList = action?.actionsStakeholderTagsAttributes?.map((element) => { return element.stakeholderTagId})
          return (
            <>
              {!action._destroy && (
                <div className='d-flex margin-bottom'>
                  <Controller
                    rules={{required: true}}
                    control={control}
                    name={`${updateRegisterName}.typeOf`}
                    render={({ field: { onChange, value } }) => (
                  <Select register={register} name={`${updateRegisterName}.typeOf`} placeholder='Select type' className={cn(styles.typeSelector, styles.leftSelect)} error={errors.journeyBlocksAttributes?.[blockIndex]?.actionsAttributes?.[index]}>
                    {ActionTypesOptions.map((actionType) => (
                      <Select.Item
                        key={actionType.key}
                        value={actionType.key}
                        active={actionType.key === action.typeOf}
                      >
                        {actionType.title}
                      </Select.Item>
                    ))}
                  </Select>
                   )}
                   />
                  <Controller
                    rules={{required: true}}
                    control={control}
                    name={`${updateRegisterName}.actionsStakeholderTagsAttributes`}
                    render={({ field: { onChange, value } }) => (
                      <MultipleSelect value={value} onChange={onChange} valueKey="stakeholderTagId" placeholder='Select Stakeholders' className={cn(styles.multipleSelect)} error={errors.journeyBlocksAttributes?.[blockIndex]?.actionsAttributes?.[index]} lineHeight={styles.multiSelectHeight}>
                        {tagList.map(stakeholderTag => (
                          <MultipleSelect.Item key={stakeholderTag.id} value={stakeholderTag.id} active={stakeholderTagList?.includes(stakeholderTag.id)}>
                            {stakeholderTag.name}
                          </MultipleSelect.Item>
                        ))}
                      </MultipleSelect>
                    )}
                  />
                  <IconButton tip='Delete Field' className={styles.triggerRemove} onClick={() => removeAction(index)} icon='trash-2' />
                </div>
              )}
            </>
          )
        }
      ))}
      {type?.length < 2 && <Button className={styles.addBtn} type='link' onClick={addAction}>+ Add action</Button>}
    </EditableSettings>
  )
}
