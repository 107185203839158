
import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import cn from "classnames";
import style from './BundleBlock.module.scss'

const FETCH_CANDIDATE_TOKEN = loader("graphql/queries/checkmate_candidate_token.graphql")

export const BundleBlock = ({blockResultId, stakeholderId, setLoading, token}) =>{
    
  const [candidateToken, setCandidateToken] = useState(null)
    const { loading: fetchingClient } = useQuery(FETCH_CANDIDATE_TOKEN, {
        variables: { partnerId: blockResultId, token: token, externalStakeholderId: stakeholderId },
        onCompleted: data => {
          if (data?.checkmateCandidateToken){
            setCandidateToken(data?.checkmateCandidateToken)
          }
        },
        onError: error => {
         
        }
      });

    useEffect(()=>{
      setLoading(fetchingClient)
    },[fetchingClient])

    return (
        <div className={cn("card", "card-with-border" , style.root)}>
          
          {candidateToken &&   
            <iframe
              src={`${process.env.REACT_APP_CHECKMATE_URL}form_submission/candidate/${candidateToken}`}
            />
          }
            
        </div>
    )
}