import styles from './UserRow.module.scss';
import { Badge } from 'react-bootstrap';
import { IconButton } from '../../../../../../components/IconButton/IconButton';
import cn from 'classnames';
import { useState } from 'react';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { Checkbox } from 'components/FormComponents/Checkbox/Checkbox';
import { checkPermission, checkSuperAdmin } from 'utils/helpers';
import { stateUser } from "graphql/state";
import { useReactiveVar } from "@apollo/client";

export const UserRow = ({
  user,
  onDelete,
  selected,
  setSelected,
  setActiveUser,
  setIsOpen,
  onResendUserInvitation = () => { }
}) => {

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleCheckboxClick = () => selected?.includes(user?.id) ? setSelected((selected) => selected.filter((id) => id !== user?.id)) : setSelected([...selected, user?.id])

  const authUser = useReactiveVar(stateUser);



  return (
    <div
      className={cn(styles.root, {
          [styles.rootWithoutCompany]: !checkPermission(authUser, 'read:company')
      })}
    >
      <ConfirmationModal
        visible={openConfirmModal}
        title='Delete User Confirmation'
        subtitle='This User will be permanently deleted. This data cannot be recovered'
        confirmationKey={'Delete User'}
        confirmationIcon='trash-2'
        onClose={() => { setOpenConfirmModal(false) }}
        onConfirmation={() => onDelete(user.id)}
        buttonTitle='Delete User'
      />

      {/* Checkbox */}
      <span className={cn(styles.name, 'title-6')}>
        <Checkbox className={styles.checkbox} onClick={handleCheckboxClick} checked={selected?.includes(user?.id)}/>
      </span>

      {/* Company */}
      {checkPermission(authUser, 'read:company') &&
        <span className={cn(styles.name, 'title-6')}>
        {user?.company?.name}
      </span>}

      {/* Name */}
      <h1 className='title-6'>
        <div
          className='link'
          onClick={() => {
            setActiveUser(user)
            setIsOpen(true)
          }}
        >
          {user.name}
        </div>
      </h1>

      {/* Email */}
      <h1 className='title-6'>
        {user?.email}
      </h1>

      {/* Contact Number*/}
      <h1 className='title-6'>
        {user?.contactNumber}
      </h1>

      {/* Verified Status*/}
      <Badge
        className={cn(styles.verifyStatus,`badge ${user.verified ? 'bg-success' : 'bg-warning'} text-white`)}
      >
        {user.verified ? 'Verified' : 'Pending'}
      </Badge>

      {/* Actions */}
      <div className={cn(styles.actions, 'd-flex justify-content-end')}>
        {!user?.verified && <IconButton
          className={styles.resend}
          icon='send'
          tip='Resend Invitation email'
          placement='left'
          onClick={() => onResendUserInvitation(user?.id)}
        />}
        {!checkSuperAdmin(user) && authUser?.id !== user?.id &&
          <IconButton className={styles.deleteBtn} icon='trash-2' tip='Delete' onClick={() => setOpenConfirmModal(true)} />
        }
      </div>
    </div>
  )
}
