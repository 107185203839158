import styles from './StakeholderJourneyLayout.module.scss';
import { Outlet } from 'react-router-dom';
import { Loading } from './../../components/Loading/Loading';
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import cn from 'classnames';
import { useState, createContext, useContext } from 'react';
import { Footer } from './components/Footer/Footer';
import { Navbar } from './components/Navbar/Navbar';
import { useEffect } from 'react';
import { ErrorAlert } from 'components/ErrorAlert/ErrorAlert';

const LayoutContext = createContext(null);

export const useStakeholderJourneyLayoutContext = () => {
  const context = useContext(LayoutContext)
  if (!context) throw new Error(`Stakeholder Journey compound components cannot be rendered outside the StakeholderJourneyLayout component`);
  return context
}

const TOKENIZED_STAKEHOLDER_JOURNEY_QUERY = loader("./../../graphql/queries/tokenized_stakeholder_journey.graphql");

export const StakeholderJourneyLayout = () => {

  const [loading, setLoading] = useState(false);

  const [stakeholderJourney, setStakeholderJourney] = useState({});
  const [showErrorAlert, setShowErrorAlert] = useState([null, false]);

  const triggerUnexpectedError = (error) => {
    setShowErrorAlert([error || null, true]);
  }

  const {token} = useParams();

  const [fetch, { loading: fetching }] = useLazyQuery(TOKENIZED_STAKEHOLDER_JOURNEY_QUERY, {
    variables: { token },
    onCompleted: data => setStakeholderJourney(data.tokenizedStakeholderJourney)
  });

  const updateBlockResult = (data) => {
    if(!data) return;

    const { id, blockId, status, answersAttributes } = data;
    let _stakeholderJourney = { ...stakeholderJourney };

    _stakeholderJourney.stakeholderBlocks = _stakeholderJourney.stakeholderBlocks.map(stakeholderBlock => {
      return stakeholderBlock.block.id === blockId ? { ...stakeholderBlock, result: { id, status, answersAttributes }} : stakeholderBlock
    });

    setStakeholderJourney(_stakeholderJourney);
  }

  useEffect(() => {
    fetch()
  }, [])

  const contextValue = {
    token,
    loading: loading || fetching,
    triggerUnexpectedError,
    stakeholderJourney: stakeholderJourney,
    updateBlockResult,
    setLoading,
    fetch
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      <div className={styles.root}>
        <Loading visible={loading} />
        <ErrorAlert errorMessage={showErrorAlert[0]} visible={showErrorAlert[1]} onClose={() => setShowErrorAlert(false)} />
        <div className={cn(styles.content)}>
          <Outlet />
        </div>
      </div>
    </LayoutContext.Provider>
  )
}

const Content = ({
  withFooter = false,
  withBrandBackgroundColor = false,
  withHeader,
  withCtaButton,
  className,
  children,
  onNavbarCtaClick = () => {},
}) => {

  const { loading, stakeholderJourney } = useStakeholderJourneyLayoutContext()

  return (
    <div
      className={cn(styles.root, {
        [styles.rootWithFooter]: withFooter,
        [styles.rootWithBrandBackgroundColor]: withBrandBackgroundColor,
      })}
      style={{
        backgroundColor: withBrandBackgroundColor ? stakeholderJourney?.brand?.backgroundColor : '#FFF'
      }}
    >
      <Navbar
        brand={stakeholderJourney?.brand}
        withHeader={withHeader}
        withCtaButton={withCtaButton}
        onCtaClick={onNavbarCtaClick}
      />
      <Loading visible={loading} />
      <div className={cn({ [className]: className })}>{children}</div>
      {withFooter && <Footer />}
    </div>
  )
}

StakeholderJourneyLayout.Content = Content

const Title = ({ children }) => (
  <h1 className={cn(styles.title, 'title-3')}>{children}</h1>
)
StakeholderJourneyLayout.Title = Title;


const Subtitle = ({ children }) => (
  <p className={styles.subtitle}>{children}</p>
)
StakeholderJourneyLayout.Subtitle = Subtitle;
