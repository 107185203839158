import Select from "react-select";
import styles from './ReactSelect.module.scss'
import cn from 'classnames';

export const ReactSelect = ({
  name,
  placeholder,
  value,
  className,
  isCustomizedOptionLabel = false,
  isClearable = false,
  isMulti = false,
  isSearchable = false,
  searchKey = 'label',
  disabled = false,
  error,
  options,
  backgroundColor,
  onChange = () => { },
  customizedOptionLabel = () => { },
}) => {

  const styledComponent = {
    control: (base) => ({
      ...base,
      border: error ? '1px solid #EB5757' : '1px solid #E6E6E6',
      '&:hover': {
        border: error ? '1px solid #EB5757' :'1px solid #4932D0',
      },
      borderRadius: 6,
      backgroundColor: backgroundColor || '#f9f9f9',
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '100',
      overflow: 'visible',
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: '12px',
    }),
    multiValue: (provided) => ({
      ...provided,
      marginLeft: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      marginLeft: '12px',
      fontSize: '14px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#333333',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: '#333333',
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '14px',
      marginLeft: '11px',
    }),
  }

  const handleOnChange = (selectedOption) => {
    onChange(selectedOption
      ? isMulti
        ? selectedOption.map((o) => o.value)
        : selectedOption.value
      : null);
  }

  const defaultSelectedValue =
    isMulti && Array.isArray(value)
      ? options.filter((option) => value.some((val) => option.value === val))
      : options?.find((option) => option.value === value)

  const filterOption = (option, inputValue) => {
    return (option.data[searchKey].toLowerCase()).includes(inputValue?.toLowerCase())
  };

  const setCustomizedLabel = (option) => <div className={styles.optionLabel}>
      {isCustomizedOptionLabel? customizedOptionLabel(option) : option?.label }
    </div>

  return (
    <div className={cn(styles.root, {[className]: className})}>
      <Select
        className={styles.selectContainer}
        name={name}
        openMenuOnClick
        onChange={(selectedOption) => handleOnChange(selectedOption) }
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        isDisabled={disabled}
        defaultValue={defaultSelectedValue}
        components={{
          IndicatorSeparator: () => null,
        }}
        getOptionLabel={(option) => setCustomizedLabel(option)}
        filterOption={filterOption}
        placeholder={placeholder || 'Select ...'}
        styles={styledComponent}
      />
      {error && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};
