import { Badge } from 'react-bootstrap';
import styles from './JourneyResult.module.scss';
import { CollapsibleBlock } from 'components/CollapsibleBlock/CollapsibleBlock';
import cn from 'classnames';
import { QuestionnaireBlockResult } from '../QuestionnaireBlockResult/QuestionnaireBlockResult';
import { DocumentBlockResult } from '../DocumentBlockResult/DocumentBlockResult';
import { IconButton } from 'components/IconButton/IconButton';
import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';
import { BlockTypes } from 'constants/block-types-enum';
import { BlockStatus } from 'constants/block-status-enum';
import { loader } from 'graphql.macro';
import { useMutation, useLazyQuery } from '@apollo/client';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { consolidatedCsvs } from 'utils/helpers';

const RESET_RESULT = loader("graphql/mutations/reset_result.graphql");
const BLOCK_RESULT_CSV_QUERY = loader("graphql/queries/multiple_block_results.graphql")

export const JourneyResult = ({
  externalStakeholder,
  status,
  journey,
  blockResults,
  internalStakeholders,
  journeyId,
  resendFunction,
  alert,
  triggerError
}) => {

  const [resetConfirmation, setResetConfirmation] = useState(false);

  const [resetResult, { loading: resetting }] = useMutation(RESET_RESULT, {
    onCompleted: (data) => {
      alert('Block was successfully reseted.')
    },
    onError: (error) => {
      triggerError(error?.message)
    }
  })

  const [fetchResults, {loading: fetching}] = useLazyQuery(BLOCK_RESULT_CSV_QUERY,{
    onCompleted: (data)=>{
      const blob = consolidatedCsvs([data.multipleBlockResults[0].result])
      const name = `${data.multipleBlockResults[0].blockName}-${externalStakeholder?.name}`.replace(/ /g, "_")
      saveAs(blob,`${name}.csv`);
    },
    onError: (error) =>{
      triggerError(error?.message)
    }
  })

  const renderResultType = (block, result) => {
    switch(block.typeOf) {
      case BlockTypes.Questionnaire:
        const otherBlockResults = blockResults.filter(bResult => bResult.id !== result.id && bResult.block.typeOf === 'questionnaire');
        return <QuestionnaireBlockResult otherBlockResults={otherBlockResults} journeyBlocks={journey.journeyBlocks} block={block} blockResult={result} />
      case BlockTypes.DocumentSigning:
        return <DocumentBlockResult block={block} blockResult={result} />
    }
  }

  const downloadResult = (url) => {
    saveAs(url)
  }

  const blockStatus = ((block, result) => {
    switch(block.typeOf) {
      case BlockTypes.Information:
        return result ? result.status : BlockStatus.Pending;
      case BlockTypes.DocumentSigning:
      case BlockTypes.Questionnaire:
        return result ? result.status : BlockStatus.Pending;
      default:
        return BlockStatus.Pending;
    }
  })


  const blockResult = useCallback((blockId) => {
    return blockResults.find(result => result?.block?.id === blockId)
  }, [blockResults])

  const handleReset = (block) => {
    resetResult({
      variables: {
        journeyId: journeyId,
        blockId: block.id
      }
    })
    setResetConfirmation(false)
  }

  return (
    <div className={cn('card', 'card-with-border', styles.root)}>
      <div className={cn(styles.content, 'card_content w-100')}>
        <header className='w-100 d-flex justify-content-between'>
          <div>
            <div className='d-flex justify-content-between'>
              <Badge className={cn(styles.status, `badge--status-${status}`)}>{status.replace('_', ' ')}</Badge>
            </div>
            <h1 className='title-2 margin-top'>{journey.name}</h1>
          </div>
          <IconButton className={styles.resend} icon='send' tip='Resend Journey email' placement='left' onClick={() => {
            resendFunction({
              variables: {
                id: journeyId
              }
            })
          }} />
        </header>

        {internalStakeholders.length > 0 &&
          <div className='card card-with-border background-secondary margin-top--large'>
            <div className='card_content align-items-start'>
              <h2 className='title-6'>Internal Stakeholders</h2>
              {internalStakeholders.map((stakeholder, index) => (
                <div key={index}>
                  <div className={styles.internelStakeholderRow}>
                    <div>
                      <span className='t-tiny'>{stakeholder.stakeholderTag.name}</span>
                      <p className='t-body margin-0 margin-top--small'>{stakeholder.name}</p>
                    </div>
                    <div>
                      <span className='t-tiny'>Email</span>
                      <p className='t-body margin-0 margin-top--small'>{stakeholder.email}</p>
                    </div>
                    <div>
                      <span className='t-tiny'>Number</span>
                      <p className='t-body margin-0 margin-top--small'>{stakeholder.phoneNumber}</p>
                    </div>
                  </div>
                  {index < internalStakeholders.length - 1 &&
                    <hr/>
                  }
                </div>
              ))}
            </div>
          </div>
        }

      </div>
      <div className={cn('card_content', 'background-secondary', styles.content)}>
        {journey.journeyBlocks.map(({ id, block, required }) => {
          const result = blockResult(block?.id);
          const status = blockStatus(block, result)
          return (
            <CollapsibleBlock key={id} color={block.stakeholderTag.color} className={cn(styles.row, 'margin-bottom')}>
              <CollapsibleBlock.Header>
                <header className={styles.header}>
                  <div className={styles.title}>
                    <p className='title-4 font-weight-500'>{block.name}</p>
                    <Badge className={cn('margin-left--small', 't-capitalize', `badge--status-${status}`)}>
                      {status}
                    </Badge>
                    {status !== BlockStatus.Completed &&
                      <Badge className={cn('margin-left--small', { ['badge--success']: required, ['badge--info']: !required })}>
                        {required ? 'Required' : 'Nice to do'}
                      </Badge>
                    }
                  </div>
                  <p className='t-body t-color-secondary margin-bottom-0 margin-top--small'>
                    <span className='t-capitalize t-color-secondary'>{block.typeOf}</span> • Sent to <span style={{ color: block.stakeholderTag.color }}>{block.stakeholderTag.name}</span>
                  </p>
                </header>
                {(result && block.typeOf === 'questionnaire') &&
                  <div className={styles.iconsDiv}>
                    <ConfirmationModal
                      title='Reset block data'
                      buttonTitle='Reset'
                      subtitle='are you sure you want to delete the filled information form this block?'
                      visible={resetConfirmation}
                      onConfirmation={() => handleReset(block)}
                      onClose={() => setResetConfirmation(false)}
                    />
                    <IconButton className={cn(styles.toggleButton, styles.reset, 'margin-right')} icon='rotate-ccw' onClick={() => setResetConfirmation(true)} />
                    <IconButton className={styles.toggleButton} icon='download' onClick={()=>fetchResults({variables: {blockResultIds: result?.id}})}/>
                  </div>
                }
                {result?.resultUrl &&
                  <IconButton className={styles.toggleButton} icon='download' onClick={() => downloadResult(result?.resultUrl)} />
                }
              </CollapsibleBlock.Header>
              <CollapsibleBlock.Content className={cn(styles.blockResult)}>
                {renderResultType(block, result)}
              </CollapsibleBlock.Content>
            </CollapsibleBlock>
          )
        })}
      </div>
    </div>
  )
}
