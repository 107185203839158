import styles from './SignEasyTemplateConfigured.module.scss'

export const SignEasyTemplateConfigured=()=>{
    return(

    <div className={styles.heading}>
        <h1 className={styles.title} >
            Template has been configured ✅
        </h1>
        <h1>
            Press the close button at the bottom to continue
        </h1>
    </div>
    )
}