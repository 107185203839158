import { ImagePicker } from "components/FormComponents";
import { Controller } from "react-hook-form";

export const ImageSettings = ({ control, name,errors }) => (
  <Controller
    control={control}
    name={`${name}.params.imgSrc`}
    rules={{ required: true }}
    render={({ field: { onChange, value } }) => <ImagePicker onChange={onChange} value={value} error={errors} />}
  />
);
