import styles from './TeamMembersTab.module.scss';
import cn from 'classnames';
import { Button, Select } from 'components/FormComponents';
import FeatherIcon from 'feather-icons-react';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { useState, useEffect } from 'react';
import { IconButton } from 'components/IconButton/IconButton';
import { AddTeamMembersModal } from '../AddTeamMembersModal/AddTeamMembersModal';
import { SearchInput } from 'components/SearchInput/SearchInput';
import fuzzysort from "fuzzysort";


export const TeamMembersTab = ({
  users,
  team,
  onAddTeamMembers = () => {},
  onRemoveTeamMembers = () => {},
  register = () => {}
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [filtered, setFiltered] = useState(team?.users)
  const [selectedUserId, setSelectedUserId] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setFiltered(team?.users);
  },[team])

  useEffect(() => {
    if (searchValue !== null && searchValue?.trim() !== "") {
      const filter = fuzzysort.go(searchValue, team?.users, { key: "name", threshold: -10000 });
      const result = [];
      filter.forEach((e) => {
        result.push(e.obj);
      });
      setFiltered(result);
    } else {
      setFiltered(team?.users);
    }
  }, [searchValue]);
  return (
    <div className={cn(styles.root)}>
      <div className={cn(styles.header)} >
        <div className={cn(styles.searchContainer)}>
          <SearchInput className={'w-50'} placeholder='Search...' onChange={setSearchValue}/>
          <Button placement={'right'} onClick={() => {setModal(true)}}><FeatherIcon icon='user-plus' size={15} className='me-2' />Add</Button>
          </div>
      </div>
      <div className={cn(styles.body)}>
        <div className={styles.tableHeaders}>
          <span
            className={cn(styles, styles.filter, "d-flex align-items-center")}
          >
            Name
          </span>
          <span
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Email
          </span>
        </div>
        <div className={cn(styles.tableRowsContainer)}>
          <ConfirmationModal
            visible={openModal}
            title='Unassign Team Confirmation'
            subtitle='This Team will be Unassigned from this user.'
            confirmationKey={'unassign team'}
            confirmationIcon='trash-2'
            onClose={() => { setOpenModal(false) }}
            onConfirmation={() => {
              onRemoveTeamMembers({ userId: selectedUserId, teamId: team?.id })
              setOpenModal(false)
            }}
            buttonTitle='Unassign Team'
          />
          {filtered?.map((user) => {
            return (
              <div className={cn(styles.tableRows)} key={`user-${user.id}`}>
                <span className={cn(styles.name, 'title-6')}>{user?.name}</span>
                <h1 className='title-6'>{user?.email}</h1>
                <div className={cn(styles.actions, 'd-flex justify-content-end')}>
                  <IconButton
                    icon='trash-2'
                    tip='Unassign Team'
                    onClick={() => {
                      setOpenModal(true)
                      setSelectedUserId(user.id)
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <AddTeamMembersModal
        handleAddTeamMembers={onAddTeamMembers}
        team={team}
        users={users}
        onClose={() => {setModal(false)}}
        visible={modal}
      />
    </div>
  )
}
