export const TriggerTypes = {
  BeforeDate:         'before_date',
  AfterDate:          'after_date',
  DependentCompleted: 'dependent_completed', 
}

export const TriggerTypeDetails = {
  [TriggerTypes.BeforeDate]: {
    title:  'Before start date',
  },
  [TriggerTypes.AfterDate]: {
    title:  'After start date',
  },
  [TriggerTypes.DependentCompleted]: {
    title: 'Previous block completed', 
  }
}

export const TriggerTypesOptions = [
  { 
    title: TriggerTypeDetails[TriggerTypes.BeforeDate].title, 
    key:   TriggerTypes.BeforeDate, 
  },
  { 
    title: TriggerTypeDetails[TriggerTypes.AfterDate].title, 
    key:   TriggerTypes.AfterDate, 
  },
  { 
    title: TriggerTypeDetails[TriggerTypes.DependentCompleted].title, 
    key:   TriggerTypes.DependentCompleted, 
  },
];