import styles from "./BlockBuilder.module.scss";
import { Button, EditableTitle } from "components/FormComponents";
import { BlockSettings } from "./components/BlockSettings/BlockSettings";
import { useForm, useWatch } from "react-hook-form";
import { IconButton } from "components/IconButton/IconButton";
import cn from "classnames";
import { useEffect } from "react";
import { stateCompany } from 'graphql/state';
import { useReactiveVar} from '@apollo/client';
import { DocumentSigningBuilder } from "./components/DocumentSigningBuilder/DocumentSigningBuilder";
import { QuestionnaireBuilder } from "./components/QuestionnaireBuilder/QuestionnaireBuilder";
import { InformationBuilder } from "./components/InformationBuilder/InformationBuilder";
import { BlockTypes } from "constants/block-types-enum";
import { StakeholderTagType } from "constants/stakeholder-tag-type-enum";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { useState } from "react";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import { Modal } from "components/Modal/Modal";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { BundleBuilder } from "./components/BundleBuilder/BundleBuilder";
import { QuizBuilder } from "./components/QuizBuilder/QuizBuilder";
import { LogicQuestionnaireSettings } from "./components/LogicQuestionnaireSettings/LogicQuestionnaireSettings";

 const BLOCK_QUERY = loader('./../../../../../graphql/queries/block.graphql')

export const BlockBuilder = ({
  saved,
  setSaved,
  stakeholderTags,
  setLoaderState,
  setLoading,
  triggerUnexpectedError,
  id,
  isDocumentRemoved,
  copyBlock = () => {},
  onSave = () => {},
  onDelete = () => {},
  onClose = () => {},
  getTemplateEditUrl = () => {},
  updateDocumentTemplate = () => { },
  handleRemoveDocument = () =>{}
}) => {
  const {
    register,
    reset,
    control,
    setValue,
    handleSubmit,
    unregister,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const [block , setBlock] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { loading: fetchingBlock } = useQuery(BLOCK_QUERY, {
    variables: {id},
    onCompleted: data => setBlock(data.block),
    onError: error => triggerUnexpectedError(error.message)
  });

  const blockType = useWatch({ control, name: "typeOf" });
  const blockName = useWatch({ control, name: "name" });

  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const company = useReactiveVar(stateCompany);


  useEffect(()=>{
    setLoading(fetchingBlock)
  },[fetchingBlock])

  // Reset form
  useEffect(() => {
      if(block){
      delete block.__typename;
      delete block.stakeholderTag;
      switch (block.typeOf) {
        case BlockTypes.Questionnaire:
          block.fieldsAttributes?.forEach((field) => {
            delete field.__typename;
            if (field.options) delete field.options.__typename;
            field?.options?.options?.forEach((option) => {
              delete option.__typename;
            });
          });
          block?.blockLogicJumpsAttributes?.forEach((jump)=>{
            delete jump.__typename;
          })
          delete block.documentTemplateAttributes;
          delete block.blockInfoItemsAttributes;
          break;
        case BlockTypes.DocumentSigning:
          delete block.documentTemplateAttributes?.__typename;
          delete block.documentTemplateAttributes?.file;
          delete block.documentTemplateAttributes?.signeasyTemplateId
          delete block.documentTemplateAttributes?.documentTemplatesStakeholderTagsAttributes?.forEach((docTempStakeholderTag) => {
            delete docTempStakeholderTag.__typename;
          });
          delete block.documentTemplateAttributes?.customFields?.forEach((customField) => {
            delete customField.__typename;
          });
          delete block.fieldsAttributes;
          delete block.blockInfoItemsAttributes;
          break;
        case BlockTypes.Information:
          block.blockInfoItemsAttributes?.forEach((item) => {
            delete item.__typename;
            if (item.params) delete item.params.__typename;
          });
          delete block.fieldsAttributes;
          delete block.documentTemplateAttributes;
          break;
        case BlockTypes.Bundle:
          delete block?.fieldsAttributes;
          delete block?.documentTemplateAttributes;
          block?.blockInfoItemsAttributes?.forEach((item) => {
            delete item.__typename;
            if (item.params) delete item.params.__typename;
          });
          delete block?.blockInfoItemsAttributes;
          break;
        case BlockTypes.Quiz:
          block.fieldsAttributes?.forEach((field) => {
            delete field.__typename;
            if (field.options) delete field.options.__typename;
            field?.options?.options?.forEach((option) => {
              delete option.__typename;
            });
          });
          delete block.documentTemplateAttributes;
          delete block.blockInfoItemsAttributes;
      }
      reset(block);
    }
  }, [block]);

  const onSubmit = (data) => {
    setIsSubmitted(isSubmitted);
    onSave(data); 
  };

  const onInvalidSubmit = () => {
    setIsSubmitted(!isSubmitted); 
  };

  const renderBlockTypeBuilder = () => {
    switch (blockType) {
      case BlockTypes.Questionnaire:
        return <QuestionnaireBuilder invalid={isSubmitted} blockCode={block.shortCode} control={control} watch={watch} register={register} unregister={unregister}  errors={errors} setValue={setValue} clearErrors={clearErrors} setError={setError}/>;
      case BlockTypes.DocumentSigning:
        return (
          <DocumentSigningBuilder
            block={block}
            control={control}
            register={register}
            errors={errors}
            stakeholderTags={stakeholderTags.filter((st) => st.typeOf === StakeholderTagType.Internal)}
            getTemplateEditUrl={getTemplateEditUrl}
            updateDocumentTemplate={updateDocumentTemplate}
            saved={saved}
            setSaved={setSaved}
            unregister={unregister}
            clearErrors={clearErrors}
            handleRemoveDocument={handleRemoveDocument}
            setError={setError}
            setLoaderState={setLoaderState}
          />
        );
      case BlockTypes.Information:
        return <InformationBuilder control={control} watch={watch} register={register} errors={errors} setValue={setValue} clearErrors={clearErrors} setError={setError}/>;

      case BlockTypes.Bundle:
        return <BundleBuilder control={control} watch={watch} register={register} errors={errors}/>

      case BlockTypes.Quiz:
        return <QuizBuilder control={control} watch={watch} register={register} unregister={unregister}  errors={errors} setValue={setValue} clearErrors={clearErrors} setError={setError}/>

    }
  };

  const handleDelete = () => {
    const blockInfo = watch();
    if (blockInfo?.typeOf === "questionnaire" && blockInfo?.fieldsAttributes.length === 0) {
      onDelete();
    } else if (blockInfo?.typeOf === "information" && blockInfo?.blockInfoItemsAttributes.length === 0) {
      onDelete();
    } else if (blockInfo?.typeOf === "document" && !blockInfo?.documentTemplateAttributes?.fileId) {
      onDelete();
    } else {
      block?.usedInJourney ? setOpenModal(true) : setOpenConfirmationModal(true);
    }
  };

  const handleBlockCopy = (data) =>{
    if (isDocumentRemoved) {
      delete data.documentTemplateAttributes?.id;
      delete data.documentTemplateAttributes?.documentTemplatesStakeholderTagsAttributes?.forEach((docTempStakeholderTag) => {
        delete docTempStakeholderTag.id;
        delete docTempStakeholderTag.deleted;
      });
      handleRemoveDocument(false)
    }
    delete data?.documentTemplateAttributes?.hellosignTemplateId
    delete data?.usedInJourney
    delete data?.usedInStakeholderJourney
    data?.fieldsAttributes?.forEach((e) => {
      delete e.upload
    })

    if(data.heading === 'Enter block heading') {
      data.heading = data.name
    }
    delete data?.shortCode
    copyBlock({variables: {blockAttributes: data}})
  }

  useEffect(() => {
    const list = errors?.fieldsAttributes?.map((field) => field);
    const realErrors = list?.filter((e) => e !== undefined).length;
    const listInformation = errors?.blockInfoItemsAttributes?.map((field) => field);
    const realInfoErrors = listInformation?.filter((e) => e !== undefined).length;

    if (realErrors === 0 || realInfoErrors === 0) {
      clearErrors();
    }
  }, [errors, onSubmit]);
  return (
    <>
      <ConfirmationModal
        visible={openConfirmationModal}
        title="Delete Block Confirmation"
        subtitle="This Block will be permanently deleted. This data cannot be recovered"
        confirmationKey={blockName?.trim()}
        confirmationIcon="trash-2"
        onClose={() => {
          setOpenConfirmationModal(false);
        }}
        onConfirmation={onDelete}
        buttonTitle="Delete Block"
      />
      <Modal
        visible={openModal}
        title="Can't delete this block"
        subtitle="To delete this block please remove it from the journeys that uses it"
        onClose={() => {
          setOpenModal(false);
        }}
      />
      <form className={cn(styles.root, "card card-with-border")} onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <div className={cn("card_content", block?.usedInStakeholderJourney && styles.headerContainer)}>
          <header className={styles.header}>
            <EditableTitle register={register} name="name" value={blockName} disabled={block?.usedInStakeholderJourney} />
            <div className={cn(styles.actions)}>
              {!block?.usedInStakeholderJourney && <div>
                <IconButton tip="Delete Block" icon="trash-2" onClick={handleDelete} />
              </div>}
                <IconButton tip="Make Block Copy" icon="copy" onClick={handleSubmit(handleBlockCopy)}/>
            </div>
          </header>
          { block?.usedInStakeholderJourney && company?.isDropboxCustomizationEnabled &&
            <p className={styles.unlockSubtitle}>
              This block is associated with an in-progress journey. Please click on unlock button to make changes.
            </p>
          }
          <BlockSettings
            register={register}
            unregister={unregister}
            control={control}
            stakeholderTags={stakeholderTags}
            blockExternalStakeholder={blockType === BlockTypes.DocumentSigning}
            isBlockBundle={blockType === BlockTypes.Bundle}
            classNames={block?.usedInStakeholderJourney && styles.hideSettings}
            setValue={setValue}
            />
        </div>
        {block?.usedInStakeholderJourney && company?.isDropboxCustomizationEnabled &&
          <div className={styles.blockWrapper}>
          <div className={styles.unlockBlock} onClick={handleSubmit(onSubmit)}>
            <FeatherIcon icon="unlock" size={30} />
          </div>
        </div>}
        <div className={cn("card_content", "background-secondary", styles.content)}>{renderBlockTypeBuilder()}</div>
        {blockType === BlockTypes.Questionnaire &&
          <div className={styles.logic}>
          <LogicQuestionnaireSettings
            register={register}
            control={control}
            watch={watch}
            unregister={unregister}
            setValue={setValue}
          />
        </div>
        }
        <div className="card_footer d-flex justify-content-between">
          <Button type="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button submit>Save</Button>
        </div>
      </form>
    </>
  );
};
