// The idea is to get a video URL like https://www.youtube.com/watch?v=CODEOFVIDEO and transform it into https://www.youtube.com/embed/CODEOFVIDEO if needed.
// code source: https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url

export default function youtube_parser(url){
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match&&match[7].length==11)? match[7] : false;
}

export function vimeo_parser(url){
  var regExp = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
  return url.match(regExp) ? true : false
}
