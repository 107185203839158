import { EditableSettings } from '../../../../../../../../../../../components/EditableSettings/EditableSettings';
import { Fragment } from 'react';
import { ToggleSwitch, Input } from '../../../../../../../../../../../components/FormComponents';
import { Collapse } from 'react-bootstrap';
import styles from './YesNoSettings.module.scss'
import { InlineOptionsSelector } from '../../../../../../../../../../../components/FormComponents';
import { Controller } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';

export const YesNoSettings = ({ setValue, register, control, name, field, errors, clearErrors }) => {
  const selector = useWatch({control, name: `${name}.answer`})
  useEffect(()=>{
    if(!selector){
      setValue(`${name}.answer`, 'Yes')
    }
  },[])
  return (
    <Fragment>
      <EditableSettings title='Settings' className='margin-top--large' startOpen={true}>
        <ToggleSwitch register={register} name={`${name}.required`} label='Required' />
        <hr />
        <Fragment>
          <ToggleSwitch register={register} name={`${name}.useDescription`} label='Add description' />
          <Collapse in={field.options?.useDescription}>
            <div><Input register={register} name={`${name}.description`} className='margin-top--small' placeholder='Enter description...'/></div>
          </Collapse>
        </Fragment>
        <hr/>
        <Fragment>
          <ToggleSwitch register={register} name={`${name}.useCustomMarks`} label='Add custom marks for this question (Default is 1)' />
          <Collapse in={field.options?.useCustomMarks}>
            <div><Input type='number' register={register} name={`${name}.marks`} className='margin-top--small' placeholder='Enter Marks'/></div>
          </Collapse>
        </Fragment>
        
      </EditableSettings>
      <hr className='my-5' />
      <div className={styles.answer}>
        <Controller
          control={control}
          register={register}
          name={`${name}.answer`}
          render={({ field: { onChange, value } }) => (
            <InlineOptionsSelector  className={styles.selector} value={value} onChange={onChange} selectedColor={"#9B51E0"}>
              <InlineOptionsSelector.Option value={'Yes'}>Correct Answer is "Yes"</InlineOptionsSelector.Option>
              <InlineOptionsSelector.Option value={'No'}>Correct Answer is "No"</InlineOptionsSelector.Option>
            </InlineOptionsSelector>
          )}
        />
      </div>
    </Fragment>
  )
}
