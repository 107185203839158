import { useEffect } from "react";
import { Input, InputGroup } from "components/FormComponents";
import styles from './JourneyPassingMarks.module.scss'

export const JourneyPassingMarks = ({journeyIncomplete, setError, clearErrors, watch, journeyBlocks, blocks, register, errors}) =>{

    const quizBlocks = blocks?.filter((block)=>{
        return journeyBlocks?.find((jB)=>jB.blockId === block.id)
    })

    const marksSum = quizBlocks.reduce((sum, obj) => sum + obj.marks, 0);
    const value = watch()?.passingMarks
    
    useEffect(()=>{
        if(value > marksSum){
            setError('marksIssue', {type: `Passing marks cant be greater than total marks (${marksSum})`})
        }else{
            clearErrors('marksIssue')
        }
    },[value])
    return(
    <>
        {!!marksSum &&
        <div className="w-100">
            <InputGroup title={`Passing Marks For Quiz (out of ${marksSum})`}>
                <Input
                register={register}
                name='passingMarks'
                placeholder='Enter the marks in numbers'
                type="number"
                validators={{required: true}}
                inputProps={{disabled: journeyIncomplete}}
                error={errors?.passingMarks?.type}
                />
            </InputGroup>
            {(errors?.marksIssue ) && <span className={styles.error}>{errors?.marksIssue?.type}</span> }

        </div>
        }
    </>
)
}