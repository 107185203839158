import { EditableSettings } from '../../../../../../../../../../../components/EditableSettings/EditableSettings';
import { Fragment, useState, useEffect } from 'react';
import { ToggleSwitch, Input, Select, InputGroup } from '../../../../../../../../../../../components/FormComponents';
import { Collapse } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import styles from './StarRatingSettings.module.scss';

export const StarRatingSettings = ({ register, name, field }) => (
  <Fragment>
    <EditableSettings title='Settings' className='margin-top--large' startOpen={true}>
      <ToggleSwitch register={register} name={`${name}.required`} label='Required' />
      <hr />
      <Fragment>
        <ToggleSwitch register={register} name={`${name}.useDescription`} label='Add description' />
        <Collapse in={field.options?.useDescription}>
          <div><Input register={register} name={`${name}.description`} className='margin-top--small' placeholder='Enter description...'/></div>
        </Collapse>
      </Fragment>
      <hr />
      <Fragment>
        <ToggleSwitch register={register} name={`${name}.useCustomStars`} label='Customise stars' />
        <Collapse in={field.options?.useCustomStars}>
          <div className='margin-top'>
            <InputGroup title='Stars' className='margin-right--large'>
              <Select register={register} name={`${name}.stars`} className='margin-right'>
                <Select.Item value='5'>5</Select.Item>
                <Select.Item value='7' active={true}>7</Select.Item>
                <Select.Item value='10' active={true}>10</Select.Item>
              </Select>
            </InputGroup>
            <div className='d-flex align-items-center margin-top'>
              <InputGroup title='Left Label' className='margin-right--large'>
                <Input name={`${name}.labelStart`} placeholder='Enter label' register={register} />
              </InputGroup>
              <InputGroup title='Right Label' className='margin-left'>
                <Input name={`${name}.labelEnd`} placeholder='Enter label' register={register} />
              </InputGroup>
            </div>
          </div>
        </Collapse>
      </Fragment>
    </EditableSettings>
    <hr className='my-5' />
    <StarsPreview {...field.options} />
  </Fragment>
)

const StarsPreview = ({ useCustomStars, stars, labelStart, labelEnd }) => {

  const [starsArray, setStarsArray] = useState([]);

  useEffect(() => {
    const starsInt = useCustomStars ? parseInt(stars) : 5;
    const array = Array(starsInt).fill().map((_, index) => index);
    setStarsArray(array);
  }, [useCustomStars, stars])

  return (
    <div className={styles.starsPreview}>
      <div className={styles.starsWrapper}>
        {starsArray.map(index => (
          <FeatherIcon className={styles.star} key={index} icon='star' size={35} />
        ))}
      </div>
      <label className={styles.labelStart}>{labelStart}</label>
      <label className={styles.labelEnd}>{labelEnd}</label>
    </div>
  )
}
