import { useEffect, useState } from 'react';

export const useTimer = (seconds, callback = () => {} ,displayTime=null, setDisplayTime=null) => {

  const [timer, setTimer] = useState(seconds);
  const [active, setActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if (active) {
      interval = setInterval(() => {
        if(timer <= 0) setActive(false);
        else setTimer(time => time - 1);
      }, 1000);
      if (displayTime !== null){
        setDisplayTime(timer)
     }
    } else {
      callback();
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [active, timer]);

  return {
    start: () => {
      setActive(true);
      setTimer(seconds);
    }
  }
}