export const QuizFieldTypes = {
    FreeText:       'free_text',
    MultipleChoice: 'multi_choice', 
    YesNo:          'yes_no', 
    Video:          'video',
    Statement:      'statement'
  }
  
  export const QuizFieldTypeDetails = {
    [QuizFieldTypes.FreeText]: {
      title:  'Free Text',
      subTitle: 'Answer freely with unlimited text answers'
    },
    [QuizFieldTypes.MultipleChoice]: {
      title: 'Multi-Choice', 
      subTitle: 'Select single or multiple answers'
    },
    [QuizFieldTypes.YesNo]:          {
      title: 'Yes/No',
      subTitle: 'Simple Yes or No answer'
    },
    [QuizFieldTypes.Video]: {
        title: 'Youtube Video',
        subTitle: 'Ability to load and view any video on youtube'
    },
    [QuizFieldTypes.Statement]: {
      title: 'Statement',
      subTitle: 'A basic statement that does not require an answer'
    },
  }
  
  export const QuizFieldTypesOptions = [
    { 
      title: QuizFieldTypeDetails[QuizFieldTypes.FreeText].title,
      subTitle: QuizFieldTypeDetails[QuizFieldTypes.FreeText].subTitle,
      key:   QuizFieldTypes.FreeText,
    },
    { 
      title: QuizFieldTypeDetails[QuizFieldTypes.MultipleChoice].title,
      subTitle: QuizFieldTypeDetails[QuizFieldTypes.MultipleChoice].subTitle,
      key:   QuizFieldTypes.MultipleChoice, 
    },
    { 
      title: QuizFieldTypeDetails[QuizFieldTypes.YesNo].title,
      subTitle: QuizFieldTypeDetails[QuizFieldTypes.YesNo].subTitle,
      key:   QuizFieldTypes.YesNo, 
    },
    {
      title: QuizFieldTypeDetails[QuizFieldTypes.Video].title,
      subTitle: QuizFieldTypeDetails[QuizFieldTypes.Video].subTitle,
      key:   QuizFieldTypes.Video,
    },
    {
      title: QuizFieldTypeDetails[QuizFieldTypes.Statement].title,
      subTitle: QuizFieldTypeDetails[QuizFieldTypes.Statement].subTitle,
      key:   QuizFieldTypes.Statement,      
    }
  ];