import styles from './AlertMessage.module.scss';
import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTimer } from '../../../../hooks/timer';

export const MessageType = {
  Success: 'Success',
  Error: 'Error',
  Info: 'Info',
}

export const AlertMessage = ({ visible, type, message, onClose = () => {}, buttonMessage = false, onClickMessageButton = () => {}, buttonClass }) => {

  useEffect(() => { if(visible) startTimer() }, [visible])

  const { start: startTimer } = useTimer(5, onClose);

  return (
    <div className={cn(styles.root, styles[`root${type}`], { [styles.rootVisible]: visible })}>
      <FeatherIcon size={18} className='margin-right--small' icon={type === MessageType.Success ? 'check' : 'alert-circle'}/>
      {message} {!!buttonMessage && <div className={buttonClass} onClick={onClickMessageButton}> {buttonMessage}  </div>}
    </div>
  )
}

AlertMessage.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.oneOf([MessageType.Success, MessageType.Error, MessageType.Info]),
  onClose: PropTypes.func
};