import { CollapsibleGroup } from 'components/CollapsibleBlock/CollapsibleBlock';
import FeatherIcon from 'feather-icons-react';
import { useFieldArray } from 'react-hook-form';
import { FieldConfig } from './components/FieldConfig/FieldConfig';
import { QuizFieldTypes } from 'constants/quiz-field-types-enum';
import { Button } from 'components/FormComponents';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

export const QuizBuilder = ({ control, watch, register, unregister, errors, setValue, clearErrors,setError }) => {

  const { fields, append, swap, remove } = useFieldArray({ control, name: 'fieldsAttributes', keyName: 'key' });

  const watchFields = watch('fieldsAttributes');

  const addField = () => {
    append({
      order: fields.length + 1,
      text: '',
      typeOf: QuizFieldTypes.FreeText,
      options: {required: true, options: [{ value: '' }] }
    });
  }

  const handleFieldRemove = (indexToRemove) => {
    let _fields = [];
    let order = 1;
    fields.forEach((field, index) => {
      let _field;
      if(index === indexToRemove || field._destroy) {
        _field = {...field, _destroy: true }
      } else {
        _field = {...field, order: order }
        order++;
      }
      delete _field.key;
      if(!_field._destroy || _field.id) _fields.push(_field)
    });
    remove(indexToRemove)
    setValue('fieldsAttributes', _fields);
  }

  const onDragEnd = ({ source, destination }) => {
    if(!source || !destination) return;

    swap(source.index, destination.index);
    setValue(`fieldsAttributes[${source.index}].order`, source.index + 1);
    setValue(`fieldsAttributes[${destination.index}].order`, destination.index + 1);
  }

  return (
    <>
      <CollapsibleGroup newStartOpen={true}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='options'>
            {({ innerRef, droppableProps, placeholder }) => (
              <div className='w-100' {...droppableProps} ref={innerRef}>
                {fields.map((field, index) => (
                  (!field._destroy &&
                    <FieldConfig
                      key={field.key}
                      fieldObject={field}
                      index={index}
                      register={register}
                      unregister={unregister}
                      control={control}
                      field={watchFields[index]}
                      errors={errors?.fieldsAttributes?.[index]}
                      remove={handleFieldRemove}
                      watch={watch}
                      clearErrors={clearErrors}
                      setError={setError}
                      setValue={setValue}
                    />
                  )
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </CollapsibleGroup>
      <Button className='w-100' type='secondary-reverse' onClick={addField}><FeatherIcon size={18} className='margin-right' icon='plus'/>Add Field</Button>
    </>
  )
}
