import { useState, useEffect, useMemo } from 'react';

export const useVariableText = (text, object) => {
  const [variables, setVariables] = useState({});

  useEffect(() => {
    if(!object) return;

    setVariables({
      stakeholder_name:           object?.externalStakeholder?.firstName,
      internal_stakeholder_name:  object?.internalStakeholder?.firstName,
      company_name:               object?.brand?.name,
      internal_stakeholder_full_name: object?.externalStakeholder?.name,
    })
  }, [object])

  return useMemo(() => {
    if(!text) return '';
    if(!variables) return text;

    return text.replace(/\{\{(.*?)\}\}/gi, (_, key) => {
      return variables[key]
    })   
  }, [text, variables]);
}