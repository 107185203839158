import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import styles from './PhoneNumberPicker.module.scss';
import cn from 'classnames';

export const PhoneNumberPicker = ({
  value,
  className,
  onChange = () => {},
  disabled
}) => {

  return (
    <PhoneInput
      className={cn(styles.root, { [className]: className, [styles.disabled]: disabled })}
      placeholder="Enter phone number"
      value={value}
      defaultCountry="NZ"
      onChange={onChange}
      disabled={disabled}
    />
  )
}