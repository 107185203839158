import styles from './Navbar.module.scss';
import { CustomButton } from './../../../../views/StakeholderJourneyScreens/components/CustomButton/CustomButton';
import cn from 'classnames';
import defaultLogo from '../../../../assets/images/KM_logo.svg'

export const Navbar = ({ brand, withHeader, withCtaButton = false, onCtaClick = () => {} }) => {
  const logo = brand?.logo?.url;
  return (
    <>
      {withHeader && <nav className={styles.header} style={{ backgroundColor: brand?.backgroundColor }}>
        <div className={styles.navItems}>
          <div className={styles.logoContainer}>
            <img
              className={cn(logo ? styles.logo : styles.defaultLogo)}
              src={logo || defaultLogo}
              alt='Brand logo'
            />
            <h1 style={{ color: brand?.textColor }} className={cn(styles.journey, 'title-3 margin-left')}>{brand?.name} Journey</h1>
          </div>
          {withCtaButton && <CustomButton brand={brand} className={styles.ctaButton} onClick={onCtaClick}>Save and close</CustomButton>}
        </div>
      </nav>}
    </>
  )
}
