import { Select } from "components/FormComponents";
import { useForm, useWatch } from "react-hook-form";
import { useState , useEffect } from "react";
import { BlockTypesList } from "constants/block-types-enum";
import ProgressBar from "@ramonak/react-progress-bar";
import styles from './OverviewTab.module.scss';
import cn from 'classnames';
import { BlockCardPreview } from "views/StakeholderJourneyScreens/components/BlockCardPreview/BlockCardPreview";
import { StakeholderJourneyStatusType } from "constants/stakeholder-journey-status-enum";
import { titleize } from "utils/helpers";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const OverviewTab = ({currentStakeholder}) =>{
    const {
        register,
        control,
        } = useForm({
            reValidateMode: "onChange",
            criteriaMode: "firstError",
            shouldFocusError: true,
            useDefaultValues: { tag: "", status: "empty", journey: "empty" },
            defaultValues: {
            status: ''
        }
      });

    const navigate = useNavigate();

    const [filtered , setFiltered] = useState(currentStakeholder?.stakeholderJourneys)

    const statusWatch = useWatch({ control, name: "status" });
    const blockTypeWatch = useWatch({ control, name: "type" });
  
    useEffect(()=>{
        setFiltered(currentStakeholder?.stakeholderJourneys)
    },[currentStakeholder])
    
    useEffect(()=>{
        const filteredWithStatus = statusWatch !== 'empty' ? 
        currentStakeholder.stakeholderJourneys?.filter((f) => f.status === statusWatch)
        : currentStakeholder.stakeholderJourneys;
        
        const filteredData = !blockTypeWatch || blockTypeWatch === 'empty' 
        ? filteredWithStatus 
        : filteredWithStatus.filter(item => item.stakeholderBlocks.some(block => block.block.typeOf === blockTypeWatch));
    
        setFiltered(filteredData);
    },[statusWatch, blockTypeWatch])

    const countStakeholderBlocks = () => {
        let totalBlocks = 0;
        let completedBlocks = 0;
        
        currentStakeholder?.stakeholderJourneys?.forEach(journey => {
            journey?.stakeholderBlocks?.forEach((stakeholderBlock) => {
                totalBlocks++;
                if (stakeholderBlock?.result?.status === 'completed') {
                    completedBlocks++;
                }
            });
        });
        
        return { totalBlocks, completedBlocks };
    }
    const { totalBlocks, completedBlocks } = countStakeholderBlocks();

    const completedPercentage = () => {
        return Math.floor((totalBlocks > 0) ? (completedBlocks / totalBlocks) * 100 : 0);
    }
  
    return (
        <>
            <div className="d-flex justify-content-between">
                <div>
                    <h1>
                        Journey Information
                    </h1>
                    <p className={styles.subtitle}>Edit and review your journey information below</p>
                </div>
                <div className="flex-04 d-flex align-items-center justify-content-between w-50">
                    <div className="t-subtitle d-flex align-items-center justify-content-center w-50">
                        Status
                        <Select register={register} name={`status`} placeholder="Select any" className={cn(styles.select, "ms-3 me-3")}>
                                <Select.Item key={"null"} value={"empty"} active={statusWatch === ""}>
                                Any
                                </Select.Item>
                                {StakeholderJourneyStatusType?.map((status, index) => (
                                <Select.Item key={index} value={status.key} active={statusWatch === status.key}>
                                    {titleize(status.value)}
                                </Select.Item>
                                ))}
                        </Select>
                    </div>
                    <div className="t-subtitle d-flex align-items-center justify-content-center w-50">
                        Type
                        <Select register={register} name={`type`} placeholder="Select any" className={cn(styles.select, "ms-3 me-3")}>
                                <Select.Item key={"null"} value={"empty"} active={statusWatch === ""}>
                                Any
                                </Select.Item>
                                {BlockTypesList?.map((status, index) => (
                                <Select.Item key={index} value={status.key} active={statusWatch === status.key}>
                                    {status.title}
                                </Select.Item>
                                ))}
                        </Select>
                    </div>
                </div>
            </div>
        <div className={styles.body} >
            <div className={cn("card card-with-border", styles.card)}>
                <p className={styles.text}><span>{`${totalBlocks}/${completedBlocks}`}</span> Blocks completed from<span>{` ${currentStakeholder?.stakeholderJourneys?.length} `}</span>Journeys</p>
                <ProgressBar className={styles.progressBar} labelAlignment="center" bgColor="#4932d0" maxCompleted={100} completed={completedPercentage()} />
            </div>
        </div>
        <hr className={styles.lineBreak}/>
            {
                filtered?.map((sj)=>{
                    const name = sj?.name
                    const status = titleize(sj?.status)

                    return(
                        <div className={styles.root}>
                            <div className={styles.title}>
                                <p className="title-3">{name}</p> 
                                <Badge className={cn(styles.badge, `badge--status-${sj?.status}`)}>{status}</Badge>
                            </div>
                            <div className={cn(styles.block)}>
                            {
                                sj?.stakeholderBlocks?.map((stakeholderBlock)=>{
                                    return(
                                        <div className={cn(styles.card,"w-100")}>
                                            <BlockCardPreview
                                            key={stakeholderBlock?.journeyBlockId}
                                            stakeholder={""}
                                            {...stakeholderBlock}
                                            onNavigate={() => navigate(`/journey/${sj.token}/block/${stakeholderBlock.journeyBlockId}`)}
                                            />
                                        </div>
                                    )
                                })
                            }
                            </div>
                            <hr className={styles.lineBreak}/>
                        </div>
                    )
                })
            }
     </>
    )
}