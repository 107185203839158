import { createContext, useEffect, useContext, useState, useRef, useMemo } from 'react';
import { useCombinedRefs } from '../../../hooks/combined-refs';
import { useClickOutside } from '../../../hooks/click-outside';
import PropTypes from 'prop-types';
import styles from './Select.module.scss';
import cn from 'classnames';
import { ChangeEvent } from '../../../utils/form-utils';

const SelectContext = createContext(null);

const useSelectContext = () => {
  const context = useContext(SelectContext)
  if (!context) throw new Error(`Select compound components cannot be rendered outside the Select component`)
  return context
}

export const Select = ({
  register,
  name,
  className,
  placeholder,
  disabled,
  children,
  description,
  error,
  buttonStyles,
  menuStyles
}) => {

  // Be able to use ref with register
  const { ref, ...registerProps } = register(name);
  const inputRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, inputRef);
  const showError = error?.typeOf || error?.type
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setShowMenu(false));

  const [option, setOption] = useState({ label: '', value: null });

  const [ showMenu, setShowMenu ] = useState(false);
  const checkError = (showError) =>{
    return showError ?  styles.red : ''
  }

  // Change option
  useEffect(() => {
    setShowMenu(false);
    if(!inputRef.current || !option.value) return;

    const { value: currentValue } = inputRef.current;
    inputRef.current.value = option.value;
    if(currentValue !== option.value && registerProps?.onChange) {
      registerProps.onChange(ChangeEvent(name, option.value))
    }
  }, [option, inputRef]);

  // Expose to context
  const contextValue = useMemo(() => ({ option, setOption }), [option])

  return (
    <SelectContext.Provider value={contextValue}>
      <div ref={wrapperRef} className={cn(styles.root, { [className]: className })}>
        <input name={name} ref={combinedRef} {...registerProps}/>
        <div className={cn(`${styles.button} ${checkError(showError)} ${buttonStyles}`, { [styles.disabled]: disabled })} onClick={() => { if(!disabled) setShowMenu(!showMenu) }}>
          <span className="u-text--ellipsis">{option.label ? option.label : <span className={styles.placeholder}>{placeholder}</span>}</span>
        </div>
        <ul className={cn(styles.menu, {[menuStyles]:menuStyles, [styles.menuVisible]: showMenu})}>
          {children}
          {(showMenu && description) && <span className={cn(styles.fieldDescription, 't-subtitle')}>{description}</span>}
        </ul>
        {(showError ) && <span className={styles.error}>{showError?.type || error?.type}</span> }
      </div>
    </SelectContext.Provider>
  )
}

Select.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.object,
  className: PropTypes.string,
};

Select.defaultProps = {
  register: (...args) => ({ ref: () => {} }),
  placeholder: ''
};

const Item = ({
  value,
  children,
  description,
  active=false,
  disabled
}) => {
  const { setOption } = useSelectContext();

  const handleSelected = () => {
    setOption({ label: children, value: value })
  }

  useEffect(() => { if(active) handleSelected() }, [active])

  return (
    <li className={cn(styles.item, {[styles.disabled]: disabled})} onClick={handleSelected}>
      {children}
      <p className={styles.itemDescription}>{description}</p>
    </li>
  )
}

Item.propTypes = {
  value: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
}

Select.Item = Item;


