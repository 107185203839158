import { EnsureStakeholderLayout } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { List } from "../StakeholderDashboardScreen/RightBar/Components/List/List";
import { Tabs,Tab } from "components/Tabs/Tabs";
import styles from './StakeholderTasksListScreen.module.scss'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import cn from "classnames";
import { BiListCheck } from "react-icons/bi";

export const EnsureStakeholderTasksListScreen = () =>{

    const navigate = useNavigate();

    const [isOpen , setIsOpen] = useState(true)

    const onClose=()=>{
        navigate('/ensure_dashboard')
    }

    
    return(
        <EnsureStakeholderLayout.Body>
            <div className={cn(styles.root)}>
                <div>
                    <div className={styles.headerBackground}>
                        <div className={styles.header}>
                            <div className={styles.iconName}>
                                <BiListCheck color="#1f4f5c" fontSize={30} />
                                <p>My Tasks</p>
                            </div>
                            <FeatherIcon onClick={onClose} icon='x' className={styles.closeBtn} />
                        </div>
                    </div>
                </div>
                <div className={styles.navigation}>
                    <Tabs  contentStyles={styles.content} navTabsClassName={styles.tabs} activeTabColour={styles.activeTabColor} defaultActiveKey="upcoming">
                        <Tab eventKey="upcoming" title="Upcoming">
                            <div className={styles.container}>
                                <List/>
                                <List/>
                                <List/>
                                <List/>
                            </div>
                        </Tab>
                        <Tab eventKey="overdue" title="Overdue">
                            <List/>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </EnsureStakeholderLayout.Body>
    
    )
}