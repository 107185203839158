import { Button } from "components/FormComponents"
import { useForm } from 'react-hook-form';
import { Checkbox } from "components/FormComponents/Checkbox/Checkbox";
import styles from './PermissionsForm.module.scss'
import fuzzysort from "fuzzysort";
import { useEffect, useState } from "react";
import { Input } from "components/FormComponents";

export const PermissionsForm = ({user, permissions, permissionIds, setModal, updatePermissions, roleId}) => {

    const [totalPermissions, setTotalPermissions] = useState(permissions)    
    const [selectedIds, setSelectedIds] = useState(permissionIds)
    
    const {
        register,
        formState: { errors },
        watch,
        setValue,
        control,
        handleSubmit
      } = useForm({
        reValidateMode: "onChange",
        criteriaMode: "firstError",
        shouldFocusError: true,
        useDefaultValues: { tag: "empty", status: "empty", journey: "empty" },
        defaultValues: {
          status: 'empty'
        }
      });

    const searchName = watch("searchName");

    useEffect(() => {
        if (searchName !== null && searchName?.trim() !== "") {
          const filter = fuzzysort.go(searchName, totalPermissions, { key: "name", threshold: -10000 });
          const result = [];
          filter.forEach((e) => {
            result.push(e.obj);
          });
          setTotalPermissions(result);
        } else {
          setTotalPermissions(permissions);
        }
    }, [searchName, permissions]);

    useEffect(()=>{
        setSelectedIds(permissionIds)
    },[permissionIds])


    const handleCheckBox = (value) =>{
        selectedIds.includes(value) ? setSelectedIds((selectedIds) =>selectedIds.filter((id) => id !== value)) : setSelectedIds([...selectedIds,value]) 
    }

    const onSubmit = () =>{
        updatePermissions({variables: {permissionIds: selectedIds, userId: user?.id, roleId: roleId}})
        setModal(false)
    }

    return(
        <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.content}>
                <Input className={styles.input} name="searchName" register={register} type="text" icon="search" placeholder="Search for permissions" />
                {
                    totalPermissions?.map((permission,index)=>{
                        return(
                            <div className={styles.item}>
                                <Checkbox key={index} onClick={()=>handleCheckBox(permission?.id)} className={styles.checkBox} checked={selectedIds.includes(permission?.id)}/>
                                <li className={styles.li}>{permission?.name}</li>
                            </div>
                        )
                    })
                }
            </div>
            <div className={styles.buttonDiv}>
              <Button onClick={() => setModal(false)} type='secondary' className={styles.closeBtn}>Cancel</Button>
              <Button submit className={styles.confirmBtn}>Save</Button>
            </div>
        </form>
    )
}