import styles from "./CompanyRow.module.scss";
import cn from "classnames";
import Moment from "react-moment";

export const CompanyRow = ({ company }) => {
  return (
    <div className={cn(styles.root)}>
      {/* company */}
      <span className={styles.statusParent}>{company?.id}</span>
      {/* Name */}
      <h1 className={cn(styles.name, "title-6")}>{company?.name}</h1>
      {/* email */}
      <span>{company?.poc?.name}</span>
      {/* Verified */}
      <span>{company?.poc?.email}</span>
      <span className={styles.name}> <Moment date={company?.createdAt} format='DD/MM/YYYY' /></span>
    </div>
  );
};
