import React, { useEffect, useRef, useState } from 'react';
import styles from './VideoWidget.module.scss';
import cn from 'classnames';
import urlParser from 'utils/format-video-url.js';

// Global state to manage YouTube API loading
let loadingPromise = null;

const loadYouTubeAPI = () => {
  if (!loadingPromise) {
    loadingPromise = new Promise((resolve) => {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = () => resolve(window.YT);
    });
  }
  return loadingPromise;
};

export const VideoWidget = ({
  src,
  className,
  noClickable = true,
  loop = true,
  autoplay = true,
  mute = true,
  trackWatchTime = false,
  setValue,
  name,
}) => {
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const [watchTime, setWatchTime] = useState(0);
  const watchTimeIntervalRef = useRef(null);
  useEffect(() => {
    let isMounted = true;

    const initPlayer = async () => {
      if (!src || !containerRef.current) return;

      const YT = await loadYouTubeAPI();
      if (!isMounted) return;

      const videoId = urlParser(src);

      if (playerRef.current) {
        playerRef.current.destroy();
      }

      playerRef.current = new YT.Player(containerRef.current, {
        height: '100%',
        width: '100%',
        videoId: videoId,
        playerVars: {
          autoplay: autoplay ? 1 : 0,
          loop: loop ? 1 : 0,
          mute: mute ? 1 : 0,
          controls: 0,
          modestbranding: 1,
          fs: 0,
          disablekb: 0,
          rel: 0,
          playlist: loop ? videoId : undefined,
        },
        events: {
          onReady: (event) => {
            if (autoplay) event.target.playVideo();
            if (mute) event.target.mute();
          },
          onStateChange: (event) => {
            if (trackWatchTime) {
              if (event.data === YT.PlayerState.PLAYING) {
                watchTimeIntervalRef.current = setInterval(() => {
                  setWatchTime((prevTime) => {
                    const newTime = prevTime + 1;
                    setValue(name, newTime);
                    return newTime;
                  });
                }, 1000);
              } else if (event.data === YT.PlayerState.PAUSED || event.data === YT.PlayerState.ENDED) {
                if (watchTimeIntervalRef.current) {
                  clearInterval(watchTimeIntervalRef.current);
                }
              }
            }
          },
        },
      });
    };

    initPlayer();

    return () => {
      isMounted = false;
      if (playerRef.current && playerRef.current.destroy) {
        playerRef.current.destroy();
      }
      if (watchTimeIntervalRef.current) {
        clearInterval(watchTimeIntervalRef.current);
      }
    };
  }, [src, autoplay, loop, mute, trackWatchTime, setValue, name]);

  return (
    <div className={cn(styles.root, className)}>
      <div ref={containerRef} className={styles.player}></div>
      {noClickable && <div className={styles.overlap}></div>}
    </div>
  );
};