import React from "react";
import { Controller } from "react-hook-form";
import { InlineOptionsSelector } from "components/FormComponents";
import { CollapsibleBlock } from "components/CollapsibleBlock/CollapsibleBlock";
import cn from "classnames";
import { useWatch } from "react-hook-form";
import { useEffect } from "react";
import { Select, InputGroup, Input } from "components/FormComponents";

export const CustomActionsStakeholders = ({
  clearErrors,
  errors,
  unregister,
  register,
  setValue,
  name,
  internalStakeholders,
  tagId,
  control,
  styles,
  typeOf,
  block,
  index,
  actionId,
  journeyBlockId,
  stakeholderTags
}) => {
  useEffect(() => {
    setValue(`formType${name}`, 2);
    setValue(`customActions.${index}.journeyBlockId`,journeyBlockId)
    setValue(`customActions.${index}.typeOf`,typeOf)
    setValue(`customActions.${index}.stakeholderTagId`,tagId)
    setValue(`customActions.${index}.actionId`,actionId)
  }, []);

  var errorCount = 0;

  errors?.forEach((error)=>{
    errorCount = error ? errorCount+1 : errorCount
  })
  if (errors?.length && errorCount === 0) {
    clearErrors(`customActions`);
  }

  const holders = internalStakeholders?.filter((stakeholder) => stakeholder.stakeholderTag.id === tagId);
  const stakeholderTag = stakeholderTags?.find((stakeholder) => stakeholder.id === tagId)
  const selectorValue = useWatch({ control, name: `formType${name}` });

 
  useEffect(() => {
    if (selectorValue === 1) {
      unregister(`${name}.firstName`);
      unregister(`${name}.lastName`);
      unregister(`${name}.phoneNumber`);
      unregister(`${name}.email`);
    } else {
      unregister(`${name}.internalStakeholderId`);
    }
  }, [selectorValue]);

  return (
    <>
      {!!stakeholderTag && (
        <CollapsibleBlock startOpen keyCode={`${index}`} color={stakeholderTag?.color} className={styles.marginBottom}>
          <CollapsibleBlock.Header>
            <h1 className={styles.blockTitle}>
              <span>
                <span style={{ color: stakeholderTag.color }}>{stakeholderTag.name}</span>
                's <i>{block?.name}</i> {typeOf} Details
              </span>
              <p className={cn(styles.subTitle, "t-subtitle")}>Enter the details of the {stakeholderTag.name} below</p>
            </h1>
          </CollapsibleBlock.Header>
          <CollapsibleBlock.Content className="margin-top">
            <div className="mt-5 mb-5">
              <Controller
                control={control}
                name={`formType${name}`}
                render={({ field: { onChange, value } }) => (
                  <InlineOptionsSelector value={value} onChange={onChange} selectedColor={stakeholderTag.color}>
                    <InlineOptionsSelector.Option value={1}>Existing {stakeholderTag.name}</InlineOptionsSelector.Option>
                    <InlineOptionsSelector.Option value={2}>New {stakeholderTag.name}</InlineOptionsSelector.Option>
                  </InlineOptionsSelector>
                )}
              />
            </div>
            <div>
              {selectorValue === 1 ? (
                <ExistingStakeholdersSelector
                  errors={errors}
                  index={index}
                  name={name}
                  control={control}
                  register={register}
                  stakeholders={holders}
                />
              ) : (
                <StakeholderForm index={index} errors={errors} name={name} register={register} />
              )}
            </div>
          </CollapsibleBlock.Content>
        </CollapsibleBlock>
      )}
    </>
  );
};

const StakeholderForm = ({ index,register, name, errors }) => {
  const error = errors?.[index]
  return (
    <div>
      <div className="inline">
        <InputGroup title="First Name">
          <Input
            name={`${name}.firstName`}
            placeholder="Add first name..."
            register={register}
            required
            error={error?.firstName?.message}
          />
        </InputGroup>
        <InputGroup title="Last Name">
          <Input
            name={`${name}.lastName`}
            placeholder="Add last name..."
            register={register}
            required
            error={error?.lastName?.message}
          />
        </InputGroup>
      </div>
      <div className="inline">
        <InputGroup title="Email">
          <Input
            name={`${name}.email`}
            placeholder="Add email address..."
            register={register}
            type="email"
            required
            error={error?.email?.message}
          />
        </InputGroup>
        <InputGroup title="Phone Number">
          <Input
            name={`${name}.phoneNumber`}
            placeholder="Add phone number..."
            register={register}
            type="phone"
            required
            error={error?.phoneNumber?.message}
          />
        </InputGroup>
      </div>
    </div>
  );
};

const ExistingStakeholdersSelector = ({ index, errors, name, stakeholders, register, control }) => {
  const error = errors?.[index]
  return (
    <div>
      <Controller
        rules={{ required: true }}
        control={control}
        name={`${name}.internalStakeholderId`}
        render={({ field: { onChange, value } }) => (
          <div>
            <Select
              error={error?.internalStakeholderId}
              register={register}
              name={`${name}.internalStakeholderId`}
              placeholder={`${stakeholders?.length ? 'Select existing stakeholders' : 'No Existing Stakeholder'}`}
            >
              {stakeholders?.map((stakeholder) => (
                <Select.Item key={stakeholder?.id} value={stakeholder.id} active={stakeholder?.id === value?.id}>
                  {stakeholder?.firstName} {stakeholder?.lastName} ({stakeholder?.email})
                </Select.Item>
              ))}
            </Select>
          </div>
        )}
      />
    </div>
  );
};
