import { CollapsibleBlock } from '../../../../../../components/CollapsibleBlock/CollapsibleBlock';
import { Select } from '../../../../../../components/FormComponents';
import { IconButton } from '../../../../../../components/IconButton/IconButton';
import { InitialLetterBadge } from '../../../../../../components/InitialLetterBadge/InitialLetterBadge';
import styles from './JourneyBlockConfig.module.scss';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { TriggerSelect } from './components/TriggerSelect/TriggerSelect';
import { ActionSelect } from './components/ActionSelect/ActionSelect';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ReactSelect } from 'components/FormComponents/ReactSelect/ReactSelect';

export const JourneyBlockConfig = ({
  journey,
  journeyBlockObject,
  journeyBlock,
  blocks,
  index,
  register,
  watch,
  control,
  setValue,
  remove,
  stakeholderTags,
  useWatch,
  errors,
  clearErrors
}) => {
  const registerName = `journeyBlocksAttributes.${index}`;
  const block = blocks.find(block => block.id === journeyBlock.blockId)
  const journeyBlocks = useWatch({control, name: `journeyBlocksAttributes`})
  const [usedBlocksIds, setUsedBlocksIds] = useState([]);
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const blockOptions = blocks?.filter((block) => !usedBlocksIds?.includes(`${block.id}`))?.map(block =>
  { return { label: block.name, value: block.id, tagName: block?.stakeholderTag?.name, tagColor: block?.stakeholderTag?.color } })

  useEffect(() => {
    setUsedBlocksIds(journeyBlocks.map((journeyBlock) => (journeyBlock.blockId)))
  }, [journeyBlocks])

  return (
    <Draggable draggableId={journeyBlockObject.key} index={index} isDragDisabled={isDragDisabled}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div {...draggableProps} {...dragHandleProps} ref={innerRef}>
          <CollapsibleBlock className={styles.root} color={block?.stakeholderTag?.color} keyCode={`${index}`}>
            <CollapsibleBlock.Header>
              <span className={styles.order}>{journeyBlock.order}</span>
              <div className={cn(styles.blockSelector, 'w-100')} onMouseEnter={() => setIsDragDisabled(true)} onMouseLeave={() => setIsDragDisabled(false)}>
                <Controller
                  name={`${registerName}.blockId`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <ReactSelect
                      isSearchable
                      options={blockOptions}
                      value={value}
                      onChange={onChange}
                      isCustomizedOptionLabel
                      customizedOptionLabel={(option) => (<div className={styles.optionLabel}>
                        <InitialLetterBadge word={option.tagName} color={option.tagColor} className='margin-right--small' /> {option.label}</div>)
                      }
                    />
                  }
                  />
              </div>
              <Select register={register} name={`${registerName}.required`} className={styles.requiredSelector}>
                <Select.Item value='true' active={journeyBlock.required === true}>Required</Select.Item>
                <Select.Item value='false' active={journeyBlock.required === false}>Not required</Select.Item>
              </Select>
              <IconButton tip='Delete Block' className={styles.removeButton} onClick={() => remove(index)} icon='trash-2' />
            </CollapsibleBlock.Header>
            <CollapsibleBlock.Content>
              <TriggerSelect
                control={control}
                index={index}
                setValue={setValue}
                journeyBlocks={journeyBlocks}
                registerName={registerName}
                register={register}
                watch={watch}
                journey={journey}
                blocks={blocks}
                block={block}
              />
              <ActionSelect
                clearErrors={clearErrors}
                errors={errors}
                control={control}
                index={index}
                setValue={setValue}
                journeyBlock={journeyBlock}
                registerName={registerName}
                register={register}
                watch={watch}
                journey={journey}
                blocks={blocks}
                block={block}
                stakeholderTags={stakeholderTags}
              />
            </CollapsibleBlock.Content>
          </CollapsibleBlock>
        </div>
      )}
    </Draggable>
  )
}
