import styles from './RoleUsersTab.module.scss';
import cn from 'classnames';
import { Button, Select } from 'components/FormComponents';
import { useWatch } from 'react-hook-form';
import FeatherIcon from 'feather-icons-react';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { useState, Fragment, useEffect } from 'react';
import { IconButton } from 'components/IconButton/IconButton';
import { useReactiveVar } from '@apollo/client';
import { stateUser } from 'graphql/state';
import { PermissionsForm } from './PermissionsForm/PermissionsForm';
import { loader } from "graphql.macro";
import { useQuery, useMutation } from '@apollo/client';
import { Modal } from 'components/Modal/Modal';
import { useAppContext } from 'templates/AppLayout/AppLayout';

const PERMISSIONS_QUERY           = loader('graphql/queries/permissions.graphql')
const UPDATE_PERMISSIONS_MUTATION = loader('graphql/mutations/update_users_role_permissions.graphql')


export const RoleUsersTab = ({
  users,
  role,
  control,
  onRoleAssign = () => {},
  onRoleUnassign = () => {},
  register = () => {},
  fetchUsers=() => {}
}) => {
  const { alert, triggerUnexpectedError, setLoading } = useAppContext();
  const currentUser = useReactiveVar(stateUser);
  const isSuperAdmin = currentUser?.roles?.some(role => role.name === 'super_admin') && role?.name === 'internal_stakeholder'

  const [openModal, setOpenModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false)

  const [selectedUserId, setSelectedUserId] = useState();
  const [permissions , setPermissions] = useState([])
  const userId = useWatch({ control, name: 'userId' })

  const user = selectedUserId ? users?.find((user)=>user?.id === selectedUserId) : ''

  const selectedIds = permissions?.filter?.((permission)=>user?.permissions?.includes(permission?.name))?.map((f)=>f?.id)

  const { loading: fetching } = useQuery(PERMISSIONS_QUERY, {
    onCompleted: data => {
      setPermissions(data?.permissions)
    }
  });

  const [updatePermissions, { loading: updating }] = useMutation(UPDATE_PERMISSIONS_MUTATION, {
    onCompleted: (data) => {
      if (data?.updateUsersRolePermissions) {
        fetchUsers()
        alert("Permissions updated successfully");
      } 
    },
    onError: (error) => {
      triggerUnexpectedError(error?.message)
    },
  });

  useEffect(()=>{
    setLoading(fetching || updating)
  },[fetching, updating])

  

  return (
    <>
    <Modal title={`${user?.name}'s Permissions`} visible={openSettingsModal} onClose={()=>setOpenSettingsModal(false)}>
      <PermissionsForm
       user={user}
       permissions={permissions}
       permissionIds={selectedIds}
       setModal={setOpenSettingsModal}
       updatePermissions={updatePermissions}
       roleId={role?.id}
       />
    </Modal>
    <div className={cn(styles.root)}>
      <div className={cn(styles.header)} >
        <div className={cn(styles.selectContainer, "d-flex align-items-center justify-content-between")}>
          <Select name='userId' register={register} placeholder="Select User..." className='me-5'>
            {users?.map(user => (
              <Select.Item key={`user-${user.id}`} description={user.email} value={user.id} active={userId === user.id}>{user.name}</Select.Item>
            ))}
          </Select>
          <Button placement={'right'} tip={"Assign Role"} onClick={() => { onRoleAssign({userId:userId, roleId: role?.id})}}><FeatherIcon icon='user-plus' size={15} className='me-2' />Add</Button>
          </div>
      </div>
      <div className={cn(styles.body)}>
        <div className={styles.tableHeaders}>
          <span
            className={cn(styles, styles.filter, "d-flex align-items-center")}
          >
            Name
          </span>
          <span
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Email
          </span>
        </div>
        <div className={cn(styles.tableRowsContainer)}>
          <ConfirmationModal
            visible={openModal}
            title='Unassign Role Confirmation'
            subtitle='This Role will be Unassigned from this user.'
            confirmationKey={'unassign role'}
            confirmationIcon='trash-2'
            onClose={() => { setOpenModal(false) }}
            onConfirmation={() => {
              onRoleUnassign({ userId: selectedUserId, roleId: role?.id })
              setOpenModal(false)
            }}
            buttonTitle='Unassign Role'
          />
          {users?.map((user) => {
            return (
              <Fragment key={`user-${user.id}`}>
                {user.roles.find(itm => itm.id === role.id) &&
                  <div className={cn(styles.tableRows)} key={`user-${user.id}`}>
                    <span className={cn(styles.name, 'title-6')}>{user?.name}</span>
                    <h1 className='title-6'>{user?.email}</h1>
                    <div className={cn(styles.actions, 'd-flex justify-content-around')}>
                      {!!isSuperAdmin &&
                        <IconButton
                        icon='edit-3'
                        tip='Edit Permissions'
                        onClick={() => {
                          setOpenSettingsModal(true)
                          setSelectedUserId(user.id)
                        }}
                      />
                      }
                      <IconButton
                        icon='trash-2'
                        tip='Unassign Role'
                        onClick={() => {
                          setOpenModal(true)
                          setSelectedUserId(user.id)
                        }}
                      />
                    </div>
                  </div>
                }
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
    </>
    )
}
