import React from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { loader } from "graphql.macro";
import { stateUser, stateCompany } from "graphql/state";
import { Button } from "components/FormComponents";
import { useAppContext } from "templates/AppLayout/AppLayout";
import Cookies from "js-cookie";
import { useReactiveVar } from "@apollo/client";
import jwt from "jwt-decode";
import styles from "./ImpersonationAlert.module.scss";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";

const REMOVE_IMPERSONATION_MUTATION = loader("./../../../../graphql/mutations/remove_impersonation.graphql");

export const ImpersonationAlert = () => {
  const client = useApolloClient();
  const { alert, triggerUnexpectedError, setLoading } = useAppContext();
  const impersonatorId = Cookies.get("token") ? jwt(Cookies.get("token"))?.impersonator_id : null;
  const currentUser = useReactiveVar(stateUser);
  const navigate = useNavigate();

  const [endImpersonation] = useMutation(REMOVE_IMPERSONATION_MUTATION, {
    onCompleted: async (data) => {
      const { token, user, company } = data.removeImpersonation;
      await client.clearStore();
      Cookies.set("token", token, { path: "" });
      setLoading(false);
      stateCompany(company);
      stateUser(user);
      alert("User impersonation ended");
      navigate('/')
    },
    onError: (error) => {
      triggerUnexpectedError(error.message);
      setLoading(false);
    },
  });

  const revertImpersonation = async () => {
    setLoading(true);
    await endImpersonation({ variables: { impersonater: `${impersonatorId}` } });
  };

  return (
    <>
      {impersonatorId && (
        <div className={styles.impersonation}>
          <FeatherIcon icon="info" className={styles.icon} />
          <div className={styles.text}>
            {`You are impersonating ${currentUser?.name}. `}
            <Button className={styles.button} type="link" onClick={revertImpersonation}>
              Stop Impersonation
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
