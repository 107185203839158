import { forwardRef } from 'react';
import styles from './SearchInput.module.scss';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';

export const SearchInput = forwardRef(({ 
  placeholder,
  className,
  onChange = () => {},
}, ref) => {

  return (  
    <div className={cn(styles.root, { [className]: className })}>
      <FeatherIcon className={styles.icon} icon='search' />

      <input
        ref={ref}
        type='text'
        onChange={e => onChange(e.target.value)}
        className={styles.input}
        placeholder={placeholder}
      />
    </div>
  )
})

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func
};
