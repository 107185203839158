import { useState, useEffect } from 'react';
import styles from './AddStakeholderToJourneyScreen.module.scss';
import { PublicJourneyLayout, usePublicJourneyLayoutContext } from "../../../templates/PublicJourneyLayout/PublicJourneyLayout";
import { useMutation} from "@apollo/client";
import { loader } from "graphql.macro";
import { useNavigate } from 'react-router-dom';
import { useForm} from "react-hook-form";
import cn from 'classnames';
import defaultLogo from '../../../assets/images/KM_logo.svg'
import { Input, InputGroup } from 'components/FormComponents';
import { CustomButton } from 'views/StakeholderJourneyScreens/components/CustomButton/CustomButton';
import moment from 'moment';

const CREATE_PUBLIC_STAKEHOLDER_JOURNEY_MUTATION = loader("graphql/mutations/create_public_stakeholder_journey.graphql");

export const AddStakeholderToJourneyScreen = () => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
        defaultValues: { externalStakeholderAttributes: { startDate: moment().format('YYYY-MM-DD') } }
    });

    const { publicJourney, setLoading, errorMessage, journeyId, triggerUnexpectedError } = usePublicJourneyLayoutContext();

    const [createPublicStakeholderJourney, { loading: creating }] = useMutation(CREATE_PUBLIC_STAKEHOLDER_JOURNEY_MUTATION, {
    onCompleted: async (data) => {
        const stakeholderJourney = data?.createPublicStakeholderJourney
        return navigate(`/journey_invitation/${journeyId}`,{ state: { stakeholderJourney: stakeholderJourney } });
    },
    onError: (error) => {
      triggerUnexpectedError(error?.message);
    },
    });

    useEffect(() => {
        setLoading(
        creating
        )
    }, [creating])

    const navigate = useNavigate();

    const onSubmit = (data) => {
        delete data?.formType

        const documentBlocks = publicJourney?.journeyBlocks?.filter((journeyBlock) => journeyBlock?.block?.typeOf === "document");

        data.internalStakeholdersAttributes = [];
        data.documentsCustomFields = [];
        data.journeyId = publicJourney?.id

        if (documentBlocks.length === 0) {
            try {
                createPublicStakeholderJourney({ variables: { stakeholderJourneyAttributes: data } })
            } catch (error) {
                triggerUnexpectedError(error?.message);
            }
        }
        else {
            triggerUnexpectedError("This Journey Contains a Document Block");
        }
    };


  const logo = publicJourney?.brand?.logo?.url || defaultLogo;

    return (
        <PublicJourneyLayout.Content className={styles.root}>
            <div className={cn(styles.root, "card", "card-with-border")}>
                <div className={cn("card_content", styles.content)}>
                    {errorMessage ?
                        (<div className={styles.header}>
                            <img
                                className={cn(styles.logo)}
                                src={logo}
                                alt='Brand logo'
                            />
                            <h1 className={styles.title}>{errorMessage}</h1>
                        </div>) :
                        (
                            <>
                                <div className={styles.header}>
                                    <img
                                        className={cn(styles.logo)}
                                        src={logo}
                                        alt='Brand logo'
                                    />
                                    <h1 className={styles.title}>Welcome to {publicJourney?.name}</h1>
                                    <p className={styles.subTitle}>Please add you information below to get started with you journey</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <InputGroup
                                        className={styles.input}
                                        titleStyles={styles.inputLabel}
                                        title='First Name'
                                    >
                                        <Input
                                            name='externalStakeholderAttributes.firstName'
                                            type='text'
                                            placeholder='Enter first name'
                                            validators={{ required: true }}
                                            register={register}
                                            error={errors?.externalStakeholderAttributes?.firstName?.type}
                                            inputProps={{ tabIndex: 1 }}
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        className={styles.input}
                                        titleStyles={styles.inputLabel}
                                        title='Last Name'
                                    >
                                        <Input
                                            name='externalStakeholderAttributes.lastName'
                                            type='text'
                                            placeholder='Enter last name'
                                            register={register}
                                            error={errors?.externalStakeholderAttributes?.lastName?.type}
                                            inputProps={{ tabIndex: 2 }}
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        className={styles.input}
                                        titleStyles={styles.inputLabel}
                                        title='Email'
                                    >
                                        <Input
                                            name='externalStakeholderAttributes.email'
                                            type='text'
                                            placeholder='Enter new email'
                                            register={register}
                                            validators={{ required: true }}
                                            error={errors?.externalStakeholderAttributes?.email?.type}
                                            inputProps={{ tabIndex: 3}}
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        className={styles.input}
                                        titleStyles={styles.inputLabel}
                                        title='Phone Number'
                                    >
                                        <Input
                                            name='externalStakeholderAttributes.phoneNumber'
                                            type='text'
                                            placeholder='Enter new phone number'
                                            register={register}
                                            validators={{ required: true }}
                                            error={errors?.externalStakeholderAttributes?.phoneNumber?.type}
                                            inputProps={{ tabIndex: 4 }}
                                        />
                                    </InputGroup>
                                    <div className={cn(styles.noBorder, 'd-flex', 'justify-content-center')}>
                                        <CustomButton submit className={cn(styles.submitBtn)} brand={publicJourney?.brand}>Get Started</CustomButton>
                                    </div>
                                </form>
                            </>
                        )}
                </div>
            </div>
        </PublicJourneyLayout.Content>
    )
}
