import { useRef, useState } from 'react';
import styles from './Input.module.scss';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { useCombinedRefs } from '../../../hooks/combined-refs';
import cn from 'classnames';
import { IconButton } from 'components/IconButton/IconButton';

export const Input = ({
  register,
  name,
  validators = {},
  placeholder,
  type='text',
  icon,
  required,
  error,
  noErrorMessage,
  className,
  inputProps,
  actionIcon,
  actionIconTip,
  onActionClick,
  value
}) => {

  // Be able to use ref with register
  const { ref, ...registerProps } = register(name, {
    ...validators,
    ...(required && {
      required: "Field is required"
    }),
    ...(type === 'email' && {
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Invalid email address'
      }
    }),
    ...(type === 'phone' && {
      pattern: {
        value: /^\+?[1-9]\d{1,14}$/,
        message: 'Invalid phone number'
      }
    })
  });
  const inputRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, inputRef);
  const { innerWidth: width } = window;

  // Reaveal password
  const [passwordRevealed, setPasswordRevealed] = useState(false)

  return (
    <div className={cn(styles.root, {[className]: className, [styles.rootWithError]: (error && !noErrorMessage) })}>
      {icon && <FeatherIcon className={styles.icon} icon={icon} /> }
      {inputProps?.disabled && <FeatherIcon className={styles.lock} icon='lock' />}
      <div className={cn(styles.inputBar)}>
        <input
          ref={combinedRef}
          type={passwordRevealed ? 'text' : type}
          name={name}
          value={value}
          className={cn(styles.input, inputProps?.disabled? styles.disabled : '', {
            [styles.invalid]: !!error,
            [styles.inputWithIcon]: !!icon,
          })}
          placeholder={placeholder}
          {...registerProps}
          {...inputProps}
        />

        {type === 'password' &&
          <span
            className={styles.showPassword}
            onClick={()=> width <= 768 && setPasswordRevealed(!passwordRevealed)}
            onMouseDown={()=> width > 768 && setPasswordRevealed(true)}
            onMouseUp={()=> width > 768 && setPasswordRevealed(false)}
            onMouseLeave={()=>width > 768 && setPasswordRevealed(false)}
          >
            Show
          </span>
        }

        {actionIcon &&
          <IconButton className={styles.actionButton} tip={actionIconTip} icon={actionIcon} onClick={onActionClick} />
        }
        </div>
      {(error && !noErrorMessage) && <span className={styles.error}>{error}</span> }
    </div>
  )
}

Input.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'phone', 'password', 'number', 'date']),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  validators: PropTypes.object,
  className: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  inputProps: PropTypes.object,
  actionIcon: PropTypes.string,
  onActionClick: PropTypes.func
};

Input.defaultProps = {
  register: (...args) => ({ ref: () => {} }),
  type: 'text',
  required: false,
  error: '',
  icon: '',
  validators: {},
  inputProps: {},
  onActionClick: () => {}
};
