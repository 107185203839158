import { StakeholderJourneyLayout, useStakeholderJourneyLayoutContext } from "templates/StakeholderJourneyLayout/StakeholderJourneyLayout";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import styles from "./StakeholderJourneyOptionalPdfScreen.module.scss";
import cn from "classnames";
import { MobilePdfViewer } from "../StakeholderJourneyBlockScreen/QuestionnaireForm/MobilePdfViewer/MobilePdfViewer";
import { InputGroup } from "components/FormComponents";
import { Signature } from "components/Signature/Signature";
import { CustomButton } from "../components/CustomButton/CustomButton";

const UPDATE_OPTIONAL_PDF_RESULT_MUTATION = loader("graphql/mutations/update_optional_pdf_result.graphql");

export const StakeholderJourneyOptionalPdfScreen = () => {

  const {
    reset,
    control,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const signatureUploadId = watch("signatureUploadId")
  const navigate = useNavigate();
  const { stakeholderJourney, token, setLoading, fetch } = useStakeholderJourneyLayoutContext();

  const [order, setOrder] = useState(1);
  const [pdfBlock, setPdfBlock] = useState(null)
  const completed = stakeholderJourney?.optionalPdfs?.filter(obj => obj.status === 'completed');
  const handleBadge = () => {
    return completed?.length === stakeholderJourney?.optionalPdfs?.length ? styles.active : styles.inactive;
  };
  const activeBadge = handleBadge();
  const { innerWidth: width } = window;
  const [updateOptionalPdfResultMutation] = useMutation(UPDATE_OPTIONAL_PDF_RESULT_MUTATION);

  useEffect(() => {
    if (!stakeholderJourney) return;
    setPdfBlock(stakeholderJourney?.optionalPdfs?.find( pdfBlock => pdfBlock?.order === 1 ))
  }, [stakeholderJourney]);

  useEffect(() => {
    if (pdfBlock?.status === "pending") {
      updateOptionalPdfResultMutation({
        variables: {
          id: pdfBlock?.id,
          status: "in_progress",
          token: token,
        },
      });
    }
    if (pdfBlock?.signature) {
      setValue('signatureUploadId', pdfBlock?.signature?.id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfBlock]);


  const nextPdfBlock = () => {
    const nextBlock = stakeholderJourney?.optionalPdfs?.find(pdfBlock => pdfBlock.order === order + 1)
    if (nextBlock) {
      reset();
      setPdfBlock(nextBlock)
      return setOrder(order + 1)
    } else {
      fetch()
      return navigate(`/journey/${token}/get_started`,{ state: { navigated:true } });
    }
  }
  const isNext = stakeholderJourney?.optionalPdfs?.find(pdfBlock => pdfBlock.order === order + 1)

  const updateResultsAndClose = () => {
    if (pdfBlock?.status !== "completed") {
      setLoading(true);

      const optionalPdfResultAttributes = { id: pdfBlock?.id, token: token }
      optionalPdfResultAttributes.signatureUploadId = signatureUploadId || null
      if ((pdfBlock?.useSignature && optionalPdfResultAttributes.signatureUploadId) ||
        (!pdfBlock?.useSignature)) {
        optionalPdfResultAttributes.status = 'completed'
      }

      updateOptionalPdfResultMutation({ variables: optionalPdfResultAttributes });
      setLoading(false);
    }
    reset();
    fetch()
    return navigate(`/journey/${token}/get_started`, { state: { navigated:false } });
  };

  const updateResultsAndNext = (data) => {
    if (pdfBlock?.status !== "completed") {
      setLoading(true);

      const optionalPdfResultAttributes = { id: pdfBlock?.id, token: token }
      optionalPdfResultAttributes.signatureUploadId = data?.signatureUploadId || null
      if ((pdfBlock?.useSignature && optionalPdfResultAttributes.signatureUploadId) ||
        (!pdfBlock?.useSignature)) {
        optionalPdfResultAttributes.status = 'completed'
      }

      updateOptionalPdfResultMutation({ variables: optionalPdfResultAttributes });
      setLoading(false);
    }
    reset();
    nextPdfBlock()

  };


  // Render
  return (
    <StakeholderJourneyLayout.Content
      withCtaButton={true}
      withHeader
      withFooter
      onNavbarCtaClick={() => updateResultsAndClose()}
      className={styles.root}
    >
      <div className={cn(styles.block, "flex")}>
        <div className={cn(styles.pdfFormContainer, "card", "card-with-border")}>
          <div className={cn("card_content", styles.content)}>
            <Badge className={cn("margin-left--small badge--default", styles.badge)}>
              {"Nice to do"}
            </Badge>
            <h1 className={cn(styles.title, "title-2")}>Supporting Documentation</h1>
            <div className={cn(styles.blockItem, styles.blockBadge, activeBadge)}>
              {`${completed?.length}/${stakeholderJourney?.optionalPdfs?.length}`}
            </div>
            <form className={cn(styles.form, "margin-top--large margin-bottom-0")}>
              <InputGroup
                title={`1. ${pdfBlock?.documentHeading} *`}
                strong
                className={cn(styles.input, "margin-bottom--large")}
              >
                  {width > 1080 ? (
                      <object className={styles.embed} data={pdfBlock?.document?.url} aria-label="document"></object>
                    ) : (
                        <MobilePdfViewer url={pdfBlock?.document?.ur}/>
                  )}
              </InputGroup>

              {pdfBlock?.useSignature && <InputGroup
                title={`2. ${pdfBlock?.signatureHeading} *`}
                strong
                className={cn(styles.input, "margin-bottom--large")}
              >
                <Controller
                  control={control}
                  name={`signatureUploadId`}
                  render={({ field: { onChange, value } }) => (
                    <Signature
                      disabled={pdfBlock?.status === "completed"}
                      className={styles.signature}
                      signatureId={value}
                      onChange={onChange}
                      setError={setError}
                      clearErrors={clearErrors}
                      name={`signatureUploadId`}
                      stakeholderJourneyToken={token}
                    />
                  )}
                />
              </InputGroup>}
              <CustomButton
                disabled={errors?.answersAttributes?.length}
                className={styles.next}
                onClick={handleSubmit(updateResultsAndNext)}
              >
                {isNext ? 'Next' : 'Complete'}
              </CustomButton>
            </form>
          </div>
        </div>
      </div>
    </StakeholderJourneyLayout.Content>
  );
};
