import axios from "axios";
import Cookies from "js-cookie";

export const GetAccessRefreshToken = async (updateRefreshToken) => {
    const oAuthToken = Cookies.get('checkmate_OAuth') 

    const data = {
        grant_type: 'authorization_code',
        client_id:  process.env.REACT_APP_KNOWMY_CLIENT_ID,
        client_secret:  process.env.REACT_APP_KNOWMY_CLIENT_SECRET,
        redirect_uri:   process.env.REACT_APP_KNOWMY_REDIRECT_URI,
        code: oAuthToken
    }

    const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    const formData = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    try{
        const response = await axios.post(`${process.env.REACT_APP_CHECKMATE_API_URL}/oauth/token`,formData.toString(), config)
        if (response){
            Cookies.set('checkmateAccessToken',response.data?.access_token)
            Cookies.set('checkmateRefreshToken', response.data?.refresh_token)
            return response?.data?.refresh_token
        }    
    }catch(e){
        console.log("Error",e)
    }
  
}