import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import cn from "classnames";
import styles from "./MobilePdfViewer.module.scss";
import { Document, Page, pdfjs } from "react-pdf";

export const MobilePdfViewer = ({ url }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  return (
    <>
      <div className={cn(styles.root)}>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess} className={styles.canvas} renderTextLayer={false}>
          <Page scale={1.5} pageNumber={pageNumber} />
        </Document>
        <div className={styles.pageBar}>
          <div>
            <FeatherIcon
              icon="arrow-left-circle"
              onClick={() => (pageNumber > 1 ? setPageNumber(pageNumber - 1) : pageNumber)}
            />
          </div>
          <div>
            {pageNumber}/{numPages}
          </div>
          <div>
            <FeatherIcon
              icon="arrow-right-circle"
              onClick={() => (pageNumber < numPages ? setPageNumber(pageNumber + 1) : pageNumber)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
