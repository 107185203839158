import { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom'
import { loader } from "graphql.macro";
import { useMutation, useApolloClient } from "@apollo/client";
import { useEffect } from "react";
import { Loading } from './../../components/Loading/Loading';
import { stateUser, stateCompany } from './../../graphql/state';
import Cookies from 'js-cookie';
import styles from './EmailVerifiedScreen.module.scss';

const CONFIRM_EMAIL_MUTATION = loader('./../../graphql/mutations/confirm_email.graphql');

export const EmailVerifiedScreen = () => {

  const client = useApolloClient();

  const [success, setSuccess] = useState(false);

  let { signedId } = useParams();

  const [ confirmEmail, { loading: confirmingEmail } ] = useMutation(CONFIRM_EMAIL_MUTATION, {
    onCompleted: async(data) => {
      if (data.confirmEmail) {
        const { token, user, company } = data.confirmEmail;
        await client.clearStore();
        Cookies.set('token', token, { path: '' });
        stateCompany(company);
        stateUser(user);
        setSuccess(true);
      }
    },
    onError: error => {
      console.error(error);
    }
  });

  useEffect(() => {
    confirmEmail({
      variables: {
        signedId: signedId
      }
    })
  }, [])


  return (
    <div className={styles.root}>
      <Loading visible={confirmingEmail} />
      { success && <Navigate to="/sign_in" /> }
      <img src='/assets/images/knowmy/KM_logo_4.svg' alt='KnowMy Logo' />
      <p className='title-1 margin-top'>Your Email Has been verified! Please wait to be redirected to your dashboard!</p>
    </div>
  )
}
