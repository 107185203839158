import styles from './SecondFactorTab.module.scss';
import { ToggleSwitch } from 'components/FormComponents';
import cn from 'classnames';
import { loader } from "graphql.macro";
import { useEffect, useState } from 'react';
import { useMutation, useLazyQuery} from "@apollo/client";
import { QRCodeSVG } from 'qrcode.react';
import { InputGroup, Input } from 'components/FormComponents';

const QR_QUERY = loader("graphql/queries/second_factor_qr.graphql");
const ENABLE_2FA_MUTATION = loader("graphql/mutations/enable_user_two_factor.graphql")
const DISABLE_2FA_MUTATION = loader("graphql/mutations/disable_user_two_factor.graphql")

export const SecondFactorTab = ({alert, triggerUnexpectedError, setLoading}) =>{

  const [secondFactor, setSecondFactor] = useState(null)

  const [fetchQR, { loading: fetchingQR }] = useLazyQuery(QR_QUERY, {
    onCompleted: data => setSecondFactor(data.secondFactorQr)
  });

  const [enable2Fa, { loading: enabling } ] = useMutation(ENABLE_2FA_MUTATION, {
    onCompleted: async (data) => {
      const twoFactor = data.enableUserTwoFactor
      if (!!twoFactor){
        alert('Two Factor Authentication successfully enabled.')
        setSecondFactor(twoFactor)
      }
    },
    onError: (error) => {
      triggerUnexpectedError(error.message);
      setLoading(false);
    },
  });

  const [disable2Fa, { loading: disabling }] = useMutation(DISABLE_2FA_MUTATION, {
    onCompleted: async (data) => {
      const twoFactor = data.disableUserTwoFactor
      if (twoFactor){
        alert('Two Factor Authentication successfully disabled.')
        setSecondFactor(null)
      }
    },
    onError: (error) => {
      triggerUnexpectedError(error.message);
      setLoading(false);
    },
  });

  const onChange = () =>{
    if (secondFactor){
      disable2Fa({variables:{}})
    }else{
      enable2Fa({variables:{}})
    }
  }

  useEffect(()=>{
    setLoading(disabling || enabling || fetchingQR)
  },[disabling,enabling, fetchingQR])

  useEffect(()=>{
    fetchQR()
  },[])
    return (
        <div className={cn("card card-with-border", styles.root)}>
        <div className="d-flex align-items-start justify-content-start card_content">
          <header className={styles.header}>
            <h1 className="title-2">Two Factor Authentication</h1>
            <ToggleSwitch className={styles.switch} inputProps={{ checked: !!secondFactor, onChange }}/>
          </header>
          <div className={styles.subHeading}>
            Two factor authentication is a good way of increasing account security, by adding
            a next step to the normal KnowMy login flow.
             <span className={styles.info}> {!secondFactor ? "Enable Two Factor Authentication above." : "Two Factor Authentication is Enabled."}</span>
          </div>

          <div className={styles.line}>
            <div className={styles.circle}>
              <span>1</span>
            </div>
            <div className={styles.text}> Download and Install Google Authenticator or any authenticator app.</div>
          </div>

          <div className={styles.line}>
            <div className={styles.circle}>
              <span>2</span>
            </div>
            <div className={styles.text}> Scan the QR code or copy the key {!secondFactor ? "(After enabling)." : "."}</div>
          </div>

          {!!secondFactor &&
           <>
              <QRCodeSVG 
                className={styles.svg}
                id="qr-code"
                value={secondFactor?.qrCode}
                size={300}
                level={"M"}
                includeMargin={true}
              />

              <InputGroup title='Secret Key'>
              <Input
                className={styles.urlInput}
                name='twoFaKey'
                value={secondFactor?.otpSecret}
                actionIcon='copy'
                actionIconTip='Copy Key'
                inputProps={{ readOnly: true }}
                onActionClick={() => {
                  navigator.clipboard.writeText(secondFactor?.otpSecret);
                }}
              />
              </InputGroup>
            </>
          }
        </div>
      </div>
    )
}