import styles from './IntegrationsTab.module.scss';
import { VerticalTabs, Tab } from '../../../../components/VerticalTabs/VerticalTabs';
import { AccountAPIKey } from './components/AccountAPIKey/AccountAPIKey';
import { Checkmate } from './components/Checkmate/Checkmate';
import { Link } from 'react-router-dom';


export const IntegrationsTab = ({ alert, error }) => {

  return (
    <div>
      <div className={styles.header}>
        <h1 className='title-3'>Integrations</h1>
        <p className={styles.description}>View and manage your account integrations below.
         Access our API documentation <Link to={'/api_docs'}>here</Link>.
        </p>
      </div>
      <VerticalTabs defaultActiveKey={'account_api'}>
        <Tab eventKey="account_api" title="Account API">
          <AccountAPIKey />
        </Tab>
        <Tab eventKey="checkmate" title="Checkmate">
          <Checkmate />
        </Tab>
      </VerticalTabs>

    </div>
  )
}
