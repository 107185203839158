import { useStakeholderJourneyLayoutContext } from "templates/StakeholderJourneyLayout/StakeholderJourneyLayout";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { saveAs } from "file-saver";
import download from "downloadjs";
import { consolidatedCsvs } from "utils/helpers";
import { Loading } from "components/Loading/Loading";
import { useState } from "react";

const BLOCK_RESULT_QUERY = loader("graphql/queries/multiple_block_results.graphql");

export const StakeholderJourneyBlockResultDownload = () =>{
    const { stakeholderJourney, token } = useStakeholderJourneyLayoutContext();
    const [loading , setLoading] = useState(false)
    // Query params
    const { block_result_id } = useParams();


    const { loading: fetching } = useQuery(BLOCK_RESULT_QUERY, {
        variables: { token: token, blockResultIds: [block_result_id] },
        onCompleted: (data) => {
          const blockResult = data.multipleBlockResults[0]
          const {blockName ,blockType, result} = blockResult
          if (blockType === 'questionnaire'){
            const blob = consolidatedCsvs([result])
            saveAs(blob,`${`${blockName}`.replace(/ /g, "_")}.csv`)
          }else{
            setLoading(true)
            download(result, `${blockName}`, "application/pdf")
            setLoading(false)
          }
        }
      });

      return(
        <>
          <Loading visible={loading || fetching} />
        </>      )

}