import { Textarea } from "components/FormComponents";

export const ParagraphSettings = ({ register, name, errors }) => (
  <Textarea
    register={register}
    name={`${name}.params.text`}
    inputProps={{ rows: 8 }}
    className="margin-top--large"
    placeholder="Enter Paragraph..."
    validators={{ required: true }}
    error={errors}
  />
);
