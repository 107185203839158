import { useEffect, useState } from 'react';
import HelloSign from 'hellosign-embedded';

export const useHelloSignEmbedded = ({
  onConfigured = () => {},
  onSign       = () => {},
  onCancel     = () => {}
}) => {
  const [helloSignClient, setHelloSignClient] = useState();

  useEffect(() => {
    const client = new HelloSign({ clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID });
    setHelloSignClient(client);
  }, [])

  const openEmbedded = (url) => {
    helloSignClient.open(url, {
      testMode: process.env.REACT_APP_ENV === 'production' ? false : true
    });

    helloSignClient.on(HelloSign.events.CREATE_TEMPLATE, onConfigured);
    helloSignClient.on(HelloSign.events.SIGN,            onSign);
    helloSignClient.on(HelloSign.events.CANCEL,          onCancel)
  }

  return {
    loaded: !!helloSignClient,
    openEmbedded: openEmbedded
  }
}