import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryParams } from 'hooks/query-params';
import { Loading } from "components/Loading/Loading";
import { GetAccessRefreshToken } from "./apis/GetAccessRefreshToken";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";

const UPDATE_REFRESH_TOKEN = loader('graphql/mutations/update_company_checkmate_refresh_token.graphql')
const REGISTER_CANDIDATE_WEBHOOK = loader('graphql/mutations/register_checkmate_candidate_webhook.graphql')

export const OAuthCallbacks = () => {

  const [updateRefreshToken] = useMutation(UPDATE_REFRESH_TOKEN);
  const [registerWebhook] = useMutation(REGISTER_CANDIDATE_WEBHOOK)

  const { callback_name } = useParams();
  const { code } = useQueryParams();
  const [loading, setLoading] = useState(true);
  const fetchTokens = async () =>{
    const response = await GetAccessRefreshToken(updateRefreshToken)
    if(response){
      await updateRefreshToken({variables:{refreshToken: response}})
    }
}

  useEffect(() => {
    const fetchData = async () => {
      if (code) {
        Cookies.set(callback_name, code);
        registerWebhook({variables:{}})
        await fetchTokens();
        setLoading(false);
        window.close()
      }
    };
  
    fetchData()
  }, [code])

  return (
    <>
      <Loading visible={loading} />
    </>
  )
}
