import { Tabs, Tab } from "../../components/Tabs/Tabs";
import { AppLayout, useAppContext } from "./../../templates/AppLayout/AppLayout";
import { DetailsTab } from "./tabs/DetailsTab/DetailsTab";
import { PasswordTab } from "./tabs/PasswordTab/PasswordTab";
import { SecondFactorTab } from "./tabs/SecondFactorTab/SecondFactorTab";

export const AccountScreen = () => {
  const { alert, triggerUnexpectedError, setLoading } = useAppContext();

  return (
    <>
      <AppLayout.Header>
        <AppLayout.Title>Account</AppLayout.Title>
        <AppLayout.Subtitle>Below you can view all your account settings, as well as manage your brands and much more.</AppLayout.Subtitle>
      </AppLayout.Header>
      <AppLayout.Body>
        <Tabs defaultActiveKey="details">
          <Tab eventKey="details" title="Details">
            <DetailsTab alert={alert} error={triggerUnexpectedError} />
          </Tab>
          <Tab eventKey="password" title="Password">
            <PasswordTab alert={alert} error={triggerUnexpectedError} />
          </Tab>
          <Tab eventKey="2fa" title="Two Factor Authentication">
            <SecondFactorTab alert={alert} error={triggerUnexpectedError} setLoading={setLoading} />
          </Tab> 
        </Tabs>
      </AppLayout.Body>
    </>
  );
};
