import { EditableSettings } from '../../../../../../../../../../../components/EditableSettings/EditableSettings';
import { Fragment, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { ToggleSwitch, Input } from '../../../../../../../../../../../components/FormComponents';
import { Button } from '../../../../../../../../../../../components/FormComponents';
import { useFieldArray } from 'react-hook-form';
import { Collapse } from 'react-bootstrap';
import styles from './MultipleChoiceSettings.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';
import { Checkbox } from 'components/FormComponents/Checkbox/Checkbox';
import { useWatch } from 'react-hook-form';

export const MultipleChoiceSettings = ({setValue, register, control, name, field,errors, clearErrors }) => {

  const multipleSelection = useWatch({control, name: `${name}.multipleSelection`})
  const options = useWatch({control, name: `${name}.options`})

  useEffect(()=>{
    const isMultiple = options?.filter(o=>o.isAnswer)
    if(isMultiple.length > 1){
      setValue(`${name}.multipleSelection`, true)
    }else{
      setValue(`${name}.multipleSelection`, false)
    }
  },[options])

  return (
    <Fragment>
      <EditableSettings title='Settings' className='margin-top--large' startOpen={true}>
        <ToggleSwitch register={register} name={`${name}.required`} label='Required' />
        <hr />
        <ToggleSwitch register={register} name={`${name}.multipleSelection`} label='Multiple selection' />
        <hr />
        <Fragment>
          <ToggleSwitch register={register} name={`${name}.useDescription`} label='Add description' />
          <Collapse in={field.options?.useDescription}>
            <div><Input register={register} name={`${name}.description`} className='margin-top--small' placeholder='Enter description...'/></div>
          </Collapse>
        </Fragment>
        <hr/>
        <Fragment>
          <ToggleSwitch register={register} name={`${name}.useCustomMarks`} label='Add custom marks for this question (Default is 1)' />
          <Collapse in={field.options?.useCustomMarks}>
            <div><Input type='number' register={register} name={`${name}.marks`} className='margin-top--small' placeholder='Enter Marks'/></div>
          </Collapse>
        </Fragment>
      </EditableSettings>
      <hr className='my-5' />
      <OptionsConfig multipleSelection={multipleSelection} register={register} name={name} control={control} errors={errors} clearErrors={clearErrors}/>
    </Fragment>
  )
}

const OptionsConfig = ({ register, name, control, errors, clearErrors, multipleSelection}) => {

  const { fields, append, remove, swap } = useFieldArray({ control, name: `${name}.options` });
  var errorCount = 0
  if (fields?.length && errors?.options?.options?.length){
    errors?.options?.options?.forEach((error)=>{
      if(error){
        errorCount = errorCount +1
      }
    })
  }
  if (errors && errorCount === 0){
    clearErrors()
  }
  

  const addOption = () => {
    append({ value: ''})
  }

  const onDragEnd = ({ source, destination }) => {
    if(!source || !destination) return;
    swap(source.index, destination.index);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='options'>
        {({ innerRef, droppableProps, placeholder }) => (
          <div className='card card-with-border background-secondary px-4' {...droppableProps} ref={innerRef}>
            {fields.map((option, index) => (
              <Option
                key={option.id}
                index={index}
                name={`${name}.options.${index}`}
                register={register}
                option={option}
                onRemove={() => remove(index)}
                onEnter={addOption}
                errors={errors}
                control={control}
                multipleSelection={multipleSelection}
              />
            ))}
            {placeholder}
            <Button type='link' onClick={addOption}>+ Add another option</Button>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}


const Option = ({ control, name, register, onRemove, option, index, onEnter, errors}) => {

  const watchCheckBox = useWatch({ control, name: `${name}` });

  const handleKeyDown = (e) => {
    switch(e.code) {
      case 'Enter':
        e.preventDefault();
        onEnter();
        break;
      case 'Backspace':
        if(e.target.value === '') {
          e.preventDefault();
          onRemove();
        }
        break;
    }
  }

  return (
    <Draggable draggableId={option.id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div className={cn(styles.option, 'd-flex', 'align-items-center', 'margin-top')} {...draggableProps} {...dragHandleProps} ref={innerRef}>
          <FeatherIcon icon='move' className={cn('margin-right--small', styles.icon)} />
          <Input
            register={register}
            name={`${name}.value`}
            className={styles.input}
            validators={{ required: true}}
            error={errors?.options?.options?.[index]?.value?.type}
            placeholder='Enter option...'
            actionIcon='trash-2'
            onActionClick={onRemove}
            inputProps={{
              onKeyDown: handleKeyDown
            }}
          />
          <div className={styles.answer}>
            <div>Correct Answer:</div>
            <Checkbox
              checked={watchCheckBox?.isAnswer}
              className={styles.checkBox}
              register={register}
              name={`${name}.isAnswer`}
            />
          </div>
        </div>
      )}
    </Draggable>
  )
}