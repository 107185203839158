import { useForm } from 'react-hook-form';
import styles from './TeamForm.module.scss';
import { EditableTitle, Button } from 'components/FormComponents';
import { TeamMembersTab } from '../TeamMembersTab/TeamMembersTab';
import { useEffect } from 'react';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { useState } from 'react';
import cn from 'classnames';
import { IconButton } from 'components/IconButton/IconButton';

export const TeamForm = ({
  team,
  users,
  onAddTeamMembers = () => {},
  onRemoveTeamMembers = () => {},
  onClose = () => {},
  onSave = () => {},
  onDelete = () => {}
}) => {

  const { register, reset, control, watch, handleSubmit } = useForm();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => reset(team), [team])

  return (
    <>
      <ConfirmationModal
        visible={openModal}
        title='Delete Team Confirmation'
        subtitle='This Team will be permanently deleted. This data cannot be recovered'
        confirmationKey={watch('name')}
        confirmationIcon='trash-2'
        onClose={() => { setOpenModal(false) }}
        onConfirmation={onDelete}
        buttonTitle='Delete Team'
      />
      <div className={cn(styles.root, 'card card-with-border')}>
        <form id='team-update-form' onSubmit={handleSubmit(onSave)}>
          <div className={cn(styles.cardContent,'card_content')}>
            <header className={styles.header}>
              <EditableTitle register={register} name='name' value={watch('name')} />
              <div>
              <IconButton tip="Delete Block" icon="trash-2" onClick={onDelete} />
            </div>
            </header>
          </div>
        </form>
          <TeamMembersTab
            register={register}
            control={control}
            users={users}
            team={team}
            reset={reset}
            onAddTeamMembers={onAddTeamMembers}
            onRemoveTeamMembers={onRemoveTeamMembers}
          />
        <div className='card_footer d-flex justify-content-between'>
            <Button type='secondary' onClick={onClose}>Cancel</Button>
            <Button buttonProps={{ form: 'team-update-form' }} submit>Save</Button>
        </div>

        </div>
    </>
  )
}
