import { useState, useCallback } from 'react';
import styles from './FilePicker.module.scss';
import PropTypes from 'prop-types';
import { loader } from "graphql.macro";
import FeatherIcon from 'feather-icons-react';
import { useDropzone } from 'react-dropzone';
import { useMutation, useLazyQuery } from "@apollo/client";
import { Loader } from './../../Loader/Loader';
import { getFileMetadata } from '../../../utils/active-storage-utils';
import { directUpload } from '../../../utils/direct-upload';
import { useEffect } from 'react';
import { PDFDocument } from 'pdf-lib';
import cn from 'classnames';

const SIGN_UPLOAD_MUTATION =   loader("./../../../graphql/mutations/sign_upload.graphql");
const CREATE_UPLOAD_MUTATION = loader("./../../../graphql/mutations/create_upload.graphql");
const DELETE_UPLOAD_MUTATION = loader("./../../../graphql/mutations/delete_upload.graphql");
const UPLOAD_QUERY =           loader("./../../../graphql/queries/upload.graphql");

export const FilePicker = ({
  value,
  options,
  className,
  error,
  onChange,
  handleCustomChange,
  disabled,
  name,
  setError,
  clearErrors,
  acceptedFileExtension,
  stakeholderJourneyToken,
  onCustomRemove = () =>{},
  setPages,
  checkPages
}) => {
  const [fileName, setFileName] = useState();

  const [uploading, setUploading] = useState(false);

  const [fetchUpload, { loading: fetching }] = useLazyQuery(UPLOAD_QUERY, {
    onCompleted: data => setFileName(data?.upload?.filename)
  });

  const [signUpload] = useMutation(SIGN_UPLOAD_MUTATION);
  const [createUpload] = useMutation(CREATE_UPLOAD_MUTATION);
  const [ deleteUpload, { loading: deletingUpload } ] = useMutation(DELETE_UPLOAD_MUTATION, {
    onCompleted: async(data) => {
      setFileName(null)
      onChange(null)
    },
  });


  useEffect(() => {
    if(value) fetchUpload({ variables: { id: value, token: stakeholderJourneyToken }})
    else setFileName(null)
  }, [value])

  /**
   * --- On upload ---
   * 1) Get file meta
   * 2) Sign upload
   * 3) Upload to s3
   * 4) Create upload
   * 5) Set value
   */
   const onDrop = useCallback(async (acceptedFiles) => {
    setUploading(true);
    const file = acceptedFiles[0];
    if(!file) return;
    if (acceptedFileExtension && !file?.type.includes(`${acceptedFileExtension}`)){
      setError(`${name}`, { type: `Only ${acceptedFileExtension.toUpperCase()} Files Allowed`})
      setUploading(false)
      return
    }
    else if (acceptedFileExtension && file?.type.includes(`${acceptedFileExtension}`)) {
      clearErrors(`${name}`)
    }

    try {
      if (acceptedFileExtension === 'pdf' &&  checkPages)
      {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const pages = pdfDoc.getPageCount();
        setPages(pages)  
      }
    } catch (error) {
      console.error("Error processing PDF:", error);
    }

    const fileMetadata = await getFileMetadata(file);
    const signedResponse = await signUpload({ variables: { signUploadAttributes: fileMetadata, token: stakeholderJourneyToken }});
    const { url, headers, signedBlobId } = signedResponse.data.signUpload;
    await directUpload(url, JSON.parse(headers), file);
    const uploadResponse = await createUpload({ variables: { blobId: signedBlobId, token: stakeholderJourneyToken }});
    const { id, filename } = uploadResponse.data.createUpload;
    setFileName(filename);
    onChange(id)
    if (handleCustomChange) {
      handleCustomChange(id, filename)
    }
    setUploading(false);
  }, []);

  const onRemove = () => {
    if (disabled) return
    deleteUpload({ variables: { id: value, token: stakeholderJourneyToken } })
    onCustomRemove(true)
    setFileName(null);
    onChange(null);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div className={cn(styles.root, { [className]: className, [styles.invalid]: error, [styles.disabled]: disabled })}>
        {(uploading || fetching) && <Loader /> }

        {(fileName && !uploading && !fetching) &&
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <FeatherIcon icon="file" size={40} />
            <p>{fileName}</p>
            <div className={styles.removeFileIconWrapper} onClick={onRemove}>
              <FeatherIcon className={styles.removeFileIcon} icon="trash-2" size={25} />
            </div>
          </div>
        }

        {(!fileName && !uploading && !fetching && !disabled) &&
          <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            <FeatherIcon icon='upload' size={30} className={styles.icon} />
            <p className={styles.placeholder}>Drop your documents here, or <span className='link'>browse</span></p>
          </div>
        }
      </div>
      {(error ) && <span className={styles.error}>{error}</span> }
    </>
  )
}

FilePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
};

FilePicker.defaultProps = {
  onChange: () => {}
};
