import cn from 'classnames';
import styles from './Placeholder.module.scss';

export const Placeholder = () => {
  return (
    <div className={cn(styles.root, 'card card-with-border')}>
      <div className={cn(styles.firstDiv)}>
        <div className={cn(styles.one)} />
        <div className='d-flex'>
          <div className={cn(styles.two)} />
          <div className={cn(styles.two)} />
          <div className={cn(styles.three)} />
        </div>
      </div>
      <div className={cn(styles.secondDiv)}>
        <div className={cn(styles.one)} />
      </div>
      <div className={cn(styles.fifthDiv)}>
        <div className={cn(styles.one)}>
          <div className={cn(styles.one)} />
          <div className={cn(styles.ball)} />
        </div>
        <div className={cn(styles.two)} />
      </div>
      <div className={cn(styles.sixthDiv)}>
        <div className={cn(styles.one)} />
        <div className='d-flex'>
          <div className={cn(styles.two)} />
          <div className={cn(styles.three)} />
        </div>
      </div>
    </div>
  )
}
