import { Input } from "./../Input/Input";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./TangoLinkInput.module.scss";

export const TangoLinkInput = ({ className, register, name, value, errors }) => {
  return (
    <div className={cn(styles.root, className)}>
      <Input
        register={register}
        name={name}
        placeholder="https://www.youtube.com/embed/dskhie93hjsk"
        validators={{ required: true }}
        error={errors}
      />
      <p className="t-color-secondary t-tiny">
        To learn what Tango is and or how to use it follow this
        <a
          className={cn(styles.link)}
          target="_blank"
          href="https://app.tango.us/app/workflow/Embedding-a-Workflow-in-Notion-722834e242504d02a8e53d410a6cca72"
        >
          link
        </a>
      </p>
      <div className={cn(styles.embed, "card-with-border card")}>
        <embed type="video/webm" src={value} />
      </div>
    </div>
  );
};

TangoLinkInput.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
};

TangoLinkInput.defaultProps = {
  register: (...args) => ({ ref: () => {} }),
};
