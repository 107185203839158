import { AppLayout, useAppContext } from "templates/AppLayout/AppLayout";
import { Tab, Tabs } from "components/Tabs/Tabs";
import { Users } from "./tabs/Users/Users";
import { Companies } from "./tabs/Companies/Companies";

export const AdminScreen = () => {
  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  return (
    <>
      <AppLayout.Header>
        <AppLayout.Title>Admin Screen</AppLayout.Title>
        <AppLayout.Subtitle>Below you can monitor companies and users.</AppLayout.Subtitle>
      </AppLayout.Header>
      <AppLayout.Body>
        <Tabs defaultActiveKey="users">
          <Tab eventKey="users" title="Users">
            <Users />
          </Tab>
          <Tab eventKey="companies" title="Companies">
            <Companies />
          </Tab>
        </Tabs>
      </AppLayout.Body>
    </>
  );
};
