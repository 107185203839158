import styles from './EditableTitle.module.scss';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useCombinedRefs } from '../../../hooks/combined-refs';
import cn from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const EditableTitle = ({
  register,
  name,
  value,
  disabled,
}) => {

  const { ref, ...registerProps } = register(name);
  const inputRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, inputRef);

  const [editing, setEditing] = useState(false);

  useEffect(() => { if(editing) inputRef.current.focus() }, [editing])

  const cancel = () => setEditing(false);

  const handleKeyPressed = (e) => {
    if(e.key === 'Enter') {
      setEditing(false);
    }
  }

  if(editing) {
    return (
      <input
        ref={combinedRef}
        className={styles.input}
        {...registerProps}
        onBlur={cancel}
        onKeyPress={handleKeyPressed}
      />
    )
  } else {
    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='top'
        overlay={!disabled ? <Tooltip>Edit</Tooltip> : <></>}
      >
        <div
          className={cn('d-flex', 'align-items-center', styles.root)}
          onClick={() => { !disabled && setEditing(true) }}
        >
          <h1 className='title-2'>{value}
          {!disabled && <FeatherIcon icon='edit-2' className={styles.editIcon} />}
          </h1>
        </div>
      </OverlayTrigger>
    )
  }
}


EditableTitle.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

EditableTitle.defaultProps = {
  register: (...args) => ({ ref: () => {} }),
}
