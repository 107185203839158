import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm, useFieldArray, useWatch, Controller } from "react-hook-form";
import { CollapsibleBlock } from "components/CollapsibleBlock/CollapsibleBlock";
import styles from "./StakeholderJourneyForm.module.scss";
import { InlineOptionsSelector } from 'components/FormComponents'
import { ExistingStakeholdersSelector } from "./Components/ExistingStakeholdersSelector.jsx/ExistingStakeholdersSelector";
import { Input, InputGroup, MultipleSelect, DatePicker } from "components/FormComponents";
import { useState } from "react";
import cn from "classnames";
import { IconButton } from "components/IconButton/IconButton";
import { csvDataToJson } from "utils/helpers";
import { UploadOptionalPdf } from "./Components/UploadOptionalPdf/UploadOptionalPdf";
import { CustomActionsStakeholders } from "./Components/CustomActionsStakeholders/CustomActionsStakeholders"
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useAppContext } from "templates/AppLayout/AppLayout";

const STAKEHOLDER_TAG_QUERY = loader("graphql/queries/stakeholder_tags.graphql");

export const StakeholderJourneyForm = forwardRef(({csvData, setCsvData, disabled,journey, initialData, internalStakeholdersList, onSubmit = () => {}, setLoading, triggerUnexpectedError },  ref) => {
  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    unregister,
    setError: setFormError,
    formState: { errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: initialData
  });
  useFieldArray({ control, name: "internalStakeholdersAttributes" });
  useFieldArray({ control, name: "documentsCustomFields" });

  const [externalStakeholderTag, setExternalStakeholderTag] = useState({});
  const [internalStakeholderTags, setInternalStakeholderTags] = useState([]);
  const [error, setError] = useState(null);

  const { journeyBlocks } = journey;
  const [documentsWithCustomFields, setDocumentsWithCustomFields] = useState([]);
  const internalStakeholdersAttributes = initialData.internalStakeholdersAttributes;
  const formType = useWatch({ control, name: `formType` })

  const internals = useWatch({ control, name: `internalStakeholdersAttributes` })
  const externals = useWatch({ control, name: `externalStakeholderAttributes` })
  
  const isExternalMatchInternal = (externals, internal) => {
    const keysToCheck = ['email'];
    return keysToCheck.every(key => externals?.[key] === internal?.[key]);
  };
  
  const isMatch = internals?.some(internal => isExternalMatchInternal(externals, internal));
  const matchingInternal = internals?.find(internal => isExternalMatchInternal(externals, internal))
  const matchedTag = internalStakeholderTags?.find((iTag)=>iTag?.id === matchingInternal?.stakeholderTagId)

  const documentFields = initialData.documentsCustomFields;

  const hasActionsAttribute = journeyBlocks.some(block => block.actionsAttributes?.length > 0);
  useEffect(() => {
    setExternalStakeholderTag(journey.stakeholderTags.find((st) => st.typeOf === "external"));
    setInternalStakeholderTags(journey.stakeholderTags.filter((st) => st.typeOf === "internal"));
    setDocumentsWithCustomFields(journey.journeyBlocks.filter((jb) => jb.block.documentTemplateAttributes?.customFields?.length > 0));
  }, [journey]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit(onSubmit)();
    },
  }));

  useEffect(() => {
    Object.keys(errors).length === 0 ? setError(false) : setError(true);
    errors?.documentsCustomFields?.map((documentCustomFields, index) => {
      if (documentCustomFields.customFields.filter((e) => e !== undefined).length === 0) {
        clearErrors(`documentsCustomFields.${index}`);
      }
    });

    if (errors?.documentsCustomFields?.filter((e) => e !== undefined).length === 0) {
      clearErrors("documentsCustomFields");
    }
  }, [Object.keys(errors)]);

  return (
    <form className={cn(styles.root, "card")}>
      <input type="hidden" {...register("journeyId")} value={journey.id} />
      <CollapsibleBlock open={error} color={externalStakeholderTag?.color} className={styles.marginBottom} startOpen>
        <CollapsibleBlock.Header>
          <h1 className={styles.blockTitle}>
            <span>
              <span style={{ color: externalStakeholderTag?.color }}>{externalStakeholderTag?.name}</span>
              Details
            </span>
            <p className={cn(styles.subTitle, "t-subtitle")}>{initialData ? 'Enter the details below' : `Enter the details of the ${externalStakeholderTag?.name} below`}</p>
          </h1>
        </CollapsibleBlock.Header>
          <CollapsibleBlock.Content className="margin-top">
            {!disabled && !csvData?.length && (
              <div className="mb-5">
                <Controller
                  control={control}
                  name='formType'
                  render={({ field: { onChange, value } }) => (
                    <InlineOptionsSelector value={value} onChange={onChange} selectedColor={"#9B51E0"}>
                      <InlineOptionsSelector.Option value={1}>Existing Employee</InlineOptionsSelector.Option>
                      <InlineOptionsSelector.Option value={2}>New Employee</InlineOptionsSelector.Option>
                    </InlineOptionsSelector>
                  )}
                />
              </div>
            )}
            {
              formType === 1 ? <ExistingStakeholdersSelector emptyError isInline setCsvData={setCsvData} setError={setFormError} clearErrors={clearErrors} unregister={unregister} register={register} errors={errors} control={control} setLoading={setLoading} triggerUnexpectedError={triggerUnexpectedError} />
              :                <ExternalStakeholderForm isMatchError={isMatch} setError={setFormError} control={control} clearErrors={clearErrors} unregister={unregister} setValue={setValue} csvData={csvData} setCsvData={setCsvData} register={register} errors={errors} disabled={disabled} />
            }
          </CollapsibleBlock.Content>
        </CollapsibleBlock>
      {internalStakeholderTags.map((stakeholderTag, index) => (
        <CollapsibleBlock open={error} key={index} color={stakeholderTag.color} className={styles.marginBottom} startOpen>
          <CollapsibleBlock.Header>
            <h1 className={styles.blockTitle}>
              <span>
                <span style={{ color: stakeholderTag.color }}>{stakeholderTag.name}</span>
                Details
              </span>
              <p className={cn(styles.subTitle, "t-subtitle")}>Enter the details of the {stakeholderTag.name} below</p>
            </h1>
          </CollapsibleBlock.Header>
          <CollapsibleBlock.Content className="margin-top">
            <InternalStakeholderForm
              internalStakeholdersAttributes={internalStakeholdersAttributes}
              unregister={unregister}
              control={control}
              register={register}
              registerName={`internalStakeholdersAttributes.${index}`}
              stakeholderTag={stakeholderTag}
              errors={errors}
              clearErrors={clearErrors}
              internalStakeholdersList={internalStakeholdersList.filter(
                (internalStakeholder) => internalStakeholder.stakeholderTag.id === stakeholderTag.id
              )}
            />
            {stakeholderTag?.name === matchedTag?.name &&
              <div className={styles.error}>
                {matchedTag?.name}'s email must not match Employee's email
              </div>
            }
          </CollapsibleBlock.Content>
        </CollapsibleBlock>
      ))}
      {documentsWithCustomFields.map(({ block }, index) => {
        const stakeholderTag = journey.stakeholderTags.find((st) => st.id === block.stakeholderTagId);
        return (
          <CollapsibleBlock open={error} key={index} color={stakeholderTag.color} className={styles.marginBottom} startOpen>
            <CollapsibleBlock.Header>
              <h1 className={styles.blockTitle}>{block.name} Fields</h1>
            </CollapsibleBlock.Header>
            <CollapsibleBlock.Content className="margin-top">
              <DocumentBlockCustomFieldsForm
                register={register}
                registerName={`documentsCustomFields.${index}`}
                block={block}
                documentFields={documentFields}
                control={control}
                setValue={setValue}
                errors={errors?.documentsCustomFields?.[index]}
              />
            </CollapsibleBlock.Content>
          </CollapsibleBlock>
        );
      })}
        { !!hasActionsAttribute &&
          <ActionsAndNotifications
            journeyBlocks={journeyBlocks}
            useFieldArray={useFieldArray}
            errors={errors}
            clearErrors={clearErrors}
            unregister={unregister}
            register={register}
            setValue={setValue}
            internalStakeholdersList={internalStakeholdersList}
            control={control}
            styles={styles}
        />}
      <UploadOptionalPdf
        register={register}
        watch={watch}
        control={control}
        setValue={setValue}
        unregister={unregister}
        clearErrors={clearErrors}
        setError={setError}
        errors={errors}
      />
    </form>
  );
});

const ExternalStakeholderForm = ({ setError, clearErrors, unregister, register,setValue, errors, disabled, csvData, setCsvData, control, setLoading, triggerUnexpectedError, isMatchError }) => {
  const [dataError, setDataError] = useState(null)
  useEffect(()=>{
    if (csvData){
      unregister('externalStakeholderAttributes')
      clearErrors('csv')
      const data = csvDataToJson(csvData)
      if (data?.error){
        setDataError(data?.error)
        setError('csv')
      }else{
        setDataError(null)
        data?.forEach((row,index)=>{
        register(`externalStakeholders.${index}`)
        setValue(`externalStakeholders.${index}`, row)
      })
      }
    }else if(isMatchError){
      setError('externalStakeholderAttributes.custom', {type: 'Custom'})
    }
    else{
      unregister('stakeholderJourneyAttributes.externalStakeholderIds')
      unregister('stakeholderJourneyAttributes')
      clearErrors()
    }
  },[csvData, isMatchError])

  let errorCount = 0
  errors?.internalStakeholdersAttributes?.forEach((e)=> errorCount = e ? errorCount + 1 : errorCount)
  if (!errorCount && errors?.internalStakeholdersAttributes){
    clearErrors('internalStakeholdersAttributes')
  }

  return (
    <>
      {!disabled && !csvData && (
      <>
        <div className="inline">
          <InputGroup title="First Name">
            <Input
              name="externalStakeholderAttributes.firstName"
              placeholder="Add first name..."
              register={register}
              required
              error={errors?.externalStakeholderAttributes?.firstName?.message}
            />
          </InputGroup>
          <InputGroup title="Last Name">
            <Input
              name="externalStakeholderAttributes.lastName"
              placeholder="Add last name..."
              register={register}
              required
              error={errors?.externalStakeholderAttributes?.lastName?.message}
            />
          </InputGroup>
        </div>
        <div className="inline">
          <InputGroup title="Email">
            <Input
              name="externalStakeholderAttributes.email"
              type="email"
              placeholder="Add email address..."
              register={register}
              required
              error={errors?.externalStakeholderAttributes?.email?.message}
            />
          </InputGroup>
          <InputGroup title="Phone Number">
            <Input
              name="externalStakeholderAttributes.phoneNumber"
              placeholder="Add phone number..."
              register={register}
              required
              error={errors?.externalStakeholderAttributes?.phoneNumber?.message}
            />
          </InputGroup>
        </div>
      </>
      )}
      {csvData ? (
        <>
          <div className={cn(styles.csv, "card", "mb-4")}>
            <div className={styles.csvLengthBar}>
              {!dataError ? `{${csvData?.length} employees} added via upload` : <span className={cn(styles.error)}>{dataError}</span>}
            </div>
            <IconButton
              tip="Delete Field"
              className={styles.delete}
              onClick={() =>{
                setCsvData(null)
                unregister("stakeholderJourneyAttributes");
                unregister("stakeholderJourneyAttributes.externalStakeholderIds");
                clearErrors()
              }}
              icon="trash-2"
            />
          </div>
          <ExistingStakeholdersSelector
            setCsvData={setCsvData}
            setError={setError}
            clearErrors={clearErrors}
            unregister={unregister}
            register={register}
            errors={errors}
            control={control}
            setLoading={setLoading}
            triggerUnexpectedError={triggerUnexpectedError}
          />
        </>
      ) : (
        <InputGroup title="Start Date">
          <DatePicker name="externalStakeholderAttributes.startDate" register={register} minDate={new Date()} />
        </InputGroup>
      )}
    </>
  );
};

const InternalStakeholderForm = ({
  unregister,
  register,
  control,
  registerName,
  stakeholderTag,
  internalStakeholdersList,
  data,
  errors,
  clearErrors,
  internalStakeholdersAttributes,
}) => {
  const stakeholderAttributes = internalStakeholdersAttributes?.flat()?.find((stakeholder => stakeholder?.stakeholderTag?.id === stakeholderTag?.id))
  const [isNew, setIsNew] = useState(!stakeholderAttributes?.id);

  useEffect(() => {
    if (!isNew) {
      clearErrors();
      unregister(`${registerName}.firstName`);
      unregister(`${registerName}.lastName`);
      unregister(`${registerName}.email`);
      unregister(`${registerName}.phoneNumber`);
    }
  }, [isNew]);

  return (
    <>
      <div className={cn(styles.selector, "margin-bottom--large")}>
        <div
          onClick={() => setIsNew(false)}
          className={styles.selectorItem}
          style={{ ...(isNew ? {} : { backgroundColor: stakeholderTag.color, color: "white", borderColor: stakeholderTag.color }) }}
        >
          Existing {stakeholderTag.name}
        </div>
        <div
          onClick={() => setIsNew(true)}
          className={styles.selectorItem}
          style={{ ...(isNew ? { backgroundColor: stakeholderTag.color, color: "white", borderColor: stakeholderTag.color } : {}) }}
        >
          New {stakeholderTag.name}
        </div>
      </div>
      <input type="hidden" {...register(`${registerName}.stakeholderTagId`)} value={stakeholderTag.id} />
      {isNew ? (
        <>
          <div className="inline">
            <InputGroup title="First Name">
              <Input
                name={`${registerName}.firstName`}
                placeholder="Add first name..."
                register={register}
                required
                error={errors?.internalStakeholdersAttributes?.[0]?.firstName?.message}
              />
            </InputGroup>
            <InputGroup title="Last Name">
              <Input
                name={`${registerName}.lastName`}
                placeholder="Add last name..."
                register={register}
                required
                error={errors?.internalStakeholdersAttributes?.[0]?.lastName?.message}
              />
            </InputGroup>
          </div>
          <div className="inline">
            <InputGroup title="Email">
              <Input
                name={`${registerName}.email`}
                placeholder="Add email address..."
                register={register}
                type="email"
                required
                error={errors?.internalStakeholdersAttributes?.[0]?.email?.message}
              />
            </InputGroup>
            <InputGroup title="Phone Number">
              <Input
                name={`${registerName}.phoneNumber`}
                placeholder="Add phone number..."
                register={register}
                type="phone"
                required
                error={errors?.internalStakeholdersAttributes?.[0]?.phoneNumber?.message}
              />
            </InputGroup>
          </div>
        </>
      ) : (
        <>
          <Controller
            control={control}
            name={`${registerName}`}
            render={({ field: { onChange, value } }) => (
              <MultipleSelect value={value} onChange={onChange} className="margin-bottom" placeholder="Please select .">
                {internalStakeholdersList.map((internalStakeholder) => (
                  <MultipleSelect.Item
                    key={internalStakeholder.id}
                    value={internalStakeholder}
                    active={internalStakeholdersAttributes?.flat()?.filter((stakeholder => stakeholder?.stakeholderTag?.id === stakeholderTag?.id)).some(obj => obj?.id === internalStakeholder?.id)}
                  >
                    {`
                  ${internalStakeholder.firstName} -\n
                  ${internalStakeholder.email} /\n
                  ${internalStakeholder.phoneNumber}
                `}
                  </MultipleSelect.Item>
                ))}
              </MultipleSelect>
            )}
          />
        </>
      )}
    </>
  );
};

const DocumentBlockCustomFieldsForm = ({ register, block, documentFields = null, control, registerName, setValue, errors }) => {
  const { fields: customFields, append } = useFieldArray({ control, name: `${registerName}.customFields` });

  useEffect(() => {
    setValue(registerName, { blockId: block.id });
    const fieldsCreated = documentFields ? documentFields[0].customFields : false;
    if (!fieldsCreated) {
      block.documentTemplateAttributes.customFields.forEach((customField) => {
        append({ name: customField.name, value: "" });
      });
    }
    else {
      customFields.forEach((customField, index) => {
        setValue(`${registerName}.customFields.${index}.value`, customField.value)
      });
    }
  }, []);

  return (
    <>
      {customFields.map((customField, index) => (
        <DocumentBlockCustomField
          key={index}
          customField={customField}
          register={register}
          registerName={`${registerName}.customFields.${index}`}
          errors={errors?.customFields ? errors?.customFields[index] : null}
        />
      ))}
    </>
  );
};

const DocumentBlockCustomField = ({ register, registerName, customField, errors }) => {
  return (
    <InputGroup title={customField.name}>
      <Input
        name={`${registerName}.value`}
        placeholder="Enter value..."
        register={register}
        required
        error={errors?.value?.message}
      />
    </InputGroup>
  );
};

const ActionsAndNotifications = ({
  clearErrors,
  journeyBlocks,
  unregister,
  register,
  setValue,
  internalStakeholdersList,
  control,
  useFieldArray,
  errors,
  styles,
}) => {
  const { setLoading } = useAppContext();

  useFieldArray({ control, name: "customActions" });
  let index = -1
  
  const [stakeholderTags, setStakeholderTags] = useState([])

  const { loading: fetchingTags } = useQuery(STAKEHOLDER_TAG_QUERY, {
    onCompleted: data => {
      setStakeholderTags(data.stakeholderTags)
    }
  });

  useEffect(()=>{
    setLoading(fetchingTags)
  },[fetchingTags])

  return (
    <>
      <div className={cn(styles.uploadPdfContainer,"mb-4")}>
        <h1 className={cn(styles.blockTitle,"mb-2")}>
            <span>
            <span className="margin-bottom" style={{ color: '#4932d0' }}>Custom Stakeholder Actions</span>
            </span>
            <p className={cn(styles.subTitle, "t-subtitle margin-bottom")}>{'Notifications and Reports'}</p>
        </h1>
          {journeyBlocks?.map((journeyBlock) => {
            return journeyBlock?.actionsAttributes?.map((actionAttribute) => {
              return actionAttribute?.actionsStakeholderTagsAttributes?.map((attribute) => {
                index = index + 1
                return (
                  <CustomActionsStakeholders
                    key={index}
                    index={index}
                    unregister={unregister}
                    register={register}
                    name={`customActions.${index}`}
                    setValue={setValue}
                    typeOf={actionAttribute?.typeOf}
                    actionId={actionAttribute?.id}
                    block={journeyBlock?.block}
                    journeyBlockId={journeyBlock.id}
                    internalStakeholders={internalStakeholdersList}
                    control={control}
                    tagId={attribute?.stakeholderTagId}
                    styles={styles}
                    clearErrors={clearErrors}
                    errors={errors?.customActions}
                    stakeholderTags={stakeholderTags}
                  />
                );
              });
            });
          })}
        </div>
    </>
  );
};
