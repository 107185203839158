import { useEffect, useState } from 'react';
import styles from './StakeholderJourneyGetStartedScreen.module.scss';
import { StakeholderJourneyLayout, useStakeholderJourneyLayoutContext } from "../../../templates/StakeholderJourneyLayout/StakeholderJourneyLayout";
import { BlockCardPreview } from '../components/BlockCardPreview/BlockCardPreview';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryParams } from 'hooks/query-params';
import cn from 'classnames';
import defaultLogo from '../../../assets/images/KM_logo.svg'
import { PdfCardPreview } from '../components/PdfCardPreview/PdfCardPreview';

export const StakeholderJourneyGetStartedScreen = () => {

  const navigate = useNavigate();
  const { state } = useLocation();

  const { stakeholderJourney, token } = useStakeholderJourneyLayoutContext();
  const [stakeholder, setStakeholder] = useState();
  const [displayMsg, setDisplayMsg] = useState(false)

  const textStyle = { color: `${stakeholderJourney?.brand?.textColor}` }

  const [lastBlock] = useQueryParams(['lastBlock']);

  const heading = stakeholderJourney?.customization?.dashboardHeading || 'How this works and what you will need 🤔';
  const subHeading = stakeholderJourney?.customization?.dashboardSubHeading || "These records are available to you at your convenience, and you can save your progress and return at anytime.";

  const optionalPdfs = stakeholderJourney?.optionalPdfs
  const completed = optionalPdfs?.every(obj => obj.status === 'completed');

  const logo = stakeholderJourney?.brand?.logo?.url || defaultLogo;
  useEffect(() => {
    setStakeholder(stakeholderJourney.internalStakeholder || stakeholderJourney.externalStakeholder)
    var check = true
    stakeholderJourney?.stakeholderBlocks?.forEach((block)=>{
      check = block?.result?.status !== "completed" ? false : check
    })
    if (check && state?.navigated &&(completed || optionalPdfs?.length === 0)){
      setDisplayMsg(true)
      setTimeout(function () {
        navigate('/sign_in');
      }, 3000);
    }
  }, [stakeholderJourney]);

  return (
    <StakeholderJourneyLayout.Content withBrandBackgroundColor withFooter className={styles.root}>
      {displayMsg && state?.navigated ?
        <div className={styles.heading}>
          <div className={styles.title}>
              Thanks, All Complete ✅
          </div>

        </div>
        :
        <>
        <div className={styles.heading}>
          <h1 className={styles.title} style={textStyle}>{heading}</h1>
          <img
            className={cn(styles.logo)}
            src={logo}
            alt='Brand logo'
          />
        </div>
        <p style={textStyle} className={cn(styles.subtitle, 't-body')}>
          {subHeading}
        </p>
        <div className='w-100'>
          <h2 style={textStyle} className='margin-bottom--large'>Get started by selecting one of the options below</h2>
          <div className={styles.blocks}>
            {stakeholder && (
              stakeholderJourney?.stakeholderBlocks?.map(stakeholderBlock => (
                <BlockCardPreview
                  key={stakeholderBlock.journeyBlockId}
                  stakeholder={stakeholder}
                  forceCompletion={lastBlock == stakeholderBlock.journeyBlockId}
                  {...stakeholderBlock}
                  onNavigate={() => navigate(`/journey/${token}/block/${stakeholderBlock.journeyBlockId}`)}
                />
              ))
              )}
            {optionalPdfs?.length > 0 &&
              <PdfCardPreview
                stakeholder={stakeholder}
                optionalPdfs={optionalPdfs}
                onNavigate={() => navigate(`/journey/${token}/optional_pdf`)}
              />

            }
          </div>
        </div>
        </>
      }
    </StakeholderJourneyLayout.Content>
  )
}
