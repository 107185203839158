import { useEffect } from "react";
import styles from "./Textarea.module.scss";
import cn from "classnames";
import PropTypes from "prop-types";
import FeatherIcon from "feather-icons-react";
import autosize from "autosize";

export const Textarea = ({ register, name, validators = {}, placeholder, containerClassName, className, inputProps, error, dynamicHeight }) => {
  const { ref, ...registerProps } = register(name, validators);

  useEffect(() => {
    if (dynamicHeight) {
      autosize(document.querySelectorAll('.' + className))
    }
  })

  return (
    <div className={cn(className, styles.textareaContainer, {[containerClassName]: containerClassName})}>
      {inputProps?.disabled && <FeatherIcon className={styles.lock} icon="lock" />}
      <textarea
        ref={ref}
        name={name}
        error={error}
        className={cn(styles.root, { [className]: className, [styles.rootWithError]: error, [styles.disabled]: inputProps?.disabled })}
        placeholder={placeholder}
        {...registerProps}
        {...inputProps}
      />
      {(error ) && <span className={styles.error}>{error}</span> }
    </div>
  );
};

Textarea.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.string,
  inputProps: PropTypes.object,
  dynamicHeight: PropTypes.bool
};

Textarea.defaultProps = {
  register: (...args) => ({ ref: () => {} }),
  error: "",
  validators: {},
  inputProps: {},
  dynamicHeight: false
};
