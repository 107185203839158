import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Button.module.scss';
import FeatherIcon from 'feather-icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const Button = ({
  className,
  type,
  submit,
  small,
  icon,
  disabled,
  children,
  buttonProps,
  tip,
  placement = 'bottom',
  onClick = () => {}
}) => (
  <OverlayTrigger trigger={tip === undefined ? 'none' : ['hover', 'focus']} placement={placement} overlay={<Tooltip>{tip}</Tooltip>}>
    <button
      className={cn(styles.root, styles[`root--${type}`], {
        [styles.rootSmall]: small,
        [className]: className,
      })}
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={onClick}
      {...buttonProps}
    >
      {icon && <FeatherIcon className={styles.icon} icon={icon} />}
      {children}
    </button>
  </OverlayTrigger>
)

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'secondary-reverse', 'link', 'delete']),
  small: PropTypes.bool,
  icon: PropTypes.string,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  buttonProps: PropTypes.object,
};

Button.defaultProps = {
  submit: false,
  small: false,
  disabled: false,
  type: 'primary',
  buttonProps: {}
};
