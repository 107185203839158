import React, { useEffect, useRef } from "react";
import VidyardEmbed from "@vidyard/embed-code";
import Style from './VidyardSettings.module.scss'
import { Input } from "components/FormComponents";

export const VidyardSettings = ({ name, register, error, watch, vidyardVideo }) => {
  const previousValueRef = useRef();
  const value = vidyardVideo || watch(`${name}`);

  useEffect(() => {
    const previousValue = previousValueRef.current;
    const allPlayers = VidyardEmbed.api.getPlayersByUUID(previousValue)[0]
    VidyardEmbed.api.destroyPlayer(allPlayers)
    
    if (value) {
      const playerConfig = {
        uuid: `${value}`,
        container: document.getElementById("player-container"),
      };
      VidyardEmbed.api.renderPlayer(playerConfig);
    }

    previousValueRef.current = value;
  }, [value]);

  return (
    <div>
      {!vidyardVideo &&
      <div className={Style.input}>
        <>
          <p className="t-color-secondary t-tiny">
            * We only accept Vidyard Video's <b>UUID</b> at the moment. Sorry for the inconvenience
          </p>
          <p className="t-color-secondary t-tiny">* Make sure embedding of the video is allowed</p>
          <Input
            register={register}
            name={`${name}`} 
            placeholder="yYNUbBHeHdnNo7EboYfmiR" 
            validators={{ required: true }} 
            error={error} 
          />
        </> 
      </div>
      }
      <div style={{ width: "100%", height: "100%" }} className={Style.playerContainer} id="player-container"></div>
    </div>
  );
};