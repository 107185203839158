import styles from './MaxWidthContent.module.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const MaxWidthContent = ({ wrapperClassName, className, children, contentClassName }) => (
  <div className={cn(styles.root, { [wrapperClassName]: wrapperClassName })}>
    <div className={cn(styles.content, { [className]: className }, {[contentClassName]: contentClassName})}>
      {children}
    </div>
  </div>
)

MaxWidthContent.propTypes = {
  wrapperClassName: PropTypes.string,
  className: PropTypes.string
};