import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { AppLayout, useAppContext } from '../../templates/AppLayout/AppLayout';
import { Tabs, Tab } from '../../components/Tabs/Tabs';
import styles from './CompanySettingsScreen.module.scss';
import { Users } from './tabs/Users/Users';
import { Roles } from './tabs/Roles/Roles';
import { Company } from './tabs/Company/Company';
import { BrandingTab } from './tabs/BrandingTab/BrandingTab';
import { Teams } from './tabs/Teams/Teams';
import { IntegrationsTab } from './tabs/Integrations/IntegrationsTab';

export const CompanySettingsScreen = () => {

  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  const [activeTab, setActiveTab ] = useState('users')

  const { state } = useLocation();
  const tabName  = state?.tabName

  useEffect(() => {
    if (tabName) setActiveTab(tabName)
  }, [tabName, activeTab]);

  return (
    <>
      <AppLayout.Header>
        <AppLayout.Title>Company</AppLayout.Title>
        <AppLayout.Subtitle>View and manage your company settings below.</AppLayout.Subtitle>
      </AppLayout.Header>
      <AppLayout.Body>
        <Tabs
          defaultActiveKey={activeTab}
          contentStyles={styles.tabContent}
          navItemStyles={styles.navItems}
        >
          <Tab eventKey="company" title="Settings">
            <Company alert={alert} error={triggerUnexpectedError} />
          </Tab>
          <Tab eventKey="users" title="Users">
            <Users setLoading={setLoading} alert={alert} error={triggerUnexpectedError} />
          </Tab>
          <Tab eventKey="roles" title="Roles">
            <Roles setLoading={setLoading} alert={alert} error={triggerUnexpectedError} />
          </Tab>
          <Tab eventKey="teams" title="Teams">
            <Teams setLoading={setLoading} alert={alert} error={triggerUnexpectedError} />
          </Tab>
          <Tab eventKey="branding" title="Branding">
            <BrandingTab setLoading={setLoading} alert={alert} error={triggerUnexpectedError} />
          </Tab>
          <Tab eventKey="integrations" title="Integrations">
            <IntegrationsTab setLoading={setLoading} alert={alert} error={triggerUnexpectedError} />
          </Tab>
        </Tabs>
      </AppLayout.Body>
    </>
  )
}
