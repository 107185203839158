import styles from './StakeholderJourneyConfig.module.scss';
import cn from 'classnames';
import { Button } from 'components/FormComponents/Button/Button';
import { JourneyPreview } from '../JourneyPreview/JourneyPreview';
import { useState, useRef } from 'react';
import { StakeholderJourneyForm } from '../StakeholderJourneyForm/StakeholderJourneyForm';
import { StakeholderJourneyPreview } from '../StakeholderJourneyPreview/StakeholderJourneyPreview';
import FeatherIcon from 'feather-icons-react';
import { IconButton } from 'components/IconButton/IconButton';
import { readFile, sampleCsv } from 'utils/helpers';

export const StakeholderJourneyConfig = ({
  journey,
  disableSettings,
  internalStakeholders = [],
  onSubmit = () => {},
  onClose = () => {},
  error = () => {},
  setParentStep = () => {},
  setOpenSettings = () => {},
  existingStakeholderCheck ,
  existingStakeholderData ,
  triggerUnexpectedError,
  setLoading,
}) => {

  const [step, setStep] = useState(1);

  const [stakeholderJourneyData, setStakeholderJourneyData] = useState(existingStakeholderCheck ? existingStakeholderData : {formType:2});

  const formRef = useRef(null);

  const [data, setData] = useState(null)

  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    event.target.value=null
    readFile(fileUploaded, setData, triggerUnexpectedError)
  };

  const nextStep = () => {
    switch (step) {
      case 1:
        setStep(2);
        setParentStep(2);
        break;
      case 2:
        formRef.current.submit();
        break;
      case 3:
        const errorDocumentBlocks = journey?.journeyBlocks?.filter(jBlock => (jBlock.block?.typeOf === "document" && !jBlock.block.documentTemplateAttributes.signeasyTemplateId))
        if (errorDocumentBlocks?.length) {
          triggerUnexpectedError("Document Blocks must be configured before the launch")
        } else {
          onSubmit(stakeholderJourneyData);
        }
        break;
    }
  }

  const handleDataSubmission = (data) => {
    setStakeholderJourneyData(data);
    setStep(3);
  }

  const setPrevious = () => {
    setStep(step - 1)
    setParentStep(step - 1)
  }

  return (
    <div className='card card-with-border'>
      <div className='card_content'>
        <header className='d-flex justify-content-between w-100'>
          <div>
            <h1 className='title-2'>{journey.name}</h1>
            <p className={cn(styles.subtitle, 't-body')}>{journey.journeyBlocks.length} modules</p>
          </div>
          <div className={cn(styles.icons)}>
            {step ===2 && !existingStakeholderData &&
            <>
              <input type="file"
                ref={hiddenFileInput}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleChange}
                style={{display:'none'}}
              />
              <IconButton icon='upload' tip='bulk upload CSV' onClick={handleClick} className={cn(styles.csv, 'me-2')}/>
              <Button type='secondary' icon='download' tip='download CSV sample' className={cn(styles.downloadButton)} onClick={sampleCsv}>Templates</Button>
            </>}
            {!disableSettings && journey?.journeyCustomization && <IconButton className={cn(styles.csv, 'ms-2')} icon='settings' onClick={() => {setOpenSettings(true)}} />}
          </div>
        </header>
        <StepIndicator step={step} setStep={setStep} setParentStep={setParentStep}/>
      </div>
      <div className={cn('card_content', 'background-secondary', styles.content)}>
        {step === 1 &&
          <JourneyPreview journey={journey} />
        }
        {step === 2 &&
          <StakeholderJourneyForm csvData={data} setCsvData={setData} ref={formRef} initialData={stakeholderJourneyData} journey={journey} internalStakeholdersList={internalStakeholders} onSubmit={handleDataSubmission} disabled={existingStakeholderCheck} setLoading={setLoading} triggerUnexpectedError={triggerUnexpectedError}/>
        }
        {step === 3 &&
          <StakeholderJourneyPreview journey={journey} stakeholderJourneyData={stakeholderJourneyData} externalStakeholderData={existingStakeholderData}/>
        }
      </div>
      <div className='card_footer d-flex justify-content-between'>
        <Button type='secondary' onClick={onClose}>Cancel</Button>
        <div className='d-flex'>
          {step > 1 && !existingStakeholderCheck &&
            <Button type='secondary' className='margin-right' onClick={setPrevious}>Previous </Button>
          }
          <Button disabled={journey.journeyBlocks.length <= 0} onClick={nextStep}>{step === 3 ? 'Launch': 'Next'}</Button>
        </div>
      </div>
    </div>
  )
}

const StepIndicator = ({ step = 1, setStep, setParentStep }) => {

  const stepBack = (self) => {
    if (step > 1 && self === 1) {
      setStep(1)
      setParentStep(1)
    } else if (step > 2 && self === 2) {
      setStep(2)
      setParentStep(2)
    }
  }

  return (
    <div className={styles.stepIndicator}>
      <div onClick={() => {stepBack(1)}} className={cn(styles.step, {[styles.stepSelected]: step === 1, [styles.stepCompleted]: step > 1 })}>
        Review Journey {step > 1 && <FeatherIcon icon='check' size={16} className='margin-left--small'/>}
      </div>
      <div onClick={() => {stepBack(2)}} className={cn(styles.step, {[styles.stepSelected]: step === 2, [styles.stepCompleted]: step > 2 })}>
        Add Information {step > 2 && <FeatherIcon icon='check' size={16} className='margin-left--small'/>}
      </div>
      <div className={cn(styles.step, {[styles.stepSelected]: step === 3 })}>
        Summary
      </div>
    </div>
  )
}
