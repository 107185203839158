import { useRef, useState, useEffect } from 'react';
import styles from './DatePicker.module.scss';
import PropTypes from 'prop-types';
import { useCombinedRefs } from '../../../hooks/combined-refs';
import DatePickerComponent from "react-datepicker";
import { ChangeEvent } from '../../../utils/form-utils';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export const DatePicker = ({
  register,
  name,
  validators = {},
  className,
  minDate,
  outputFormat,
  disabled,
  initialValue
}) => {

  // Be able to use ref with register
  const { ref, ...registerProps } = register(name, validators);
  const inputRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, inputRef);
  
  // Initialize date state with the initial value from props
  const [date, setDate] = useState(initialValue ? new Date(initialValue) : new Date());

  useEffect(() => {
    if(!inputRef.current) return;

    const { value: currentValue } = inputRef.current;
    const newValue = moment(date).format(outputFormat)
    inputRef.current.value = newValue;
    if(currentValue !== newValue && registerProps?.onChange) {
      registerProps.onChange(ChangeEvent(name, newValue))
    }
  }, [date, inputRef]);

  return (
    <div className={cn(styles.root, { [className]: className })}>
      <input name={name} ref={combinedRef} {...registerProps}/>
      <DatePickerComponent
        dateFormat="dd-MM-yyyy"
        onChange={setDate}
        selected={date}
        minDate={minDate}
        disabled={disabled}
        className={cn({[styles.disabled]: disabled})}
      />
    </div>
  )
}

DatePicker.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  validators: PropTypes.object,
  className: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  outputFormat: PropTypes.string
};

DatePicker.defaultProps = {
  register: (...args) => ({ ref: () => {} }),
  validators: {},
  outputFormat: 'DD/MM/YYYY'
};