import { TangoLinkInput } from "components/FormComponents/TangoLinkInput/TangoLinkInput";

export const TangoEmbedSettings = ({ register, name, watch, errors }) => (
  <TangoLinkInput
    register={register}
    className="margin-top--large"
    name={`${name}.params.videoSrc`}
    value={watch(`${name}.params.videoSrc`)}
    errors={errors}
  />
);
