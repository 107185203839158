import { Modal } from "components/Modal/Modal";
import { Button } from "components/FormComponents";
import cn from "classnames";
import { loader } from "graphql.macro";
import styles from "./InternalStakeholdersModal.module.scss"
import { Checkbox } from "components/FormComponents/Checkbox/Checkbox";
import { useMutation } from "@apollo/client";
import { useState } from "react";

const DELETE_INTERNAL_STAKEHOLDERS_MUTATION = loader("./../../../../../graphql/mutations/delete_internal_stakeholders.graphql")

export const InternalStakeholdersModal = ({alert, selected, setSelected, onCancel , visible, tag, triggerUnexpectedError }) => {
  const [internalStakeholders , setInteralStakeholders] = useState(tag?.internalStakeholders)

  const handleCheckboxClick = (stakeholderId) => {
    selected.includes(stakeholderId) ? setSelected((selected) =>selected.filter((id) => id !== stakeholderId)) : setSelected([...selected,stakeholderId]) 
  }
  
  const [ deleteInternalStakeholders, { loading: deleting }] = useMutation(DELETE_INTERNAL_STAKEHOLDERS_MUTATION, {
    onCompleted: async(data) => {
      const ids = data?.deleteInternalStakeholders?.map((val)=>val.id)
      const filtered = internalStakeholders?.filter((is)=>!ids?.includes(is.id))
      setInteralStakeholders(filtered)
      alert('Stakeholder Successfully deleted')
      onCancel()
    }, onError: error => {
      triggerUnexpectedError(error.message)
    }
  })

  return (
    <Modal title={`Existing ${tag?.name}`} visible={visible} onClose={()=>onCancel()}>
      <div className={cn(styles.root)}>
        <div className={cn(styles.card,"card", "card-with-border", "mt-2 mb-2")}>
          {internalStakeholders?.length ? internalStakeholders?.map((stakeholder)=>{
            return (
              <div className={cn(styles.item, 't-body')}>
                <Checkbox checked={selected?.includes(stakeholder?.id)} onClick={()=>handleCheckboxClick(stakeholder?.id)} className={cn(styles.checkBox)}/> 
                <li className={cn(styles.li)}>{stakeholder?.name} - {stakeholder?.email}</li>
              </div>
            )
          })
        :
        <div className={cn(styles.notFound)}>
          No Stakeholders Found
        </div>
        }
        </div>
        <div className="d-flex justify-content-between w-100 margin-top--large">
          <Button type="secondary" onClick={()=>onCancel()}>
            Cancel
          </Button>
          <Button onClick={()=> {
            deleteInternalStakeholders({variables: {ids: selected?.map((id)=>parseInt(id))}})
            }} disabled={!selected?.length} icon="trash-2" >Delete {!!selected?.length && selected?.length}</Button>
        </div>
      </div>
    </Modal>
  );
};
