import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import { TriggerTypesOptions } from 'constants/trigger-types-enum';
import { useFieldArray } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { Select } from 'components/FormComponents';
import styles from './TriggerSelect.module.scss';
import cn from 'classnames';

export const TriggerSelect = ({
  control,
  index,
  setValue,
  registerName,
  register,
  watch,
  journeyBlocks,
  blocks,
  block
}) => {

  const { fields: triggers, append } = useFieldArray({ control, name: `journeyBlocksAttributes.${index}.triggersAttributes`, keyName: 'key' });

  const [useTriggers, setUseTriggers] = useState(null);

  // Set triggers toggle
  useEffect(() => {
    setUseTriggers(triggers?.filter(trigger => !trigger._destroy).length > 0)
  }, [triggers])

  // Delete triggers if toggled off
  useEffect(() => {
    if(useTriggers === null) return;

    if(!useTriggers) {
      let _triggers = [];
      triggers.forEach(trigger => {
        if(trigger.key) delete trigger.key;
        if(trigger.id) _triggers.push({ ...trigger, _destroy: true });
      });
      setValue(`journeyBlocksAttributes.${index}.triggersAttributes`, _triggers);
    }
  }, [useTriggers])

  const addTrigger = () => {
    append();
  }

  const activateTrigger = (e) => {
    addTrigger();
    setUseTriggers(e.target.checked)
  }

  const dateTypes = [
    {type: 'days', title: 'Day(s)'},
    {type: 'weeks', title: 'Week(s)'},
    {type: 'month', title: 'Month(s)'}
  ]
  
  return (
    <EditableSettings 
      title='Set custom triggers'
      open={useTriggers}
      className='w-100 margin-top'
      toggleable
      onChange={e => activateTrigger(e)}
    >
      {(
        triggers.map((trigger, index) => {
          const updateRegisterName = `${registerName}.triggersAttributes.${index}`
          const journeyBlocksIds = journeyBlocks.map((jb) => (jb.blockId))
          if (!trigger._destroy) {
            return (
              <div className='d-flex'>
                <Select register={register} name={`${updateRegisterName}.typeOf`} placeholder='Select Type' className={cn(styles.typeSelector, {[styles.leftSelect]: watch(`${updateRegisterName}.typeOf`)})}>
                  {TriggerTypesOptions.map((triggerType) => (
                    <Select.Item 
                      key={triggerType.key} 
                      value={triggerType.key}
                      active={triggerType.key === trigger.typeOf}
                    >
                      {triggerType.title}
                    </Select.Item>
                  ))}
                </Select>
                {(watch(`${updateRegisterName}.typeOf`) === 'before_date' || watch(`${updateRegisterName}.typeOf`) === 'after_date') && (
                  <>
                    <Select register={register} name={`${updateRegisterName}.params.dateAmount`} placeholder='Quantity' className={cn(styles.typeSelector, styles.middleSelect)}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(number => (
                        <Select.Item 
                          key={number} 
                          value={number}
                          active={watch(`${updateRegisterName}.params.dateAmount`) === number}
                        >
                          {number}
                        </Select.Item>
                      ))}
                    </Select>
                    <Select register={register} name={`${updateRegisterName}.params.dateType`} placeholder='Select date type' className={cn(styles.typeSelector, styles.rightSelect)}>
                      {dateTypes.map(dateType => (
                        <Select.Item 
                          key={dateType.type} 
                          value={dateType.type}
                          active={watch(`${updateRegisterName}.params.dateType`) === dateType.type}
                        >
                          {dateType.title}
                        </Select.Item>
                      ))}
                    </Select>
                  </>
                )}
                {watch(`${updateRegisterName}.typeOf`) === 'dependent_completed' && (
                  <Select register={register} name={`${updateRegisterName}.dependentBlockId`} placeholder='Select block' className={cn(styles.typeSelector, styles.rightSelect)}>
                    {blocks.filter((b) => {return (b.id !== block.id && journeyBlocksIds.includes(b.id))}).map(filteredBlock => {
                      return (
                      <Select.Item 
                        key={filteredBlock.id} 
                        value={filteredBlock.id}
                        active={watch(`${updateRegisterName}.dependentBlockId`) === filteredBlock.id}
                      >
                        {filteredBlock?.name}
                      </Select.Item>
                    )})}
                  </Select>
                )}
              </div>
            )
          }
        }
      ))}
    </EditableSettings>
  )
}