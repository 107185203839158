import { Modal } from './../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { Input, InputGroup, Button, Textarea, ColorInput } from './../../../../components/FormComponents';

export const NewStakeholderTagModal = ({ visible, onClose, onSubmit }) => {

  const { watch, register, handleSubmit,reset, formState: { errors } } = useForm();
  const close = () => {
    onClose()
    reset()
  }
  return (
    <Modal title='New Stakeholder' visible={visible} onClose={close}>
      <form className='margin-top--large' onSubmit={handleSubmit(onSubmit)}>
        <InputGroup title='Stakeholder Label'>
          <Input
            name='name'
            placeholder='Enter a stakeholder label...'
            register={register}
            validators={{ required: true }}
            inputProps={{ tabIndex: 1 }}
            error={errors?.name?.type}
          />
        </InputGroup>
        <InputGroup title='Stakeholder Description'>
          <Textarea
            name='description'
            placeholder='Enter a description for the stakeholder...'
            register={register}
            inputProps={{ tabIndex: 2, rows: '3' }}
          />
        </InputGroup>
        <InputGroup title='Stakeholder Color'>
          <ColorInput
            name='color'
            register={register}
            initialColor={watch('color')}
            inputProps={{ tabIndex: 3 }}
          />
        </InputGroup>
        <InputGroup title='Stakeholder Email & Welcome Message Copy'>
          <Textarea
            name='welcomeMessage'
            placeholder="Enter the messaging you'd like this stakeholder to receive when being alerted of journeys they will be asked to participate in ..."
            register={register}
            inputProps={{ tabIndex: 4, rows: '3' }}
          />
        </InputGroup>
        <div className='d-flex justify-content-between w-100 margin-top--large'>
          <Button type='secondary' onClick={close}>Cancel</Button>
          <Button submit>Save</Button>
        </div>
      </form>
    </Modal>
  )
}
