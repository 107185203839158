import { EditableSettings } from '../../../../../../../../../../../components/EditableSettings/EditableSettings';
import { Fragment, useState, useEffect } from 'react';
import { ToggleSwitch, Input, Select, InputGroup } from '../../../../../../../../../../../components/FormComponents';
import { Collapse } from 'react-bootstrap';
import cn from 'classnames';
import styles from './OpinionScaleSettings.module.scss';

export const OpinionScaleSettings = ({ register, name, field }) => (
  <Fragment>
    <EditableSettings title='Settings' className='margin-top--large' startOpen={true}>
      <ToggleSwitch register={register} name={`${name}.required`} label='Required' />
      <hr />
      <Fragment>
        <ToggleSwitch register={register} name={`${name}.useDescription`} label='Add description' />
        <Collapse in={field.options?.useDescription}>
          <div><Input register={register} name={`${name}.description`} className='margin-top--small margin-bottom' placeholder='Enter description...'/></div>
        </Collapse>
      </Fragment>
      <hr />
      <Fragment>
        <ToggleSwitch register={register} name={`${name}.useCustomSteps`} label='Customise steps' />
        <Collapse in={field.options?.useCustomSteps}>
          <div className='margin-top--small'>
            <div className='d-flex align-items-center'>
              <Select register={register} name={`${name}.stepsStart`} className='margin-right'>
                <Select.Item value='0'>0</Select.Item>
                <Select.Item value='1' active={true}>1</Select.Item>
              </Select>
              <p className='margin-0 t-body'>to</p>
              <Select register={register} name={`${name}.stepsEnd`} className='margin-left'>
                <Select.Item value='3'>3</Select.Item>
                <Select.Item value='5'>5</Select.Item>
                <Select.Item value='7'>7</Select.Item>
                <Select.Item value='10' active={true}>10</Select.Item>
              </Select>
            </div>
            <div className='d-flex align-items-center margin-top'>
              <InputGroup title={`"${field.options.stepsStart}" Label`} className='margin-right--large margin-bottom--small'>
                <Input name={`${name}.labelStart`} placeholder='Enter label' register={register} />
              </InputGroup>
              <InputGroup title={`"${field.options.stepsEnd}" Label`} className='margin-left margin-bottom--small'>
                <Input name={`${name}.labelEnd`} placeholder='Enter label' register={register} />
              </InputGroup>
            </div>
          </div>
        </Collapse>
      </Fragment>
    </EditableSettings>
    <hr className='my-5' />
    <ScalePreview {...field.options} />
  </Fragment>
)

const ScalePreview = ({ useCustomSteps, stepsStart, stepsEnd, labelStart, labelEnd }) => {

  const [scale, setScale] = useState([]);

  useEffect(() => {
    const start = useCustomSteps && stepsStart ? parseInt(stepsStart) : 1;
    const end = useCustomSteps && stepsEnd ? parseInt(stepsEnd) : 10;
    const array = Array(end - start + 1).fill().map((_, index) => start + index);
    setScale(array);
  }, [useCustomSteps, stepsStart, stepsEnd])

  return (
    <div className={cn(styles.scalePreview, 'margin-bottom--large')}>
      <div className={styles.scaleWrapper}>
        {scale.map(number => <span key={number} className='t-body'>{number}</span>)}
      </div>
      <label className={styles.labelStart}>{labelStart}</label>
      <label className={styles.labelEnd}>{labelEnd}</label>
    </div>
  )
}