import axios from 'axios';

export const directUpload = (url, headers, file) => {
  let params = {
    method: 'PUT',
    url,
    data: file,
    headers
  }

  return new Promise((resolve, reject) => {
    axios(params)
      .then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      });
  });
}