import { useState, useCallback } from 'react';
import styles from './ImagePicker.module.scss';
import PropTypes from 'prop-types';
import { loader } from "graphql.macro";
import FeatherIcon from 'feather-icons-react';
import { useDropzone } from 'react-dropzone';
import { useMutation, useLazyQuery } from "@apollo/client";
import { Loader } from './../../Loader/Loader';
import { getFileMetadata } from '../../../utils/active-storage-utils';
import { directUpload } from '../../../utils/direct-upload';
import { useEffect } from 'react';
import cn from 'classnames';

const SIGN_UPLOAD_MUTATION =   loader("./../../../graphql/mutations/sign_upload.graphql");
const CREATE_UPLOAD_MUTATION = loader("./../../../graphql/mutations/create_upload.graphql");
const DELETE_UPLOAD_MUTATION = loader("./../../../graphql/mutations/delete_upload.graphql");
const UPLOAD_QUERY =           loader("./../../../graphql/queries/upload.graphql");

export const ImagePicker = ({
  value,
  onChange,
  disabled,
  error,
  stakeholderJourneyToken
}) => {
  const [imageUrl, setImageUrl] = useState();

  const [uploading, setUploading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const [fetchUpload, { loading: fetching }] = useLazyQuery(UPLOAD_QUERY, {
    onCompleted: data => setImageUrl(data.upload?.url)
  });

  const [signUpload] = useMutation(SIGN_UPLOAD_MUTATION);
  const [createUpload] = useMutation(CREATE_UPLOAD_MUTATION);
  const [deleteUpload] = useMutation(DELETE_UPLOAD_MUTATION);


  useEffect(() => {
    if(value) fetchUpload({ variables: { id: value, token: stakeholderJourneyToken }})
    else setImageUrl(null)
  }, [value])

  /**
   * --- On upload ---
   * 1) Get file meta
   * 2) Sign upload
   * 3) Upload to s3
   * 4) Create upload
   * 5) Set value
   */
  const onDrop = useCallback(async (acceptedFiles) => {
    setUploading(true);
    setImageLoading(true);
    const file = acceptedFiles[0];
    if(!file) return;

    const fileMetadata = await getFileMetadata(file);
    const signedResponse = await signUpload({ variables: { signUploadAttributes: fileMetadata, token: stakeholderJourneyToken }});
    const { url, headers, signedBlobId } = signedResponse.data.signUpload;
    await directUpload(url, JSON.parse(headers), file);
    const uploadResponse = await createUpload({ variables: { blobId: signedBlobId, token: stakeholderJourneyToken }});
    const { id: imageId, url: imageUrl } = uploadResponse.data.createUpload;
    setImageUrl(imageUrl);
    onChange(imageId)
    setUploading(false);
  }, []);

  const onRemove = () => {
    deleteUpload({ variables: { id: value, token: stakeholderJourneyToken }})
    setImageUrl(null);
    onChange(null);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });
  const errorBorder = error ? {border : "1px solid red"} : null
  return (
    <>
      <div className={cn(styles.root, 'margin-top')} style={errorBorder}>
        {uploading || fetching && <Loader /> }

        {(imageUrl && !uploading && !fetching) &&
          <div>
            {imageLoading && <Loader />}
            <img src={imageUrl} className={styles.imagePreview} onLoad={() => setImageLoading(false)} />
            {(!imageLoading && !disabled) &&
              <div className={styles.removeImageIconWrapper} onClick={onRemove}>
                <FeatherIcon className={styles.removeImageIcon} icon="trash-2" size={40} />
              </div>
            }
          </div>
        }

        {(!imageUrl && !uploading && !fetching) &&
          <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            <FeatherIcon icon='image' size={30} className={styles.icon} />
            <p>Drag or <span className='link'>browse</span> to upload</p>
          </div>
        }
      </div>
          {(error ) && <span className={styles.error}>{error}</span> }
    </>
  )
}

ImagePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
};

ImagePicker.defaultProps = {
  onChange: () => {}
};
