import { EditableSettings } from '../../../../../../components/EditableSettings/EditableSettings';
import { Button, Input, InputGroup } from '../../../../../../components/FormComponents';
import styles from './JourneyPublicSettings.module.scss'
import { useEffect, useState, useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';

export const JourneyPublicSettings = ({
  journey,
  onCustomizeJourney = () => {}
}) => {

  const publicUrlAccess = `${window.location.origin}/journey/${btoa(journey?.id)}`

  const [isEnable, setIsEnable] = useState(false);

  const handleToggle = () => {
    onCustomizeJourney({
      id: journey.id,
      usePublicUrlAccess: !isEnable,
      publicUrlAccess: publicUrlAccess
    }, setIsEnable)

  }

  useEffect(() => {
    setIsEnable(journey?.journeyCustomizationAttributes?.usePublicUrlAccess ?? false)
  },[journey])

  const svgRef = useRef(null);

  const downloadQRCode = () => {
    const svgElement = svgRef.current.firstChild;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const svgSize = svgElement.getBoundingClientRect();
    canvas.width = svgSize.width - 2;
    canvas.height = svgSize.height - 2;
    const img = new Image();
    img.onload = () => {
      context.drawImage(img, 0, 0);
      canvas.toBlob(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'qrcode.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(new XMLSerializer().serializeToString(svgElement));
  }

  return (
    <>
      <EditableSettings
        title='Make Journey Public Facing'
        open={isEnable} className='w-100 margin-top--large'
        toggleable name='usePublicUrlAccess' onChange={handleToggle}>
        <InputGroup title='Public URL Access'>
          <Input
            className={styles.urlInput}
            name='publicUrlAccess'
            placeholder="Click on 'Make Journey Public Facing', to get the public url"
            value={publicUrlAccess}
            actionIcon='copy'
            actionIconTip='Copy Url'
            inputProps={{ readOnly: true }}
            onActionClick={() => {
              navigator.clipboard.writeText(publicUrlAccess);
            }}
          />
        </InputGroup>
        <div>
          <span className={styles.title}>QR Code</span>
          <div className={styles.QRContainer}>
            <div className={styles.QRCode} ref={svgRef}>
              <QRCodeSVG className={styles.canvasStyle}
                id="qr-code"
                value={publicUrlAccess}
                size={200}
                level={"M"}
                includeMargin={true}
              />
              <Button className={styles.downloadBtn} onClick={downloadQRCode}>
                <FeatherIcon className={styles.downloadIcon} icon='download' /> Download QR Code</Button>
            </div>
            <p className={styles.description}>
              Use this QR asset to trigger your journey. You can embed this in your existing brand material or print it out and attach it to a physical object or space.
              <br/><br/>
              QR code usecases include:
              <ul>
                <li>Self service onboarding for new employees</li>
                <li>Survey capture</li>
                <li>On-site inductions</li>
              </ul>
            </p>
          </div>
        </div>
      </EditableSettings>
    </>
  )
}
