import { Input } from "components/FormComponents";
import { GoogleMapWidget } from "components/GoogleMapWidget/GoogleMapWidget";
export const GoogleMapSettings = ({ register, name, watch, errors }) => {
  return (
    <>
      <Input
        register={register}
        name={`${name}.params.text`}
        className="margin-top--large"
        placeholder="Enter location name..."
        validators={{ required: true }}
        error={errors}
      />
      <GoogleMapWidget width={500} height={360} search={watch(`${name}.params.text`)} />
    </>
  );
};
