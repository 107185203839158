import { EditableSettings } from '../../../../../../components/EditableSettings/EditableSettings';
import { Input, Textarea, InputGroup, InlineOptionsSelector, ImagePicker, LinkVideoInput } from '../../../../../../components/FormComponents';
import { Controller, useWatch } from 'react-hook-form';
import { MediaType } from 'constants/media-type-enum';
import { useEffect } from 'react';
import { VimeoSettings } from 'views/BlockBuilderScreen/tabs/Blocks/BlockBuilder/components/InformationBuilder/components/InfoItemConfig/settings_components/VimeoSettings/VimeoSettings';
import { LoomSettings } from 'views/BlockBuilderScreen/tabs/Blocks/BlockBuilder/components/InformationBuilder/components/InfoItemConfig/settings_components/LoomSettings/LoomSettings';
import { VidyardSettings } from 'views/BlockBuilderScreen/tabs/Blocks/BlockBuilder/components/InformationBuilder/components/InfoItemConfig/settings_components/VidyardSettings/VidyardSettings';

export const WelcomeScreenSettings = ({
  control,
  register,
  watch,
  errors,
  setValue,
  unregister,
}) => {

  const mediaType = useWatch({ control, name: 'journeyCustomizationAttributes.mediaType' });
  const videoSrc = useWatch({ control, name: 'journeyCustomizationAttributes.videoSrc' });
  const useMedia = watch('journeyCustomizationAttributes.useMedia')

  useEffect(()=>{
    if (useMedia && !mediaType){
      setValue('journeyCustomizationAttributes.mediaType','image')
    }
  },[useMedia])

  useEffect(()=>{
    if (!mediaType || mediaType==='image'){
      unregister('journeyCustomizationAttributes.videoSrc')
    }
  },[mediaType])
  return (
    <>
      <EditableSettings title='Customize Welcome Screen' open={watch('journeyCustomizationAttributes.useWelcomeScreenHeadings')} className='w-100 margin-top' toggleable register={register} name='journeyCustomizationAttributes.useWelcomeScreenHeadings'>
        <InputGroup title='Heading'>
          <Textarea
            name='journeyCustomizationAttributes.welcomeScreenHeading'
            placeholder='Kia ora, {{stakeholder_name}}! Welcome to {{company_name}}'
            register={register}
            inputProps={{ rows: '2' }}
            validators={{required: watch('journeyCustomizationAttributes.useWelcomeScreenHeadings')}}
            error={errors?.journeyCustomizationAttributes?.welcomeScreenHeading?.type}
            noErrorMessage
          />
        </InputGroup>
        <InputGroup title='Sub-heading'>
          <Textarea
            name='journeyCustomizationAttributes.welcomeScreenSubHeading'
            placeholder='Enter sub-heading'
            register={register}
            inputProps={{ rows: '3' }}
            validators={{required: watch('journeyCustomizationAttributes.useWelcomeScreenHeadings')}}
            error={errors?.journeyCustomizationAttributes?.welcomeScreenSubHeading?.type}
            noErrorMessage
          />
        </InputGroup>
        <EditableSettings toggleable title='Embed a video or add a cover image' open={watch('journeyCustomizationAttributes.useMedia')} register={register} name='journeyCustomizationAttributes.useMedia'>
          <Controller
            control={control}
            name='journeyCustomizationAttributes.mediaType'
            render={({ field: { onChange, value } }) => (
              <InlineOptionsSelector value={value} onChange={onChange}>
                <InlineOptionsSelector.Option value={MediaType.VidyardVideo}>Embed Vidyard </InlineOptionsSelector.Option>
                <InlineOptionsSelector.Option value={MediaType.LoomVideo}>Embed Loom </InlineOptionsSelector.Option>
                <InlineOptionsSelector.Option value={MediaType.VimeoVideo}>Embed Vimeo </InlineOptionsSelector.Option>
                <InlineOptionsSelector.Option value={MediaType.Video}>Embed Youtube </InlineOptionsSelector.Option>
                <InlineOptionsSelector.Option value={MediaType.Image}>Add Image Banner</InlineOptionsSelector.Option>
              </InlineOptionsSelector>
            )}
          />
          <div className='margin-top'>
            {mediaType === MediaType.Video && useMedia &&
              <LinkVideoInput register={register} name='journeyCustomizationAttributes.videoSrc' value={videoSrc} />
            }
            {mediaType === MediaType.Image && useMedia &&
              <Controller
                control={control}
                name='journeyCustomizationAttributes.imageId'
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <ImagePicker value={value} error={errors?.journeyCustomizationAttributes?.imageId?.type} onChange={onChange} />
                )}
              />
            }
            {mediaType === MediaType.VimeoVideo && useMedia &&
              <VimeoSettings register={register} name='journeyCustomizationAttributes.videoSrc' watch={watch} error={errors?.journeyCustomizationAttributes?.videoSrc?.type} />
            }
            {mediaType === MediaType.LoomVideo && useMedia &&
              <LoomSettings register={register} name='journeyCustomizationAttributes.videoSrc' watch={watch} error={errors?.journeyCustomizationAttributes?.videoSrc?.type} />
            }
            {mediaType === MediaType.VidyardVideo && useMedia &&
              <VidyardSettings register={register} name='journeyCustomizationAttributes.videoSrc' watch={watch} error={errors?.journeyCustomizationAttributes?.videoSrc?.type}  />
            }
            
            
          </div>
        </EditableSettings>
      </EditableSettings>
      <EditableSettings  title='Customize Blocks Screen' open={watch('journeyCustomizationAttributes.useDashboardHeadings')} className='w-100 margin-top margin-bottom' toggleable register={register} name='journeyCustomizationAttributes.useDashboardHeadings'>
        <InputGroup title='Heading'>
          <Input
            name='journeyCustomizationAttributes.dashboardHeading'
            placeholder='How this works and what you will need 🤔'
            register={register}
            error={errors?.journeyCustomizationAttributes?.dashboardHeading?.type}
            validators={{required: watch('journeyCustomizationAttributes.useDashboardHeadings')}}
          />
        </InputGroup>
        <InputGroup title='Sub-heading'>
          <Textarea
            name='journeyCustomizationAttributes.dashboardSubHeading'
            placeholder='You can choose which block you want to complete from the ones available and you can save at any time!'
            register={register}
            inputProps={{ rows: '3' }}
            error={errors?.journeyCustomizationAttributes?.dashboardSubHeading?.type}
            validators={{required: watch('journeyCustomizationAttributes.useDashboardHeadings')}}
          />
        </InputGroup>
      </EditableSettings>
    </>
  )
}
