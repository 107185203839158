import PropTypes from 'prop-types';
import styles from './Loading.module.scss';
import cn from 'classnames';

export const Loading = ({
  visible
}) => {

  if(!visible) return null

  return (
    <div className={cn(styles.root, {[styles.rootVisible]: visible})}>
      <img className={styles.logo} src="/assets/images/knowmy/knowmy_logo.svg" alt='KnowMy Logo'/>
    </div>
  )
}

Loading.propTypes = {
  visible: PropTypes.bool
};

Loading.defaultProps = {
  visible: true
};