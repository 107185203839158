import styles from './RightBar.module.scss';
import cn from 'classnames'
import FeatherIcon from 'feather-icons-react';
import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'components/Tabs/Tabs';
import { List } from './Components/List/List';

export const RightBar = ({

  onStateChange = () => {}
}) => {

  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => onStateChange(hidden), [hidden]);

  return (
    <aside className={cn(styles.root, { [styles.rootHidden]: hidden })}>
      <div>
        <div className={cn(styles.header, { [styles.headerHidden]: hidden })}>
          <div className={cn(styles.titleIcon,  { [styles.headerHidden]: hidden })}>
            <FeatherIcon icon='clipboard'/>
            <div className={cn('title-5',styles.title)}>My Tasks</div>
          </div>
          {/* <FeatherIcon icon='chevron-right' className={styles.toggleButton} onClick={() => setHidden(!hidden)} /> */}
        </div>
        <Tabs navItemStyles={styles.navItemStyles} contentStyles={styles.tabsContent} activeTabColour={styles.activeTabColor} defaultActiveKey="upcoming">
          <Tab eventKey="upcoming" title="Upcoming">
              <div className={cn(styles.container,'mt-3')}>
                <List/>
                <List/>
                <List/>
              </div>
          </Tab>
          <Tab eventKey="overdue" title="Overdue">
            <List/>
            <List/>
          </Tab>
        </Tabs>
      </div>
    </aside>
  )
}
