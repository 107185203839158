import { Link } from "react-router-dom"
import styles from "./NotFoundScreen.module.scss"
import cn from "classnames";


export const NotFoundScreen = () => {
  return (
    <div className={styles.notFound}>
      <h1>404</h1>
      <h2>Page Not Found</h2>
        
      <Link to={'/'} className={cn(styles.addBtn, "button")}>Home Page</Link>
    </div>
  )
}