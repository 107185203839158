import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { loader } from "graphql.macro";
import { getFileMetadata } from "utils/active-storage-utils";
import { useMutation, useLazyQuery } from "@apollo/client";
import { directUpload } from "utils/direct-upload";
import { Button } from "components/FormComponents";
import { Loading } from "components/Loading/Loading";
import styles from "./CaptureMobileCamera.module.scss";
import { jpegImageToBlob } from "utils/helpers";

const SIGN_UPLOAD_MUTATION = loader("./../../../../../../graphql/mutations/sign_upload.graphql");
const CREATE_UPLOAD_MUTATION = loader("./../../../../../../graphql/mutations/create_upload.graphql");
const DELETE_UPLOAD_MUTATION = loader("./../../../../../../graphql/mutations/delete_upload.graphql");
const UPLOAD_QUERY = loader("./../../../../../../graphql/queries/upload.graphql");

export const CaptureMobileCamera = ({ onChange, value, stakeholderName, disabled, chooseMobile, setChooseMobile, index, stakeholderJourneyToken }) => {
  const [reset, setReset] = useState(null);
  const [valid, setValid] = useState(true);

  const webcamRef = React.useRef(reset);
  const [picture, setPicture] = useState(false);
  const mediaRecorderRef = React.useRef(reset);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const isInitialMount = React.useRef(valid);
  const [fileName, setFileName] = useState();
  const [videoRecording, setVideoRecording] = useState();
  const [loading, setLoading] = useState(false);

  const [signUpload] = useMutation(SIGN_UPLOAD_MUTATION);
  const [createUpload] = useMutation(CREATE_UPLOAD_MUTATION);
  const [deleteUpload] = useMutation(DELETE_UPLOAD_MUTATION);

  const [fetchUpload, { loading: fetching }] = useLazyQuery(UPLOAD_QUERY, {
    onCompleted: (data) => setFileName(data.upload.filename),
  });
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (!capturing) {
        prepareVideo();
      }
    }
  }, [recordedChunks, isInitialMount]);

  useEffect(() => {
    if (value) fetchUpload({ variables: { id: value, token: stakeholderJourneyToken } });
    else setFileName(null);
  }, [value]);

  const capturePicture = React.useCallback(async () => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    const blob = jpegImageToBlob(pictureSrc.replace("data:image/jpeg;base64,", ""));
    var fileMetadata = await getFileMetadata(blob);
    fileMetadata["filename"] = `${stakeholderName.replace(" ", "_")}.jpg`;
    await handleUpload(fileMetadata, blob);
  });

  const onRemove = () => {
    if (!value) return;
    setLoading(true);
    deleteUpload({ variables: { id: value, token: stakeholderJourneyToken } });
    setFileName(null);
    onChange(null);
    setLoading(false);
  };

  const onCancel = async () => {
    await onRemove();
    setPicture(false);
    setReset(null);
    setValid(true);
    setFileName();
    setRecordedChunks([]);
    setCapturing(false);
    setVideoRecording();
  };

  const handleUpload = async (fileMetadata, blob) => {
    setLoading(true);
    const signedResponse = await signUpload({ variables: { signUploadAttributes: fileMetadata, token: stakeholderJourneyToken } });
    const { url, headers, signedBlobId } = signedResponse.data.signUpload;
    await directUpload(url, JSON.parse(headers), blob);
    const uploadResponse = await createUpload({ variables: { blobId: signedBlobId, token: stakeholderJourneyToken } });
    const { id, filename } = uploadResponse.data.createUpload;
    setFileName(filename);
    onChange(id);
    setLoading(false);
  };

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const prepareVideo = React.useCallback(async () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      var fileMetadata = await getFileMetadata(blob);
      fileMetadata["filename"] = `${stakeholderName.replace(" ", "_")}.mp4`;
      const urlObj = URL.createObjectURL(blob);
      setVideoRecording(urlObj);
      await handleUpload(fileMetadata, blob);
    }
  }, [recordedChunks]);

  if (loading) return <Loading visible={loading} />;

  return (
    <>
      {!disabled &&
        <div className={styles.useCamera}>
          <Button
            type="button"
            onClick={() => {
              setChooseMobile((prevArray) => {
                const newArray = [...prevArray];
                newArray[index] = !chooseMobile[index];
                return newArray;
              });
            }}
          >
            {chooseMobile[index] ? "Upload File" : "Use Camera"}
          </Button>
        </div>
      }
      {!disabled && chooseMobile[index] && (
        <div className="d-flex flex-column align-items-center">
          {!videoRecording && !picture && (
            <div className={styles.button}>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{facingMode:"environment"}}
                style={{
                  textAlign: "center",
                  zindex: 8,
                  right: 0,
                  height: "60%",
                  width: "100%",
                  objectFit: "fill",
                }}
              />
            </div>
          )}

          {!videoRecording ? (
            <>
              {picture && <img className={styles.button} src={picture} />}
              {!capturing && !picture && (
                <Button type="button" className={styles.button} onClick={handleStartCaptureClick}>
                  Capture Video
                </Button>
              )}
            </>
          ) : (
            <video height="60%" width="100%" controls src={videoRecording}></video>
          )}

          {capturing ? (
            <Button type="button" className={styles.button} onClick={handleStopCaptureClick}>
              Stop Capture
            </Button>
          ) : (
            <>
              {!videoRecording && !picture && (
                <Button type="button" className={styles.button} onClick={() => capturePicture()}>
                  Capture Image
                </Button>
              )}
            </>
          )}

          <Button type="button" className={styles.button} onClick={async () => onCancel()}>
            Reset
          </Button>
        </div>
      )}
    </>
  );
};
