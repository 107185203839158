import PropTypes from 'prop-types';
import styles from './AppLayout.module.scss';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './components/Sidebar/Sidebar';
import { Loading } from './../../components/Loading/Loading';
import cn from 'classnames';
import { useState, createContext, useContext } from 'react';
import { AlertMessage, MessageType } from './components/AlertMessage/AlertMessage';
import { ErrorAlert } from 'components/ErrorAlert/ErrorAlert';
import { ImpersonationAlert } from './components/ImpersonationAlert/ImpersonationAlert';

const AppContext = createContext(null);

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) throw new Error(`App compound components cannot be rendered outside the AppLayout component`);
  return context
}

export const AppLayout = () => {

  const [loading, setLoading] = useState(false);
  const [sidebarHidden, setSidebarHidden] = useState(true);

  const [alertMessage, setAlertMessage] = useState();
  const [alertMessageType, setAlertMessageType] = useState(MessageType.Success);

  const [showErrorAlert, setShowErrorAlert] = useState([null,false]);

  const alert = (message, type = MessageType.Success) => {
    setAlertMessage(message);
    setAlertMessageType(type);
  }

  const triggerUnexpectedError = (error) => {
    setShowErrorAlert([error || null, true]);
  }

  const contextValue = { setLoading, alert, triggerUnexpectedError };

  return (
    <AppContext.Provider value={contextValue}>
      <div className={styles.root}>
        <ImpersonationAlert/>     
        <AlertMessage visible={!!alertMessage} type={alertMessageType} message={alertMessage} onClose={() => alert(null, null)} />
        <ErrorAlert errorMessage={showErrorAlert[0]} visible={showErrorAlert[1]} onClose={() => setShowErrorAlert(false)} />
        <Loading visible={loading} />
        <Sidebar onStateChange={setSidebarHidden} />
        <div className={cn(styles.content, {[styles.contentExpanded]: sidebarHidden})}><Outlet /></div>
      </div>
    </AppContext.Provider>
  )
}

AppLayout.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
};

AppLayout.defaultProps = {
  loading: false,
};


const Header = ({ children, className, withDivider=false }) => (
  <div className={cn(styles.header, { [styles.headerWithDivider]: withDivider, [className]: className })}>
    {children}
  </div>
)
AppLayout.Header = Header;


const Title = ({ children }) => (
  <h1 className={cn(styles.title, 'title-3')}>{children}</h1>
)
AppLayout.Title = Title;


const Subtitle = ({ children }) => (
  <p className={styles.subtitle}>{children}</p>
)
AppLayout.Subtitle = Subtitle;


const Body = ({ children, className, withMargin=false }) => (
  <div className={cn(styles.body, { [styles.bodyWithMargin]: withMargin, [className]: className })}>{children}</div>
)
AppLayout.Body = Body;
