import { EnsureStakeholderLayout, useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { stateExternalStakeholder } from "graphql/state";
import { useReactiveVar } from "@apollo/client";
import { OverviewTab } from "./Tabs/OverviewTab/OverviewTab";
import { HistoryTab } from "./Tabs/HistoryTab/HistoryTab";
import { Tab, Tabs } from "components/Tabs/Tabs";
import { useEffect, useState } from "react";
import styles from './EnsureStakeholderDashboardScreen.module.scss';

export const EnsureStakeholderDashboardScreen = () =>{
    const [currentStakeholder, setCurrentStakeholder] = useState([])
   
    const stakeholder = useReactiveVar(stateExternalStakeholder);

    useEffect(()=>{
        setCurrentStakeholder(stakeholder)
    },[stakeholder])

    return(
        <>
            <EnsureStakeholderLayout.Header>
                <EnsureStakeholderLayout.Title>Dashboard</EnsureStakeholderLayout.Title>
                <EnsureStakeholderLayout.Subtitle>Below is your summary of Journeys and Blocks.</EnsureStakeholderLayout.Subtitle>
            </EnsureStakeholderLayout.Header>
            <EnsureStakeholderLayout.Body>
                <Tabs maxWidthContentClassName={styles.maxWidthContent} contentStyles={styles.tabsContent} activeTabColour={styles.activeTabColor} navItemStyles={styles.tab} defaultActiveKey="overview">
                    <Tab eventKey="overview" title="Overview">
                        <div className={styles.container}>
                            <OverviewTab currentStakeholder={currentStakeholder}/>
                        </div>
                    </Tab>
                    <Tab eventKey="history" title="History">
                        <HistoryTab currentStakeholder={currentStakeholder}/>
                    </Tab>
                </Tabs>
            </EnsureStakeholderLayout.Body>
        </>
    )
}