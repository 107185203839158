import { LinkVideoInput } from 'components/FormComponents';
import { ToggleSwitch } from 'components/FormComponents';

export const VideoSettings = ({ register, name, watch, errors }) => {
  return (
    <>
      <ToggleSwitch className='margin-top--large' register={register} name={`${name}.useTimeTracking`} label='Track video watch time' />
      <hr />
      <LinkVideoInput 
        register={register}
        className='margin-top--large'
        name={`${name}.videoSrc`}
        value={watch(`${name}.videoSrc`)}
        validators={{ required: true }}
        error={errors?.options?.videoSrc?.type}
      />
    </>
  )
}