import { createContext, useMemo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MaxWidthContent } from '../MaxWidthContent/MaxWidthContent';
import styles from './Tabs.module.scss';
import cn from 'classnames';

const TabsContext = createContext(null);

export const Tabs = ({
  defaultActiveKey,
  className,
  children,
  contentStyles,
  navItemStyles,
  activeTabColour,
  maxWidthContentClassName,
  navTabsClassName
}) => {

  const [tabs, setTabs] = useState({});
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (defaultActiveKey) setActiveKey(defaultActiveKey)
  }, [defaultActiveKey]);

  const addTab = (key, title) => {
    setTabs(curr => ({...curr, [key]: title }))
  }

  const contextValue = useMemo(() => ({ activeKey, addTab }), [activeKey])

  return (
    <TabsContext.Provider value={contextValue}>
      <div className={className}>
        <MaxWidthContent wrapperClassName={cn(styles.navTabsWrapper, {[navTabsClassName]: navTabsClassName} )} contentClassName={maxWidthContentClassName}>
          <ul className={styles.navTabs}>
            {Object.keys(tabs).map(tabKey => (
              <li key={tabKey} className={cn(styles.navItem, navItemStyles, { [cn(styles.navItemActive, activeTabColour)]: activeKey === tabKey })} onClick={() => setActiveKey(tabKey)}>
                {tabs[tabKey]}
              </li>
            ))}
          </ul>
        </MaxWidthContent>
        <MaxWidthContent className={contentStyles ? contentStyles : styles.tabContent} contentClassName={maxWidthContentClassName}>
          {children}
        </MaxWidthContent>
      </div>
    </TabsContext.Provider>
  )
}

Tabs.propTypes = {
  defaultActiveKey: PropTypes.string,
  className: PropTypes.string
};


const useTabsContext = () => {
  const context = useContext(TabsContext)
  if (!context)
    throw new Error(`Tab compound components cannot be rendered outside the Tabs component`)

  return context
}


export const Tab = ({ eventKey, title, children }) => {
  const { activeKey, addTab } = useTabsContext()

  useEffect(() => { addTab(eventKey, title) }, [title, eventKey])

  if(activeKey !== eventKey) return null;

  return (
    children
  )
}

Tab.propTypes = {
  eventKey: PropTypes.string,
  title: PropTypes.string
};
