import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

const httpLink = process.env.REACT_APP_ENV === 'development' ?
                    createHttpLink({ uri: 'http://localhost:5000/graphql' }) :
                    createHttpLink({
                      uri: '/graphql',
                      credentials: 'same-origin'
                    })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});


export default apolloClient;
