import React from "react";
import { Input } from "components/FormComponents";
import cn from "classnames";
import styles from "./UrlSettings.module.scss";

export const UrlSettings = ({ register, name, watch, error }) => {
  return (
    <div className={cn(styles.root)}>
      <Input
        register={register}
        name={`${name}.params.text`}
        placeholder="A valid URL to embed..."
        validators={{ required: true }}
        error={error}
      />
      <div className={cn(styles.url)}>
        <iframe
          width="853"
          height="480"
          src={watch(`${name}.params.text`)}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded URL"
          style={{ backgroundColor: "#e5e5e5" }}
        />
      </div>
    </div>
  );
};
