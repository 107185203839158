import { useRef } from 'react';
import { useClickOutside } from '../../hooks/click-outside';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './ModalForm.module.scss';

export const ModalForm = ({
  visible,
  onClose,
  children,
  rootClasses,
  headerClasses,
  customStyles = {
    backgroundColor: '#FFF',
    titleColor: '#333333',
  }
}) => {
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, onClose);

  return (
    <div className={cn(styles.root, {[rootClasses]: rootClasses, [styles.rootVisible]: visible })}>
      <div className={cn(styles.card, 'card', 'card-with-shadow')} ref={wrapperRef} style={{ backgroundColor: customStyles.backgroundColor }}>
        <div className={cn(styles.header, {[headerClasses]: headerClasses,})} >
          {children}
        </div>
      </div>
    </div>
  )
}

ModalForm.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  rootClasses: PropTypes.string,
  headerClasses: PropTypes.string,
};
