import { Button, Input, InputGroup } from "components/FormComponents";
import { useForm, Controller } from "react-hook-form";
import styles from './UpdateUserForm.module.scss';
import cn from 'classnames';
import { ModalForm } from "components/ModalForm/ModalForm";
import { ReactSelect } from "components/FormComponents/ReactSelect/ReactSelect";

export const UpdateUserForm = ({ roles, companies, selectedUser, onUserUpdate, onClose, visible }) => {

  const { register, errors, control, reset, handleSubmit } = useForm({
    defaultValues: {
      id:selectedUser.id,
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      contactNumber: selectedUser.contactNumber,
      email: selectedUser.email,
      companyId: selectedUser?.company?.id
    }
  });

  const close = () => {
    onClose()
    reset(selectedUser)
  }

  const onSubmit = (userAttributes) => {
    onUserUpdate(userAttributes)
    onClose()
  }

  return (
    <ModalForm visible={visible} onClose={() => { onClose() }} headerClasses={styles.modalHeader}>
      <div className={cn(styles.header)}>
        <h1 className={cn(styles.title, 'title-3')}>Edit Company User</h1>
        <p>Edit the details of company user below and click the Save button.</p>
      </div>
      <form className={cn(styles.root)} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.content}>
          <div className={styles.inputContainer}>
            <InputGroup className={styles.input} title='First Name'>
              <Input
                name='firstName'
                type='text'
                placeholder='Enter first name'
                validators={{ required: true }}
                register={register}
                error={errors?.firstName?.type}
                inputProps={{ tabIndex: 1 }}
              />
            </InputGroup>
            <InputGroup className={styles.input} title='Last Name'>
              <Input
                name='lastName'
                type='text'
                placeholder='Enter last name'
                register={register}
                error={errors?.lastName?.type}
                inputProps={{ tabIndex: 2 }}
              />
            </InputGroup>
          </div>
          <div className={styles.inputContainer}>
            <InputGroup className={styles.input} title='Email'>
              <Input
                name='email'
                type='text'
                placeholder='Enter email address'
                register={register}
                validators={{ required: true }}
                error={errors?.email?.type}
                inputProps={{ tabIndex: 3, readOnly: true }}
              />
            </InputGroup>
            <InputGroup className={styles.input} title='Phone Number'>
              <Input
                name='contactNumber'
                type='text'
                placeholder='Enter phone number'
                register={register}
                validators={{ required: true }}
                error={errors?.contactNumber?.type}
                inputProps={{ tabIndex: 5 }}
              />
            </InputGroup>
          </div>
          {companies && <div className={styles.inputContainer}>
            <InputGroup className={styles.input} title='Company'>
              <Controller
                name={'companyId'}
                control={control}
                render={({ field: { onChange, value } }) =>
                  <ReactSelect
                    isSearchable
                    options={companies}
                    value={value}
                    onChange={onChange}
                  />
                }
              />
            </InputGroup>
          </div>}
        </div>
        <div className={styles.footer}>
          <Button type='secondary' onClick={close}>Cancel</Button>
          <Button submit >Save</Button>
        </div>
      </form>
    </ModalForm>
  )
}
