import styles from './CustomButton.module.scss';
import cn from 'classnames';

export const CustomButton = ({
  brand,
  className,
  children,
  submit,
  reverse  = false,
  disabled = false,
  onClick  = () => {}
}) => {

  const getStyle = () => {
    if (disabled)      return { backgroundColor: '#e5e5e5', color: '#666666'}
    else if (reverse)  return { backgroundColor: brand?.buttonTextColor,       color: brand?.buttonBackgroundColor }
    else               return { backgroundColor: brand?.buttonBackgroundColor, color: brand?.buttonTextColor       }
  }

  return (
    <button
      className={cn(styles.root, { [styles.rootDisabled]: disabled, [className]: className })}
      style={getStyle()}
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
