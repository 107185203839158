import styles from './InlineOptionsSelector.module.scss';
import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const InlineOptionsSelectorContext = createContext(null);

const useInlineOptionsSelectorContext = () => {
  const context = useContext(InlineOptionsSelectorContext)
  if (!context) throw new Error(`InlineOptionsSelector compound components cannot be rendered outside the InlineOptionsSelector component`)
  return context
}

export const InlineOptionsSelector = ({
  value,
  children,
  onChange,
  selectedColor
}) => {

  const contextValue = useMemo(() => ({ selected: value, select: onChange, selectedColor: selectedColor }), [value])

  return (
    <InlineOptionsSelectorContext.Provider value={contextValue}>
      <div className={styles.root}>
        {children}
      </div>
    </InlineOptionsSelectorContext.Provider>
  )
}

InlineOptionsSelector.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func
};

const Option = ({
  value,
  activeColor = '#4932d0',
  children
}) => {
  const { selected, select, selectedColor } = useInlineOptionsSelectorContext();

  return (
    <span
      className={cn(styles.option, { [styles.optionActive]: selected === value })}
      style={selected === value ? { backgroundColor: selectedColor ? selectedColor : activeColor } : {}}
      onClick={() => select(value)}
    >
      {children}
    </span>
  )
}

InlineOptionsSelector.Option = Option
