import { createContext, useMemo, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './List.module.scss';
import { Button } from '../FormComponents/Button/Button';
import { SearchInput } from '../SearchInput/SearchInput';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import { Checkbox } from 'components/FormComponents/Checkbox/Checkbox';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';

const ListContext = createContext(null);

const useListContext = () => {
  const context = useContext(ListContext)
  if (!context)
    throw new Error(`Lists compound components cannot be rendered outside the Lists component`)
  
  return context
}

export const List = ({ 
  title,
  subtitle,
  children,
  className,
  onNew,
  tip,
  selectedIds,
  selectedIdsError,
  setErrorModal,
  handleSelectedDeletion
}) => {

  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState(false)

  const contextValue = useMemo(() => ({ searchQuery: searchQuery.toLowerCase() }), [searchQuery])

  return (
    <ListContext.Provider value={contextValue}>
      {selectedIds && 
      <ConfirmationModal
        visible={deleteModal}
        title={ `Are you sure you want to delete selected ${title} ?` }
        subtitle={`These Selected ${title?.toUpperCase()} will be permanently deleted. This data cannot be recovered`}
        confirmationKey={`Delete Selected ${title}`}
        confirmationIcon="trash-2"
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirmation={()=>{
          handleSelectedDeletion()
          setDeleteModal(false)
        }}
        buttonTitle={`Delete Selected ${title}`}
      />
     }
      <div className={cn(styles.list, 'card', 'card-with-border', { [className]: className })}>
        <div className={cn(styles.content, 'card_content', 'd-flex', 'justify-content-start')}>
          <div className={styles.header}>
            <h1 className='title-2'>{title}</h1>
            {!!onNew &&
              <Button placement={'right'} tip={tip} onClick={() => onNew(true)}><FeatherIcon icon='plus' size={18} className='me-2' />Add</Button>
            }
          </div>
          <p className={cn(styles.subtitle, 't-subtitle mt-3')}>{subtitle}</p>
          {!!selectedIds?.length && 
            <div className={styles.button}>
              <Button className={styles.contents} icon={"trash"} type="delete" onClick={()=>{selectedIdsError && setErrorModal && selectedIdsError() ? setErrorModal(true) :  setDeleteModal(true)}}>
                {selectedIds?.length}
              </Button>
            </div>
          }
          <SearchInput className={`w-100 ${selectedIds?.length ? 'mt-4' : 'mt-5'}`} placeholder='Search...' onChange={setSearchQuery}/>
          <div className={styles.items}>
            {children}
          </div>
        </div>
      </div>
    </ListContext.Provider>
  )
}

List.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onNew: PropTypes.func
};


const CategoryContext = createContext(null);

const useCategoryContext = () => {
  const context = useContext(CategoryContext)
  if (!context)
    throw new Error(`Category compound components cannot be rendered outside the Category component`)
  
  return context
}

export const Category = ({ title, withCount=false, children, isTitleLarge }) => {
  const [childrenCount, setChildrenCount] = useState(0);

  const contextValue = ({
    withCount,
    incrementCategoryCount: () => setChildrenCount(curr => curr + 1),
    decreaseCategoryCount: () => setChildrenCount(curr => curr - 1),
  })

  return (
    <CategoryContext.Provider value={contextValue}>
      {title && <h3 className={isTitleLarge ? styles.largeItemHeader :styles.itemHeader}>{title} {withCount && `(${childrenCount})`}</h3>}
      {children}
    </CategoryContext.Provider>
  )
  
}

Category.propTypes = {
  title: PropTypes.string,
  withCount: PropTypes.bool
};
List.Category = Category;


export const Item = ({ title, value, onClick, displayCheckbox, selectedIds, setSelectedIds }) => {
  const { searchQuery } = useListContext();
  const { withCount, incrementCategoryCount, decreaseCategoryCount } = useCategoryContext();

  const [show, setShow] = useState(true);

  useEffect(() => setShow(title.toLowerCase().includes(searchQuery)), [searchQuery, title]);

  useEffect(() => {
    if(!withCount) return;
    
    if(show) incrementCategoryCount();
    return () => { if(show) decreaseCategoryCount() }
  }, [show, withCount])

  const handleCheckboxClick=()=>{
    selectedIds.includes(value) ? setSelectedIds((selectedIds) =>selectedIds.filter((id) => id !== value)) : setSelectedIds([...selectedIds,value]) 
  }

  if(show) return(
    <div className={cn(styles.item, 't-body')}>
      {displayCheckbox &&  <Checkbox className={cn(styles.checkBox)} onClick={handleCheckboxClick} checked={selectedIds?.includes(value)}/> }
      <li className={cn(styles.li)} onClick={() => onClick(value)} >{title}</li>
    </div>
  )
  else return null
}

Item.propTypes = {
  onClick: PropTypes.func
};
List.Item = Item;
