import { Button, InputGroup, Select } from "components/FormComponents"
import { Modal } from "components/Modal/Modal"
import { useForm } from "react-hook-form";

export const JourneyConfig = ({
  visible,
  brands,
  journey,
  onClose = () => {},
  onSubmit = () => {}
}) => {

  const { watch, register, handleSubmit, formState: { errors } } = useForm();
  const brandId = watch('brandId')

  return (
    <Modal title={journey.name} visible={visible} onClose={onClose}>
      <form className='margin-top--large' onSubmit={handleSubmit(onSubmit)}>
        <InputGroup title='Brand'>
          <Select name='brandId' register={register}>
            {brands?.map((brand, index) => (
              <Select.Item key={brand.id} active={journey?.brand?.id === brand.id} value={brand.id}>{brand.brandName}</Select.Item>
            ))}
          </Select>
        </InputGroup>
        <div className='d-flex justify-content-between w-100 margin-top--large'>
          <Button onClick={onClose} type='secondary'>Cancel</Button>
          <Button disabled={journey?.incomplete} submit>Save</Button>
        </div>
      </form>
    </Modal>
  )
}