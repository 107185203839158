import { useEffect, useState } from 'react';
import { useStakeholderJourneyLayoutContext } from 'templates/StakeholderJourneyLayout/StakeholderJourneyLayout';
import { loader } from 'graphql.macro';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CustomButton } from 'views/StakeholderJourneyScreens/components/CustomButton/CustomButton';
import { useHelloSignEmbedded } from 'hooks/hello-sign-embedded';
import styles from './DocumentSigningForm.module.scss';
import { Badge } from 'react-bootstrap';
import cn from 'classnames';
import img from 'assets/images/completeShield.svg';
import { Player } from '@lottiefiles/react-lottie-player';
import gif from 'assets/images/documentGIF.json';  
import download from 'downloadjs';
import FeatherIcon from 'feather-icons-react';

const GET_SIGNING_URL_MUTATION = loader("graphql/mutations/get_signing_url.graphql");
const TEMPLATE_URI_QUERY = loader("graphql/queries/hello_sign_template_uri.graphql")

export const DocumentSigningForm = ({
  required,
  block,
  result,
  stakeholderTag,
  blockResultId,
  onCompleted = () => {},
  onContinue = () => {}
}) => {

  const { setLoading, stakeholderJourney, token } = useStakeholderJourneyLayoutContext();

  const [documentSigner, setDocumentSigner] = useState();

  const [signingUrl, setSigningUrl] = useState();

  const [ getSigningUrl, { loading } ] = useMutation(GET_SIGNING_URL_MUTATION, {
    onCompleted: data => setSigningUrl(data.getSigningUrl.signingUrl)
  });

  const [fetchTemplateUri] = useLazyQuery(TEMPLATE_URI_QUERY)

  // Fetch signing url
  useEffect(() => {
    if(!result) return;

    const localDocumentSigner = result.documentSigners?.find(documentSigner => documentSigner.stakeholder.stakeholderTag.id === stakeholderTag.id)
    setDocumentSigner(localDocumentSigner);

    if (!localDocumentSigner) return;

    getSigningUrl({ variables: { documentSignerId: localDocumentSigner.id, token: token }});
  }, [result]);

  // Set loading
  useEffect(() => setLoading(loading), [loading])

  // Handle cancel
  const onCancel = () => {
    if (!documentSigner) return;

    getSigningUrl({ variables: { documentSignerId: documentSigner.id, token: token}});
  }

  // HelloSign embedded hook
  const { openEmbedded } = useHelloSignEmbedded({ onSign: onCompleted, onCancel });

  const downloadFile = () => {
    if(!result?.resultUrl) { return }
    const journeyName = stakeholderJourney?.name?.replace(/\s+/g, '-')
    const name = stakeholderJourney?.externalStakeholder?.name?.replace(/\s+/g, '-')
    download(result?.resultUrl, `${name}-${journeyName}`, "application/pdf")
  }

  const handlePrint = async () => {
    try {
      setLoading(true)
      const {data} = await fetchTemplateUri({ variables: {token: token, blockResultId: blockResultId}})
      const pdfUrl = data.helloSignTemplateUri
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const printWindow = window.open(url);
      printWindow.onload = () => {
        printWindow.print();
        URL.revokeObjectURL(url);
      };
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };


  return (
    <div className={cn(styles.root, 'card card-with-border card-scrollable')}>
      <div className={cn(styles.blockInfo)}>
        <div className={cn(styles.top)}>
          <Badge className={cn(styles.badge, { ['badge--default']: !required, ['badge--info']: required })}>
            {required ? 'Required' : 'Nice to do'}
          </Badge>
          <FeatherIcon icon='printer' className={cn(styles.printer)} onClick={handlePrint}/>
        </div>
        <h1 className={cn(styles.title, 'title-2')}>{block.heading}</h1>
      </div>
      <div className={cn(styles.document, 'd-flex flex-column justify-content-center align-items-center')}>
        <p className='t-body margin-bottom--large'>{block?.documentTemplate.prompt}</p>
        {result?.status !== 'completed' ? (
            <>
              <Player
                autoplay
                loop
                src={gif}
                style={{ height: '300px', width: '300px' }}
              />
              <CustomButton className={styles.button} brand={stakeholderJourney?.brand} disabled={!signingUrl} onClick={() => openEmbedded(signingUrl)}>Open File</CustomButton>
            </>
            ) : (
            <div className={cn('d-flex flex-column align-items-center justify-content-center')}>
              <div className={cn(styles.shieldDiv, 'd-flex flex-column align-items-center justify-content-center card card-with-border')}>
                <img className={styles.img} src={img} alt='Email sent' />
                <p>Thank you, your document has been successfully signed.</p>
              </div>
              <CustomButton className={cn(styles.next, 'mt-4')} onClick={downloadFile}>Download</CustomButton>
              <CustomButton className={cn(styles.next, 'mt-4')} onClick={onContinue}>Continue</CustomButton>
            </div>
          )
        }
        </div>
    </div>
  )
}
