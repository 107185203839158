import { Tabs, Tab } from "components/Tabs/Tabs";
import { PasswordTab } from "./Tabs/PasswordTab/PasswordTab";
import { EnsureStakeholderLayout, useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import styles from './EnsureStakeholderAccountScreen.module.scss';

export const EnsureStakeholderAccountScreen = () => {
  const { alert, triggerUnexpectedError, setLoading } = useEnsureStakeholderLayoutContext();

  return (
    <>
      <EnsureStakeholderLayout.Header>
        <EnsureStakeholderLayout.Title>Account</EnsureStakeholderLayout.Title>
        <EnsureStakeholderLayout.Subtitle>Below you can view all your account settings.</EnsureStakeholderLayout.Subtitle>
      </EnsureStakeholderLayout.Header>
      <EnsureStakeholderLayout.Body>
        <Tabs activeTabColour={styles.activeTabColor} defaultActiveKey="password">
          <Tab eventKey="password" title="Password">
            <PasswordTab alert={alert} error={triggerUnexpectedError} />
          </Tab>
        </Tabs>
      </EnsureStakeholderLayout.Body>
    </>
  );
};
