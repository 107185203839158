import styles from './ToggleSwitch.module.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const ToggleSwitch = ({
  label,
  register,
  name,
  className,
  inputProps
}) => {

  return (
    <div className={cn(styles.root, { [className]: className })}>
      {label && <label>{label}</label>}
      <div className={styles.slider}>
        <input type='checkbox' {...register(name)} {...inputProps} /> 
        <span></span>
      </div>
    </div>
  )
}

ToggleSwitch.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.object
};

ToggleSwitch.defaultProps = {
  register: (...args) => ({ ref: () => {} }),
};