import React from "react";
import { useLazyQuery } from "@apollo/client";
import { Loading } from "components/Loading/Loading";
import { loader } from "graphql.macro";
import { useState, useEffect } from "react";
import { useAppContext } from "templates/AppLayout/AppLayout";
import styles from "./Companies.module.scss";
import cn from "classnames";
import { CompanyRow } from "./CompanyRow/CompanyRow";
import fuzzysort from "fuzzysort";
import { AppLayout } from "templates/AppLayout/AppLayout";
import { useForm } from "react-hook-form";
import { Input } from "components/FormComponents";

const COMPANIES_QUERY = loader("./../../../../graphql/queries/companies.graphql");

export const Companies = () => {
  const { triggerUnexpectedError } = useAppContext();

  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [filtered, setFiltered] = useState();

  const [fetchCompanies, { loading: fetchingcompanies }] = useLazyQuery(COMPANIES_QUERY, {
    onCompleted: (data) => {
      setCompanies(data.companies);
      setFiltered(data?.companies);
      setLoading(false);
    },
    onError: (error) => {
      triggerUnexpectedError(error.message);
      setLoading(false);
    },
  });

  const { register, watch } = useForm();
  const searchName = watch("searchName");

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (searchName !== null && searchName?.trim() !== "") {
      const filter = fuzzysort.go(searchName, companies, { keys: ["name", "poc.name", "poc.email"], threshold: -10000 });
      const result = [];
      filter.forEach((e) => {
        result.push(e.obj);
      });
      setFiltered(result);
    } else {
      fetchCompanies();
    }
  }, [searchName]);

  if (loading) return <Loading visible={loading} />;

  return (
    <>
      <AppLayout.Header className={cn(styles.header, "card-with-border mb-5", styles.border)}>
        <div className="d-flex justify-content-start">
          <form className={cn(styles.searchForm)}>
            <Input name="searchName" register={register} type="text" icon="search" placeholder="Search name or owner info " />
          </form>
        </div>
      </AppLayout.Header>
      <div className={cn("card-with-border", styles.border)}>
      {!!filtered?.length ? (<>

          <div className={styles.tableHeaders}>
            <span className={cn(styles.statusParent, styles.filter, "d-flex align-items-center")}>ID</span>
            <span className={cn("d-flex align-items-center")}>Name</span>
            <span className={cn("d-flex align-items-center")}>Owner Name</span>
            <span className={cn("d-flex align-items-center")}>Owner Email</span>
            <span className={cn("d-flex align-items-center")}>Created At</span>
          </div>
          <div className={styles.tableRows}>
            {filtered?.map((company) => {
              return <CompanyRow company={company} key={company?.id} />;
            })}
          </div>
          </> ):
          <div className={styles.notFound}> Company Not Found </div>
          }
              </div>

    </>
  );
};
