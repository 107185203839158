import cn from "classnames";
import styles from '../../IntegrationsTab.module.scss';
import defaultLogo from 'assets/images/checkmate.svg'
import { Button} from "components/FormComponents";

const OAUTH_URL = process.env.REACT_APP_CHECKMATE_OAUTH_URL
const KNOWMY_CLIENT_ID = process.env.REACT_APP_KNOWMY_CLIENT_ID
const CHECKMATE_SCOPE = process.env.REACT_APP_CHECKMATE_SCOPE
const KNOWMY_REDIRECT_URI = process.env.REACT_APP_KNOWMY_REDIRECT_URI


export const Checkmate = () => {

  const openOAuthWindow = () => {

    const oauth_url = `${OAUTH_URL}?client_id=${KNOWMY_CLIENT_ID}&scope=${CHECKMATE_SCOPE}&redirect_uri=${KNOWMY_REDIRECT_URI}&response_type=code`
    const width = 800;
    const height = 600;
    const leftOffset = window.screenLeft + (window.innerWidth - width) / 2;
    const topOffset = window.screenTop + (window.innerHeight - height) / 2;
    const windowFeatures = `width=${width},height=${height},top=${topOffset},right=${leftOffset},resizable,scrollbars=yes`;

    window.open(oauth_url, '_blank', windowFeatures);
  };

  return (
      <div className={cn('card card-with-shadow', styles.accountAPI)}>
          <div className='card_content'>
            <div className={styles.logoWrapper}>
              <img class={styles.logo} src={defaultLogo} alt='Checkmate' />
            </div>
            <Button className="w-50" type='primary' onClick={openOAuthWindow}>Connect</Button>
          </div>
        </div>
  )
}
