import styles from './TwoFaLogin.module.scss'
import cn from 'classnames'
import ReactCodeInput from 'react-verification-code-input';
import { useLazyQuery, useApolloClient } from "@apollo/client";
import { loader } from "graphql.macro";
import { Loading } from 'components/Loading/Loading';
import { stateCompany, stateUser } from 'graphql/state';
import Cookies from 'js-cookie';
import { useState } from 'react';

const TWO_FACTOR_QUERY = loader('graphql/queries/verify_two_fa_code.graphql')

export const TwoFaLogin = ({token}) =>{

    const client = useApolloClient();
    const [error , setError] = useState(null)

    const onComplete = (code) =>{
        verifyCode({variables:{token: token, code: `${code}`}})
    }
    
    const onChange = () =>{
        if (error) setError(null)
    }

    const [verifyCode, { loading: verifying }] = useLazyQuery(TWO_FACTOR_QUERY, {
        onCompleted: async(data) => {
            if(data.verifyTwoFaCode){
                const { token, user, company } = data.verifyTwoFaCode;
                await client.clearStore();
                Cookies.set('token', token, { path: '' });
                stateCompany(company);
                stateUser(user);
            }
            else{
                setError('Wrong Code.')
            }
        }
      });

    return(
        <div className='card card-with-shadow margin-top'>
            <Loading visible={verifying} />
            <div className={cn('card_content', styles.content)}>
                <h1 className='design-marker'>Welcome back!</h1>
                <div className={styles.text}>Two Factor Authentication Code</div>
                <div className={styles.input}>
                    <ReactCodeInput onChange={onChange} onComplete={onComplete} fieldWidth={30}  className={styles.input}/>
                </div>
                {!!error && <p className={cn(styles.error)}>{error}</p>}

            </div>
        </div>
    )
}