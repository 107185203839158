import cn from "classnames";
import styles from '../../IntegrationsTab.module.scss';
import defaultLogo from 'assets/images/KM_logo.svg'
import { Input, InputGroup } from "components/FormComponents";
import { useQuery } from '@apollo/client';
import { Loading } from 'components/Loading/Loading';
import { loader } from "graphql.macro";
import { useState } from "react";

const ACCOUNT_API_KEY_QUERY =  loader("graphql/queries/account_api_key.graphql");


export const AccountAPIKey = () => {

  const [apiKey, setApiKey] = useState(null)

  const { loading: fetchingAPIKey} = useQuery(ACCOUNT_API_KEY_QUERY, {
    onCompleted: (data) => {
      setApiKey(data.accountApiKey)
    }
  });

  return (
      <div className={cn('card card-with-shadow', styles.accountAPI)}>
          <Loading visible={fetchingAPIKey} />
          <div className='card_content'>
            <div className={styles.logoWrapper}>
              <img class={styles.logo} src={defaultLogo} alt='KnowMY' />
            </div>
            <InputGroup title='Account API Key'>
              <Input
                className={styles.urlInput}
                name='apiKey'
                value={apiKey}
                actionIcon='copy'
                actionIconTip='Copy Url'
                inputProps={{ readOnly: true }}
                onActionClick={() => {
                  navigator.clipboard.writeText(apiKey);
                }}
              />
            </InputGroup>
          </div>
        </div>
  )
}
