import { FilePicker } from 'components/FormComponents';
import { Controller } from 'react-hook-form';

export const PdfSettings = ({ control, name, errors, setError, clearErrors }) => {
  return (
    <Controller 
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FilePicker 
          onChange={onChange}
          value={value}
          error={errors?.uploadId?.type || errors?.params?.pdfSrc?.type}
          setError={setError}
          name={name}
          clearErrors={clearErrors}
          acceptedFileExtension={"pdf"}
        />
      )}
      rules={{ required: true }}
    />
  )
}

