import styles from "./InformationPreview.module.scss";
import cn from "classnames";

export const InformationPreview = ({ block, internalStakeholder }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <div>
      {internalStakeholder && <div className={cn(styles.row, "pt-3")}>
        <p style={{color: internalStakeholder.stakeholderTag.color }} className="title-4 font-weight-500">{internalStakeholder?.stakeholderTag?.name}</p>
        <p className="title-4 font-weight-500">{internalStakeholder?.name}</p>
      </div>}
      <p className="t-small font-weight-600">Block summary ({block.blockInfoItemsAttributes.length} fields)</p>
      {block.blockInfoItemsAttributes.map((field) => (
        <div key={field.id} className={styles.row}>
          <p className="t-small">
            {field.order}. {capitalizeFirstLetter(field.typeOf.replace("_", " "))}:
          </p>
          {field.typeOf !== "image" && <p className={cn("t-small",styles.text)}>"{field.typeOf === "video" ? field.params.videoSrc : field.params.text}"</p>}
        </div>
      ))}
    </div>
  );
};
