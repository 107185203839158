import { List } from "../../../../components/List/List";
import { useState } from "react";
import { JourneyResult } from "./components/JourneyResult/JourneyResult";
import { loader } from "graphql.macro";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { StakeholderJourneyConfig } from "views/NewStakeholderJourneyScreen/components/StakeholderJourneyConfig/StakeholderJourneyConfig";
import { useAppContext } from "templates/AppLayout/AppLayout";
import { useNavigate } from "react-router-dom";

const JOURNEYS_QUERY = loader("graphql/queries/journeys.graphql");
const JOURNEY_QUERY = loader("graphql/queries/journey.graphql");
const STAKEHOLDER_QUERY = loader("graphql/queries/internal_stakeholders.graphql");
const CREATE_EXISTING_STAKEHOLDER_JOURNEY_MUTATION = loader("graphql/mutations/create_existing_stakeholder_journey.graphql");

export const Journeys = ({ alert, triggerError, externalStakeholder, resendFunction }) => {
  const { setLoading } = useAppContext();
  const navigate = useNavigate();

  const [stakeholderJourney, setStakeholderJourney] = useState();
  const [journeys, setJourneys] = useState([]);
  const [journey, setJourney] = useState();
  const [step, setStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [fetchId, setFetchId] = useState();
  const [openSettings, setOpenSettings] = useState(false);
  const [internalStakeholders, setInternalStakeholders] = useState([]);
  const [choose, setChoose] = useState(1);

  const { loading: fetchingStakeholders } = useQuery(STAKEHOLDER_QUERY, {
    onCompleted: (data) => {
      setInternalStakeholders(data.internalStakeholders);
    },
  });


  const { loading: fetchingJourneys } = useQuery(JOURNEYS_QUERY, {
    onCompleted: (data) => setJourneys(data.journeys),
  });

  const [fetchJourney, { loading: fetchingJourney }] = useLazyQuery(JOURNEY_QUERY, {
    onCompleted: (data) => {
      setJourney(data.journey);
    },
  });

  const [createExistingStakeholderJourney, { loading: creating }] = useMutation(CREATE_EXISTING_STAKEHOLDER_JOURNEY_MUTATION, {
    onCompleted: async (_) => {
      navigate("/");
    },
    onError: (error) => {
      triggerError();
    },
  });

  const handleClick = (id) => {
    if (id === journey?.id) return;
    else if (journey && step === 1) {
      return fetchJourney({ variables: { id: id } });
    }
    else if (journey) {
      setFetchId(id);
      setOpenModal(true);
      return fetchJourney({ variables: { id: id } });
    }
    else{
    fetchJourney({ variables: { id: id } });
    }
  };

  useEffect(() => {
    setLoading(fetchingJourney || fetchingJourneys || fetchingStakeholders|| creating);
  }, [fetchingJourneys, fetchingJourney, fetchingStakeholders,choose, creating]);

  const handleCreate = (data) => {
    let error = false;
    
    const documentBlocks = journey?.journeyBlocks?.filter((journeyBlock) => journeyBlock?.block?.typeOf === "document");
    data["externalStakeholderId"] = externalStakeholder?.id;
    data.internalStakeholdersAttributes = data?.internalStakeholdersAttributes.flat();
    delete data?.formTypecustomActions
    data?.stakeholderJourneyCustomizationsAttributes?.forEach((customizationAttribute) => {
      customizationAttribute.documentUploadId = customizationAttribute.documentUploadAttributes.documentUploadId
      delete customizationAttribute.documentUploadAttributes
      delete customizationAttribute.key
    });

    data.internalStakeholdersAttributes.map((internalStakeholder) => {
      delete internalStakeholder.__typename;
      delete internalStakeholder.stakeholderTag;
    });
    const { externalStakeholderAttributes: _, ...dataToSend } = data;

    documentBlocks?.forEach((documentBlock) => {
      if (!documentBlock?.block?.documentTemplateAttributes?.hellosignTemplateId) {
        error = true;
        triggerError("There is a document signing block that is not configured");
      }
    });
    if (documentBlocks.length === 0 || !error) {
      try {
        createExistingStakeholderJourney({ variables: { stakeholderJourneyAttributes: dataToSend } });
      } catch (error) {
        triggerError(error?.message);
      }
    }
  };

  return (
    <div className="d-flex">
      <List
        title="Journeys"
        subtitle={`Select one of ${externalStakeholder?.firstName}'s existing onboarding journeys.`}
        className="margin-right--large"
      >
        <List.Category title={`${externalStakeholder?.name}'s Journeys`} isTitleLarge>
          {externalStakeholder?.stakeholderJourneys?.map((stakeholderJourney) => (
            <List.Item
              key={stakeholderJourney.id}
              title={stakeholderJourney.journey.name}
              value={stakeholderJourney.id}
              onClick={() => {
                setStakeholderJourney(stakeholderJourney);
                setChoose(2);
              }}
            />
          ))}
        </List.Category>

        <List.Category title="KnowMy Journeys (Click To Lauch)" isTitleLarge>
          {journeys.map((journey) => (
            <List.Item
              key={journey.id}
              title={`${journey.name} (${journey.brand.name})`}
              value={journey.id}
              onClick={() => {
                setChoose(1);
                handleClick(journey.id);
              }}
            />
          ))}
        </List.Category>
      </List>
      {choose === 1 && (
        <div>
          {journey && (
            <StakeholderJourneyConfig
              journey={journey}
              internalStakeholders={internalStakeholders}
              onSubmit={handleCreate}
              onClose={() => setJourney(null)}
              error={triggerError}
              setParentStep={setStep}
              setOpenSettings={setOpenSettings}
              existingStakeholderCheck={true}
              existingStakeholderData={externalStakeholder}
            />
          )}
        </div>
      )}
      {choose === 2 && stakeholderJourney && (
        <JourneyResult
          {...stakeholderJourney}
          alert={alert}
          triggerError={triggerError}
          journeyId={stakeholderJourney.id}
          externalStakeholder={externalStakeholder}
          resendFunction={resendFunction}
        />
      )}
    </div>
  );
};
