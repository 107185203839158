import { useForm } from 'react-hook-form';
import styles from './RoleForm.module.scss';
import { EditableTitle, Button } from 'components/FormComponents';
import { Tabs, Tab } from 'components/Tabs/Tabs';
import { RolePermissionsTab } from '../RolePermissionsTab/RolePermissionsTab';
import { RoleUsersTab } from '../RoleUsersTab/RoleUsersTab';
import { useEffect } from 'react';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { useState } from 'react';
import cn from 'classnames';

export const RoleForm = ({
  role,
  users,
  onRoleAssign = () => {},
  onRoleUnassign = () => {},
  onClose = () => {},
  onSave = () => {},
  onDelete = () => {},
  fetchUsers=()=>{}
}) => {

  const { register, reset, control, watch, handleSubmit } = useForm();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => reset(role), [role])

  return (
    <>
      <ConfirmationModal
        visible={openModal}
        title='Delete Role Confirmation'
        subtitle='This Role will be permanently deleted. This data cannot be recovered'
        confirmationKey={watch('displayName')}
        confirmationIcon='trash-2'
        onClose={() => { setOpenModal(false) }}
        onConfirmation={onDelete}
        buttonTitle='Delete Role'
      />
      <div className={cn(styles.root, 'card card-with-border')}>
        <div className={styles.headerContainer}>
          <form id='role-update-form' onSubmit={handleSubmit(onSave)}>
            <header className={styles.header}>
              <EditableTitle disabled register={register} name='displayName' value={watch('displayName')} />

            </header>
          </form>
        </div>
        <Tabs defaultActiveKey='users' contentStyles={styles.customTab} navItemStyles={styles.navItems}>
          <Tab eventKey="permissions" title="Permissions">
            <RolePermissionsTab permissions={role?.permissions} />
          </Tab>
          <Tab eventKey='users' title='Users' >
            <RoleUsersTab
              register={register}
              control={control}
              users={users}
              role={role}
              reset={reset}
              onRoleAssign={onRoleAssign}
              onRoleUnassign={onRoleUnassign}
              fetchUsers={fetchUsers}
            />
          </Tab>
        </Tabs>
        <div className={styles.footer}>
          <div className='d-none'>
            <Button type='secondary' onClick={onClose}>Cancel</Button>
            <Button buttonProps={{ form: 'role-update-form' }} submit>Save</Button>
          </div>
        </div>

        </div>
    </>
  )
}
