import { ImagePicker, Input, InputGroup, Select, Textarea } from 'components/FormComponents';
import { VideoWidget } from 'components/VideoWidget/VideoWidget';
import { Modal } from 'components/Modal/Modal';

export const JourneyCustomizationPreview = ({
  journey,
  visible,
  onClose = () => {}
}) => {
  return (
    <Modal title={'Journey Customization'} visible={visible} onClose={onClose}>
      <InputGroup title='Brand'>
        <Select name='brandId' disabled={true}>
          <Select.Item key={journey.brand.name} active={true} value={journey.brand.name}>{journey.brand.name}</Select.Item>
        </Select>
      </InputGroup>
      <InputGroup className='margin-top' title='Welcome Screen Heading'>
        <Input
          inputProps={{value: journey.journeyCustomization.welcomeScreenHeading || '', disabled: true}}
        />
      </InputGroup>
      <InputGroup title='Welcome Screen Sub-Heading'>
        <Textarea
          inputProps={{value: journey.journeyCustomization.welcomeScreenSubHeading || '', disabled: true}}
        />
      </InputGroup>
      <InputGroup title='Media'>
        {journey?.journeyCustomization.mediaType === 'video' ? 
          <VideoWidget autoplay={false} mute={true} src={journey.journeyCustomization.videoSrc} /> : 
          <ImagePicker value={journey.journeyCustomization.imageId} disabled={true} />
        }
      </InputGroup>
      <InputGroup title='Dashboard Heading'>
        <Input
          inputProps={{value: journey.journeyCustomization.dashboardHeading || '', disabled: true}}
        />
      </InputGroup>
      <InputGroup title='Dashboard Sub-Heading'>
        <Textarea
          inputProps={{value: journey.journeyCustomization.dashboardSubHeading || '', disabled: true}}
        />
      </InputGroup>
    </Modal>
  )
}


