import styles from './DetailsTab.module.scss';
import cn from 'classnames';
import { stateUser, stateCompany } from '../../../../graphql/state';
import { EditDetailsForm } from './components/EditDetailsForm/EditDetailsForm';
import { useReactiveVar, useMutation } from '@apollo/client';
import { Loading } from '../../../../components/Loading/Loading';
import { loader } from "graphql.macro";


const UPDATE_COMPANY_MUTATION = loader('graphql/mutations/update_company.graphql');

export const DetailsTab = ({ alert, error }) => {

  const user = useReactiveVar(stateUser);
  const company = useReactiveVar(stateCompany);

  const [ updateCompany, { loading: updatingCompany } ] = useMutation(UPDATE_COMPANY_MUTATION, {
    onCompleted: async(data) => {
      const { user, company } = data.updateCompany;
      stateCompany(company);
      stateUser(user);
      alert('Details were successfully updated.')
    },
    onError: errorMessage => {
      error(errorMessage.message)
    }
  });

  return (
    <div className={cn('card card-with-border', styles.root)}>
      <Loading visible={updatingCompany} />
      <div className='padding-bottom-0 d-flex align-items-start justify-content-start card_content'>
        <header className={styles.header}>
          <h1 className='title-2'>Details</h1>
        </header>
      </div>
      <EditDetailsForm
        stateUser={user}
        stateCompany={company}
        updateCompany={updateCompany}
      />
    </div>
  )
}