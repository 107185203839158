import styles from './PublicJourneyLayout.module.scss';
import { Outlet } from 'react-router-dom';
import { Loading } from '../../components/Loading/Loading';
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import cn from 'classnames';
import { useState, createContext, useContext } from 'react';
import { Footer } from '../StakeholderJourneyLayout/components/Footer/Footer';
import { ErrorAlert } from 'components/ErrorAlert/ErrorAlert';

const LayoutContext = createContext(null);

export const usePublicJourneyLayoutContext = () => {
  const context = useContext(LayoutContext)
  if (!context) throw new Error(`Public Journey compound components cannot be rendered outside the PublicJourneyLayout component`);
  return context
}

const PUBLIC_JOURNEY_QUERY = loader("./../../graphql/queries/public_journey.graphql");

export const PublicJourneyLayout = () => {

  const [loading, setLoading] = useState(false);

  const [publicJourney, setPublicJourney] = useState({});
  const [showErrorAlert, setShowErrorAlert] = useState([null, false]);
  const [errorMessage, setErrorMessage] = useState('');

  const triggerUnexpectedError = (error) => {
    setShowErrorAlert([error || null, true]);
  }

  const {journeyId} = useParams();

  const { loading: fetching } = useQuery(PUBLIC_JOURNEY_QUERY, {
    variables: { token: journeyId },
    onCompleted: (data) => {
      if(data.publicJourney)
        setPublicJourney(data.publicJourney)
      else
        setErrorMessage('Sorry, this public journey is no longer available.')
    }
  });

  const contextValue = {
    journeyId,
    loading: loading || fetching,
    triggerUnexpectedError,
    publicJourney: publicJourney,
    setLoading,
    fetch,
    errorMessage,
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      <div className={styles.root}>
        <Loading visible={loading} />
        <ErrorAlert errorMessage={showErrorAlert[0]} visible={showErrorAlert[1]} onClose={() => setShowErrorAlert(false)} />
        <div className={cn(styles.content)}>
          <Outlet />
        </div>
      </div>
    </LayoutContext.Provider>
  )
}

const Content = ({
  withFooter = true,
  withBrandBackgroundColor = true,
  className,
  children,
}) => {

  const { loading, publicJourney } = usePublicJourneyLayoutContext()

  return (
    <div
      className={cn(styles.root, {
        [styles.rootWithFooter]: withFooter,
        [styles.rootWithBrandBackgroundColor]: withBrandBackgroundColor,
      })}
      style={{
        backgroundColor: withBrandBackgroundColor ? publicJourney?.brand?.backgroundColor : '#449DD3'
      }}
    >
      <Loading visible={loading} />
      <div className={cn({ [className]: className })}>{children}</div>
      {withFooter && <Footer />}
    </div>
  )
}

PublicJourneyLayout.Content = Content

const Title = ({ children }) => (
  <h1 className={cn(styles.title, 'title-3')}>{children}</h1>
)
PublicJourneyLayout.Title = Title;


const Subtitle = ({ children }) => (
  <p className={styles.subtitle}>{children}</p>
)
PublicJourneyLayout.Subtitle = Subtitle;
