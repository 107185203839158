import { Tabs, Tab } from "../../components/Tabs/Tabs";
import { ExternalStakeholderLayout, useExternalStakeholderLayoutContext } from "templates/ExternalStakeholderLayout/ExternalStakeholderLayout";
import { PasswordTab } from "./Tabs/PasswordTab/PasswordTab";

export const ExternalStakeholderAccountScreen = () => {
  const { alert, triggerUnexpectedError, setLoading } = useExternalStakeholderLayoutContext();

  return (
    <>
      <ExternalStakeholderLayout.Header>
        <ExternalStakeholderLayout.Title>Account</ExternalStakeholderLayout.Title>
        <ExternalStakeholderLayout.Subtitle>Below you can view all your account settings.</ExternalStakeholderLayout.Subtitle>
      </ExternalStakeholderLayout.Header>
      <ExternalStakeholderLayout.Body>
        <Tabs defaultActiveKey="password">
          <Tab eventKey="password" title="Password">
            <PasswordTab alert={alert} error={triggerUnexpectedError} />
          </Tab>
        </Tabs>
      </ExternalStakeholderLayout.Body>
    </>
  );
};
