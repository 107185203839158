import { Modal } from './../../../../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { Input, InputGroup, Button, Select } from './../../../../../../components/FormComponents';
import cn from 'classnames';
import styles from './NewJourneyModal.module.scss';

export const NewJourneyModal = ({ 
  visible,
  brands,
  onClose,
  onSubmit
}) => {

  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <Modal title='New Journey' subtitle='To create a new journey, first name it and then configure all the fields below' visible={visible} onClose={onClose}>
      <form className='margin-top' onSubmit={handleSubmit(onSubmit)}>
        <Input 
          name='name'
          placeholder='Enter your journey name...'
          register={register}
          validators={{ required: true }}
          inputProps={{ tabIndex: 1 }}
          error={errors?.name?.type}
        />
        <div className={cn(styles.config, 'background-secondary', 'margin-top--large')}>
          <div className='card card-with-border'>
            <div className='card_content'>
              <InputGroup title='Notification - send time' inline className='m-0'>
                <Select name='sendAt' register={register}>
                  <Select.Item value='00:00'>12:00 AM</Select.Item>
                  <Select.Item value='01:00'>01:00 AM</Select.Item>
                  <Select.Item value='02:00'>02:00 AM</Select.Item>
                  <Select.Item value='03:00'>03:00 AM</Select.Item>
                  <Select.Item value='04:00'>04:00 AM</Select.Item>
                  <Select.Item value='05:00'>05:00 AM</Select.Item>
                  <Select.Item value='06:00'>06:00 AM</Select.Item>
                  <Select.Item value='07:00'>07:00 AM</Select.Item>
                  <Select.Item value='08:00' active={true}>08:00 AM</Select.Item>
                  <Select.Item value='09:00'>09:00 AM</Select.Item>
                  <Select.Item value='10:00'>10:00 AM</Select.Item>
                  <Select.Item value='11:00'>11:00 AM</Select.Item>
                  <Select.Item value='12:00'>12:00 PM</Select.Item>
                  <Select.Item value='13:00'>01:00 PM</Select.Item>
                  <Select.Item value='14:00'>02:00 PM</Select.Item>
                  <Select.Item value='15:00'>03:00 PM</Select.Item>
                  <Select.Item value='16:00'>04:00 PM</Select.Item>
                  <Select.Item value='17:00'>05:00 PM</Select.Item>
                  <Select.Item value='18:00'>06:00 PM</Select.Item>
                  <Select.Item value='19:00'>07:00 PM</Select.Item>
                  <Select.Item value='20:00'>08:00 PM</Select.Item>
                  <Select.Item value='21:00'>09:00 PM</Select.Item>
                  <Select.Item value='22:00'>10:00 PM</Select.Item>
                  <Select.Item value='23:00'>11:00 PM</Select.Item>
                </Select>
              </InputGroup>

              <InputGroup title='Brand' inline className='margin-top margin-bottom-0'>
                <Select name='brandId' register={register}>
                  {brands.map((brand, index) => (
                    <Select.Item key={brand.id} active={index===0} value={brand.id}>{brand.brandName}</Select.Item>
                  ))}
                </Select>
              </InputGroup>

              <InputGroup title='Send reminders for' inline className='margin-top margin-bottom-0'>
                <Select name='reminders' register={register}>
                  <Select.Item value={'never'} active={true}>Never</Select.Item>
                  <Select.Item value={'one_day'}>1 Day</Select.Item>
                  <Select.Item value={'two_days'}>2 Days</Select.Item>
                  <Select.Item value={'three_days'}>3 Days</Select.Item>
                  <Select.Item value={'week'}>Week</Select.Item>
                </Select>
              </InputGroup>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between w-100 margin-top'>
          <Button type='secondary' onClick={onClose}>Cancel</Button>
          <Button submit>Save</Button>
        </div>
      </form>
    </Modal>
  )
}