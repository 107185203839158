import { EditableSettings } from '../../../../../../../../../../../components/EditableSettings/EditableSettings';
import { Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { ToggleSwitch, Input } from '../../../../../../../../../../../components/FormComponents';
import { Button } from '../../../../../../../../../../../components/FormComponents';
import { useFieldArray } from 'react-hook-form';
import { Collapse } from 'react-bootstrap';
import styles from './MultipleChoiceSettings.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';

export const MultipleChoiceSettings = ({ register, control, name, field,errors, clearErrors }) => {
  return (
    <Fragment>
      <EditableSettings title='Settings' className='margin-top--large' startOpen={true}>
        <ToggleSwitch register={register} name={`${name}.required`} label='Required' />
        <hr />
        <ToggleSwitch register={register} name={`${name}.multipleSelection`} label='Multiple selection' />
        <hr />
        <ToggleSwitch register={register} name={`${name}.otherOption`} label="Add 'other' option" />
        <hr />
        <Fragment>
          <ToggleSwitch register={register} name={`${name}.useDescription`} label='Add description' />
          <Collapse in={field.options?.useDescription}>
            <div><Input register={register} name={`${name}.description`} className='margin-top--small' placeholder='Enter description...'/></div>
          </Collapse>
        </Fragment>
      </EditableSettings>
      <hr className='my-5' />
      <OptionsConfig register={register} name={name} control={control} errors={errors} clearErrors={clearErrors}/>
    </Fragment>
  )
}

const OptionsConfig = ({ register, name, control, errors, clearErrors}) => {

  const { fields, append, remove, swap } = useFieldArray({ control, name: `${name}.options` });
  var errorCount = 0
  if (fields?.length && errors?.options?.options?.length){
    errors?.options?.options?.forEach((error)=>{
      if(error){
        errorCount = errorCount +1
      }
    })
  }
  if (errors && errorCount === 0){
    clearErrors()
  }
  

  const addOption = () => {
    append({ value: ''})
  }

  const onDragEnd = ({ source, destination }) => {
    if(!source || !destination) return;
    swap(source.index, destination.index);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='options'>
        {({ innerRef, droppableProps, placeholder }) => (
          <div className='card card-with-border background-secondary px-4' {...droppableProps} ref={innerRef}>
            {fields.map((option, index) => (
              <Option
                key={option.id}
                index={index}
                name={`${name}.options.${index}`}
                register={register}
                option={option}
                onRemove={() => remove(index)}
                onEnter={addOption}
                errors={errors}
              />
            ))}
            {placeholder}
            <Button type='link' onClick={addOption}>+ Add another option</Button>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}


const Option = ({ name, register, onRemove, option, index, onEnter, errors}) => {

  const handleKeyDown = (e) => {
    switch(e.code) {
      case 'Enter':
        e.preventDefault();
        onEnter();
        break;
      case 'Backspace':
        if(e.target.value === '') {
          e.preventDefault();
          onRemove();
        }
        break;
    }
  }

  return (
    <Draggable draggableId={option.id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div className={cn(styles.option, 'd-flex', 'align-items-center', 'margin-top')} {...draggableProps} {...dragHandleProps} ref={innerRef}>
          <FeatherIcon icon='move' className={cn('margin-right--small', styles.icon)} />
          <Input
            register={register}
            name={`${name}.value`}
            className={styles.input}
            validators={{ required: true}}
            error={errors?.options?.options?.[index]?.value?.type}
            placeholder='Enter option...'
            actionIcon='trash-2'
            onActionClick={onRemove}
            inputProps={{
              onKeyDown: handleKeyDown
            }}
          />
        </div>
      )}
    </Draggable>
  )
}