import { Badge, Dropdown, ListGroup } from "react-bootstrap";
import moment from "moment";
import { IconButton } from 'components/IconButton/IconButton';
import cn from 'classnames';
import styles from './List.module.scss'

export const List =()=> {
    return (
        <div className="mb-4">
        <ListGroup>
            <ListGroup.Item
            className={cn(`${styles.listItem}  list-item--success d-flex justify-content-between`)}
            style={{ borderLeft: `3px solid #1f4f5c` }}
            >
              <div>
                <div className={cn("d-flex")}>
                  <p className="title-4 font-weight-500">Test Plan </p>
                  <Badge className={cn("margin-left--small", "t-capitalize", `badge--completed`)}>
                    Nice to do
                  </Badge>
                </div>
                <p className={cn(styles.date) + " pt-2"}>{moment(document?.updatedAt).format("MMM DD YYYY")}</p>
              </div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle className={cn(styles.dropdownToggle)}>
                    <IconButton className={cn(styles.moreBtn)} icon="more-vertical" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={cn(styles.dropdownMenu)}>
                    <Dropdown.Item
                    className={cn(styles.menuItem)}
                    href="#"
                    >
                      Download
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              </ListGroup.Item>
        </ListGroup>
      </div>
    )
}