export const ActionTypes = {
  Notification: 'notification',
  Report:       'report', 
}

export const ActionTypeDetails = {
  [ActionTypes.Notification]: {
    title:  'Notification',
  },
  [ActionTypes.Report]: {
    title: 'Report', 
  }
}

export const ActionTypesOptions = [
  { 
    title: ActionTypeDetails[ActionTypes.Notification].title, 
    key:   ActionTypes.Notification, 
  },
  { 
    title: ActionTypeDetails[ActionTypes.Report].title, 
    key:   ActionTypes.Report, 
  },
];