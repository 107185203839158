import cn from 'classnames';
import styles from './VerifyEmailScreen.module.scss';
import img from 'assets/images/emailImage.svg';
import { loader } from 'graphql.macro';
import { useMutation, useReactiveVar } from '@apollo/client';
import { stateUser } from 'graphql/state';
import { Loading } from 'components/Loading/Loading';
import { useState } from 'react';
import { useTimer } from 'hooks/timer';

const SEND_CONFIRMATION_MAIL = loader("./../../graphql/mutations/send_confirmation_mail.graphql");

export const VerifyEmailScreen = ( ) => {

  const [time , setTime] = useState(0)
  const { start: startTimer } = useTimer(30, ()=>{}, time, setTime);

  const [emailSent, setEmailSent]= useState(false)
  const [displayTimer, setDisplayTimer] = useState(false)

  const [ sendConfirmationMail ] = useMutation(SEND_CONFIRMATION_MAIL)
  
  const user = useReactiveVar(stateUser);

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const resend = async () => {
    setEmailSent(true)
    await sendConfirmationMail({ variables: { email: user.email }})
    await timeout(1000); 
    setEmailSent(false)
    setDisplayTimer(true)
    startTimer()
  }

  return (
    <div className={cn(styles.root)}>
      <Loading visible={emailSent} />
      <div className={cn(styles.card, 'card', 'd-flex', 'flex-column', 'justify-content-center', 'align-items-center')}>
        <h1 className={cn(styles.title, 'design-marker')}>Verify your account</h1>
        <img className={styles.img} src={img} alt='Email sent' />
        <p className={cn(styles.subtitle, 'title-4')}>We've sent you an email to verify your account</p>
        <p className={cn(styles.smallText, 't-small')}>
          Didn't receive it? <button className={`${cn(styles.button)} ${time>0 ? cn(styles.plain): ''}`} disabled={time > 0} onClick={resend}>Resend email</button> <div className={cn(styles.timer)}>{displayTimer && time>0 && `(${time})`}</div>
        </p>       
      </div>
    </div>
  )
}