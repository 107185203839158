import { CollapsibleBlock } from "components/CollapsibleBlock/CollapsibleBlock";
import { BlockInfoItemTypes, BlockInfoItemOptions } from "constants/block-info-items-enum";
import { useWatch } from "react-hook-form";
import { Select } from "components/FormComponents";
import { IconButton } from "components/IconButton/IconButton";
import cn from "classnames";
import styles from "./InfoItemConfig.module.scss";
import { Draggable } from "react-beautiful-dnd";
import { ParagraphSettings } from "./settings_components/ParagraphSettings/ParagraphSettings";
import { TitleSettings } from "./settings_components/TitleSettings/TitleSettings";
import { VideoSettings } from "./settings_components/VideoSettings/VideoSettings";
import { ImageSettings } from "./settings_components/ImageSettings/ImageSettings";
import { GoogleMapSettings } from "./settings_components/GoogleMapSettings/GoogleMapSettings";
import { TangoEmbedSettings } from "./settings_components/TangoEmbedSettings/TangoEmbedSettings";
import { PdfSettings } from "../../../QuestionnaireBuilder/components/FieldConfig/settings_components/PdfSettings/PdfSettings";
import { UrlSettings } from "./settings_components/UrlSettings/UrlSettings";
import { VimeoSettings } from "./settings_components/VimeoSettings/VimeoSettings";
import { LoomSettings } from "./settings_components/LoomSettings/LoomSettings";
import { VidyardSettings } from "./settings_components/VidyardSettings/VidyardSettings";

export const InfoItemConfig = ({ item, index, watch, register, remove, control, errors, setError, clearErrors }) => {
  const registerName = `blockInfoItemsAttributes.${index}`;

  const typeOf = useWatch({ control, name: `${registerName}.typeOf` });

  const renderTypeSettings = () => {
    switch (typeOf) {
      case BlockInfoItemTypes.Paragraph:
        return <ParagraphSettings register={register} name={registerName} errors={errors?.params?.text?.type} />;
      case BlockInfoItemTypes.Title:
        return <TitleSettings register={register} name={registerName} errors={errors?.params?.text?.type} />;
      case BlockInfoItemTypes.Video:
        return <VideoSettings register={register} watch={watch} name={registerName} errors={errors} />;
      case BlockInfoItemTypes.Image:
        return <ImageSettings control={control} watch={watch} name={registerName} errors={errors?.params?.imgSrc?.type}/>;
      case BlockInfoItemTypes.GoogleMap:
        return <GoogleMapSettings register={register} watch={watch} name={registerName} errors={errors?.params?.text?.type} />;
      case BlockInfoItemTypes.TangoEmbed:
        return <TangoEmbedSettings register={register} watch={watch} name={registerName} errors={errors?.params?.videoSrc?.type} />;
      case BlockInfoItemTypes.Pdf:
        return <PdfSettings name={`${registerName}.params.pdfSrc`} control={control} watch={watch} errors={errors} setError={setError} clearErrors={clearErrors} />
      case BlockInfoItemTypes.Url:
        return <UrlSettings register={register} name={registerName} watch={watch} error={errors?.params?.text?.type}/>
      case BlockInfoItemTypes.Vimeo:
        return <VimeoSettings register={register} name={`${registerName}.params.text`} watch={watch} error={errors?.params?.text?.type}/>
      case BlockInfoItemTypes.Loom:
        return <LoomSettings register={register} name={`${registerName}.params.text`} watch={watch} error={errors?.params?.text?.type}/>
      case BlockInfoItemTypes.Vidyard:
        return <VidyardSettings register={register} name={`${registerName}.params.text`} watch={watch} error={errors?.params?.text?.type}/>
    }
  };

  return (
    <Draggable draggableId={item.key} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div {...draggableProps} {...dragHandleProps} ref={innerRef}>
          <CollapsibleBlock className={cn("margin-bottom", styles.root)} keyCode={`${index}`}>
            <CollapsibleBlock.Header>
              <span className={styles.order}>{item.order}</span>
              <Select register={register} name={`${registerName}.typeOf`} className={styles.typeSelector}>
                {BlockInfoItemOptions.map((itemType) => (
                  <Select.Item
                    key={itemType.key}
                    value={itemType.key}
                    active={itemType.key === item.typeOf}
                    description={itemType.subTitle}
                  >
                    {itemType.title}
                  </Select.Item>
                ))}
              </Select>
              <IconButton tip="Delete Field" className={styles.removeButton} onClick={() => remove(index)} icon="trash-2" />
            </CollapsibleBlock.Header>
            <CollapsibleBlock.Content>{renderTypeSettings()}</CollapsibleBlock.Content>
          </CollapsibleBlock>
        </div>
      )}
    </Draggable>
  );
};
