import { FieldTypes } from "./field-types-enum"

export const LogicOperatorTypes = {

  Is: 'is',
  IsNot: 'is_not',
  LowerThan: 'lower_than',
  LowerEqualThan: 'lower_equal_than',
  GreaterThan: 'greater_than',
  GreaterEqualThan: 'greater_equal_than',
  
}

export const LogicOperatorTypesList = [
    { 
      value: 'is', 
      label: 'Equal to',
      allowedTypes: [FieldTypes.MultipleChoice, FieldTypes.YesNo] 
    },
    { 
      value: 'is_not', 
      label: 'Not equal to',
      allowedTypes: [FieldTypes.MultipleChoice, FieldTypes.YesNo]
    },
    {
      value: 'lower_than', 
      label: 'Lower than',
      allowedTypes: [FieldTypes.OpinionScale, FieldTypes.StarRating, FieldTypes.Date]
    },
    { 
      value: 'lower_equal_than', 
      label: 'Lower or equal than',
      allowedTypes: [FieldTypes.OpinionScale, FieldTypes.StarRating, FieldTypes.Date]
    },
    { 
      value: 'greater_than', 
      label: 'Greater than',
      allowedTypes: [FieldTypes.OpinionScale, FieldTypes.StarRating, FieldTypes.Date]
    },
    { 
      value: 'greater_equal_than', 
      label: 'Greater or equal than',
      allowedTypes: [FieldTypes.OpinionScale, FieldTypes.StarRating, FieldTypes.Date]
    },
]