export const StakeholderJourneyStatusType = [
    {
        key: 'completed',
        value: 'Completed'
    },
    {
        key: 'in_progress',
        value: 'In-Progress'
    },
    {
        key: 'pending',
        value: 'Pending'
    }
]